import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
  COMPANIES_EDIT_PAGE,
  EDIT_USER,
  USERS_PAGE
} from "../../../constants/pages";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useIsMobile from "../../../hooks/useIsMobile";
import { newUserInitialValues } from "../../../initialValues/newUserInitialValues";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
  toggleSetPasswordModal,
  toggleSetUserModal,
} from "../../../store/actions/modal/modalActions";
import {
  fetchBlockUser,
  fetchChangeUserPassword,
  fetchUnblockUser,
} from "../../../store/actions/user/userActions";
import history from "../../../store/utils/history";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import newUserValidationSchema from "../../../validations/newUserValidationSchema";
import ErrorIcon from "../../Icon/Icons/ErrorIcon";
import CircularLoader from "../../Loader/CircularLoader/CircularLoader";
import { PasswordValidationText } from "../../Modals/SetPasswordModal/SetPasswordModal.styled";
import ActionButtons from "../../PageTitle/ActionButtons/ActionButtons";
import PageTitle from "../../PageTitle/PageTitle";
import ConfirmPasswordField from "./ConfirmPasswordField/ConfirmPasswordField";
import EmailField from "./EmailField/EmailField";
import FirstNameField from "./FirstNameField/FirstNameField";
import LastNameField from "./LastNameField/LastNameField";
import {
  ErrorMessage,
  Line,
  NewUserAcountInfo,
  NewUserBasicInfo,
  NewUserContainerForm,
  NewUserInfoHeader,
  NewUserInputsContainer,
  PasswordButton,
  PasswordButtonContainer,
  PasswordValidationIcon,
  SetPasswordIcon,
  TickIcon,
} from "./NewUserForm.styled";
import PasswordField from "./PasswordField/PasswordField";
import RolePicker from "./RolePicker/RolePicker";

const NewUserForm = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const routeMatch = useRouteMatch();
  const userId = routeMatch.params?.userId;
  const location = useLocation();
  const { isMobile } = useIsMobile();

  const handleChangePassword = () => {
    dispatch(
      toggleSetPasswordModal({
        title: t("changePassword.changePasswordTitle", {
          name: formik?.values?.firstName + " " + formik?.values?.lastName,
        }),
        onSubmit: (values) => {
          formik?.setFieldValue?.("password", values?.password);
          formik?.setFieldValue?.("confirmPassword", values?.password);
        },
        rank: 1,
      })
    );
  };

  const handleApiResponseSuccess = (payload) => {
    dispatch(closeModal());
    if (payload?.isBlocked) {
      if (props?.companyId) {
        history?.replace?.(
          replaceInRoute(COMPANIES_EDIT_PAGE, { companyId: props?.companyId }),
          { isInUsers: true }
        );
      } else {
        history.goBack();
        history.replace(USERS_PAGE, {
          forceRefetch: true,
          name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
          isBlocked: payload?.isBlocked || false,
          ...location?.state,
        });
      }
    } else {
      const titleI18Key = props?.isEditing
        ? "toast.success.editedTitle"
        : "toast.success.createdTitle";
      const descriptionI18Key = props?.isEditing
        ? "toast.success.editedDescription"
        : "toast.success.createdDescription";
      makeToastMessage(
        {
          title: t(titleI18Key, {
            typeOfData: t("users.typeOfData"),
          }),
          description: t(descriptionI18Key, {
            name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
            typeOfData: t("users.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      if (!props?.customRedirect) {
        if (!props?.isEditing) {
          history.replace({
            pathname: replaceInRoute(EDIT_USER, { userId: payload }),
            state: {
              isDeactive: false,
            },
          });
        } else {
          history.replace({
            pathname: props?.companyId
              ? replaceInRoute(COMPANIES_EDIT_PAGE, {
                  companyId: props?.companyId,
                })
              : replaceInRoute(EDIT_USER, { userId: userId }),
            state: {
              isDeactive: false,
              isInUsers: props?.companyId != null,
            },
          });
        }
      } else props?.customRedirect?.();
    }
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    const userData = {
      firstname: values?.firstName?.replace?.(/  +/g, " ")?.trim?.(),
      lastname: values?.lastName?.replace?.(/  +/g, " ")?.trim?.(),
      email: values?.email?.replace?.(/  +/g, " ")?.trim?.(),
      password: values?.password,
      createdDate: values?.createdDate,
      roles: values?.userRole,
      companyId: props?.companyId,
    };
    const userId = routeMatch?.params?.userId;
    if (props?.isEditing && values?.password) {
      dispatch(
        fetchChangeUserPassword({
          userId: userId,
          newPassword: values?.password,
        })
      );
    }
    setTimeout(() => {
      dispatch(
        props?.dispatchFunction({
          userData,
          userId,
          handleApiResponseSuccess,
          handleApiResponseError,
        })
      );
    }, 500);
  };
  const formik = useFormik({
    initialValues: newUserInitialValues(props?.prefilledData),
    validationSchema: newUserValidationSchema(props?.isEditing),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleDeactivateUser = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchBlockUser({
        userId: routeMatch?.params?.userId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleActivateUser = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchUnblockUser({
        userId: routeMatch?.params?.userId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const handleDeactivate = () => {
    dispatch(
      toggleSetUserModal({
        title: t("users.modal.deactivate.title"),
        description: t("users.modal.deactivate.description"),
        buttonTitle: t("common.deactivate"),
        onSubmit: handleDeactivateUser,
        rank: 1,
      })
    );
  };

  const handleActivate = () => {
    dispatch(
      toggleSetUserModal({
        title: t("users.modal.activate.title"),
        description: t("users.modal.activate.description"),
        buttonTitle: t("common.activate"),
        onSubmit: handleActivateUser,
        rank: 1,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  return (
    <NewUserContainerForm onSubmit={formik?.handleSubmit}>
      <PageTitle
        title={
          props?.isEditing &&
          props?.prefilledData?.firstName &&
          props?.prefilledData?.lastName
            ? `${props?.prefilledData?.firstName} ${props?.prefilledData?.lastName}`
            : props?.title
        }
        isEditing={props?.isEditing}
        showBlock={!props?.prefilledData?.blocked}
        showUnblock={props?.prefilledData?.blocked}
        blockTooltip="users.deactivateUser"
        unblockTooltip="users.activateUser"
        onBlockClick={handleDeactivate}
        onUnblockClick={handleActivate}
        disableSaveButton={isSavingEnabled}
        onSubmitClick={formik?.handleSubmit}
        hideActionButtons={props?.prefilledData?.blocked}
      />
      {props?.isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <NewUserInputsContainer>
            <NewUserBasicInfo>
              <NewUserInfoHeader>{t("users.basicInfo")}</NewUserInfoHeader>
              <Line />
              <FirstNameField
                formik={formik}
                isRequired={
                  newUserValidationSchema(props?.isEditing)?.fields?.firstName
                    ?.exclusiveTests?.required
                }
                isReadOnly={props?.isDeactive}
              />
              <LastNameField
                formik={formik}
                isRequired={
                  newUserValidationSchema(props?.isEditing)?.fields?.lastName
                    ?.exclusiveTests?.required
                }
                isReadOnly={props?.isDeactive}
              />
              <RolePicker
                formik={formik}
                isRequired={
                  newUserValidationSchema(props?.isEditing)?.fields?.userRole
                    ?.exclusiveTests?.min
                }
                isReadOnly={props?.isDeactive}
              />
            </NewUserBasicInfo>
            <NewUserAcountInfo>
              <NewUserInfoHeader>{t("users.accountInfo")}</NewUserInfoHeader>
              <Line />
              <EmailField
                formik={formik}
                isRequired={
                  newUserValidationSchema(props?.isEditing)?.fields?.email
                    ?.exclusiveTests?.required
                }
                isReadOnly={props?.isDeactive}
              />
              {props?.isEditing ? (
                <>
                  {!props?.isDeactive && (
                    <PasswordButtonContainer>
                      <PasswordValidationIcon>
                        {formik?.errors?.password &&
                          formik?.touched?.password && <ErrorIcon />}
                        {formik?.values?.password && <TickIcon />}
                      </PasswordValidationIcon>
                      <PasswordButton
                        variant="contained"
                        onClick={handleChangePassword}
                        value={formik?.values?.password}
                      >
                        {t("users.setPassword")}
                        <SetPasswordIcon />
                      </PasswordButton>
                    </PasswordButtonContainer>
                  )}
                  {formik?.errors?.password && formik?.touched?.password && (
                    <ErrorMessage $rightAlign>
                      {t(formik?.errors?.password)}
                    </ErrorMessage>
                  )}
                </>
              ) : (
                <>
                  <PasswordField formik={formik} isRequired={true} />
                  <ConfirmPasswordField formik={formik} />
                  <PasswordValidationText style={{ marginTop: "34px" }}>
                    <Trans
                      i18nKey={"changePassword.validationPasswordDescription"}
                    />
                  </PasswordValidationText>
                </>
              )}
            </NewUserAcountInfo>
          </NewUserInputsContainer>
          {!props?.isDeactive && isMobile && (
            <ActionButtons
              handleClickSubmitButton={formik?.handleSubmit}
              disabledSaveButton={isSavingEnabled}
            />
          )}
        </>
      )}
    </NewUserContainerForm>
  );
};

NewUserForm.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  title: PropTypes.string,
  dispatchFunction: PropTypes.func,
  prefilledData: PropTypes.any,
  userId: PropTypes.any,
  companyId: PropTypes.any,
  submitText: PropTypes.string,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDeactive: PropTypes.bool,
  customRedirect: PropTypes.bool,
};

export default NewUserForm;
