import { Box } from "@mui/material";
import styled from "styled-components";
import TickCircledIcon from "../../../../../../Icon/Icons/TickCircledIcon";
import TextInputField from "../../../../../../Field/TextInputField/TextInputField";
import DeleteIcon from "../../../../../../Icon/Icons/DeleteIcon";

export const TemplateSectionsListItemEditContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;
export const CancelEditIcon = styled(DeleteIcon)`
  cursor: pointer;
  & path {
    stroke: black;
  }
`;
export const FinishEditIcon = styled(TickCircledIcon)`
  cursor: pointer;
`;
export const SectionTitleField = styled(TextInputField)`
  margin: 0;
  width: 274px;
  & * {
    max-width: 274px;
  }
`;
