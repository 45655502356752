import React, { useState } from "react";
import PropTypes from "prop-types";
import { ButtonsContainer, CloseButton, ModalTitle } from "../Modal.styled";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import {
  CancelButton,
  SubmitButton,
  FindingTypesModalContainer,
  FindingTypesSelectContainer,
} from "./FindingTypesModal.styled";
import { useTranslation } from "react-i18next";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import FindingTypesSelect from "../../Dropdown/FindingTypeSelect/FindingTypesSelect";

const FindingTypesModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = () => {
    props?.handleSubmit?.(value);
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <FindingTypesModalContainer rank={props?.rank}>
        <ModalTitle>{props?.title}</ModalTitle>
        <CloseButton onClick={handleCloseModal} />
        <FindingTypesSelectContainer>
          <FindingTypesSelect value={value} onChange={handleChange} />
        </FindingTypesSelectContainer>
        <ButtonsContainer>
          <CancelButton
            onClick={handleCloseModal}
            $variant={props?.cancelButtonVariant}
          >
            {t("common.cancel")}
          </CancelButton>
          <SubmitButton onClick={handleSubmit}>{t("common.add")}</SubmitButton>
        </ButtonsContainer>
      </FindingTypesModalContainer>
    </>
  );
};

FindingTypesModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelButtonVariant: PropTypes.number,
  rank: PropTypes.number,
  history: PropTypes.any,
  handleSubmit: PropTypes.func,
};

FindingTypesModal.defaultProps = {
  cancelButtonVariant: 1,
  data: [],
  handleSubmit: () => {}
};

export default FindingTypesModal;
