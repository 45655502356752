import {
  FormControl,
  RadioGroup,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";

export const FindingTypesContainer = styled(FormControl)`
  padding-left: 12px;
`;
export const FindingTypesLabel = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-feature-settings: "salt" on;
  text-align: left;
  margin-bottom: 6px;
  margin-left: -12px;
  color: ${selectedTheme.colors.labelTextColor};
`;

export const FindingTypes = styled(RadioGroup)`
  gap: 6px;
`;

