import React, { useCallback, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { UsersTableContainer } from "./UsersTable.styled";
import Table from "../../../Table/Table";
import usersTableConstants from "../../../../constants/tableConstants/usersTableConstants";
import { useTranslation } from "react-i18next";
import TextRenderer from "../../../Table/Renderers/TextRenderer/TextRenderer";
import {
  fetchUsers,
  fetchUsersClear,
} from "../../../../store/actions/user/userActions";
import {
  selectTotalUsers,
  selectUsersData,
} from "../../../../store/selectors/usersSelectors";
import { formatDateTime } from "../../../../util/helpers/dateHelpers";
import { findRoleByRoleName } from "../../../../constants/rolesConstants";
import history from "../../../../store/utils/history";
import { EDIT_USER, NEW_USER } from "../../../../constants/pages";
import { useLocation } from "react-router-dom";
import { makeToastMessage } from "../../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../../constants/toastConstants";
import { replaceInRoute } from "../../../../util/helpers/routeHelpers";

const UsersTable = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location?.state?.forceRefetch) {
      tableRef?.current?.setPage(location?.state?.page);
      tableRef?.current?.setSearchValue(location?.state?.searchValue);
      let currentState = { ...history?.location?.state };
      delete currentState.page;
      delete currentState.searchValue;
      delete currentState.forceRefetch;
      history.replace({
        state: currentState,
      });
    }
    if (location?.state?.isBlocked) {
      makeToastMessage(
        {
          title: t("toast.success.deactivateTitle", {
            typeOfData: t("users.typeOfData"),
          }),
          description: t("toast.success.deactivateDescription", {
            name: history.location.state?.name,
            typeOfData: t("users.typeOfDataPlural").toLowerCase(),
          }),
        },
        {
          containerId: APPLICATION_TOAST_CONTAINER_ID,
        }
      );
      let currentState = { ...history?.location?.state };
      delete currentState.name;
      delete currentState.isBlocked;
      history.replace({
        state: currentState,
      });
    }
  }, [location]);

  const tableColumns = useMemo(() => {
    return Object.keys(usersTableConstants).map((property) => ({
      ...usersTableConstants[property],
      field: t(usersTableConstants[property].i18key),
      backendProperty:
        usersTableConstants[property]?.backendProperty || property,
      propertyName: property,
      style: usersTableConstants[property]?.style,
      renderer: usersTableConstants[property]?.renderer || TextRenderer,
    }));
  }, [usersTableConstants, t]);

  const mapDataFunction = useCallback(
    (newData) =>
      newData?.map?.((singleData) => ({
        users: `${singleData?.firstName} ${singleData?.lastName}`,
        email: singleData?.email || "Not provided",
        lastLogin:
          new Date(singleData?.lastLoginDate)?.getFullYear() < 2000
            ? ""
            : formatDateTime(singleData?.lastLoginDate),
        role: singleData?.roles?.map((item) => findRoleByRoleName(item)),
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
        actions: {
          firstName: singleData?.firstName,
          lastName: singleData?.lastName,
          email: singleData?.email || "Not provided",
          role: singleData?.roles?.map((item) => findRoleByRoleName(item)),
          lastLogin: formatDateTime(singleData?.lastLoginDate),
          userId: singleData?.id,
          routeState: {
            page: tableRef?.current?.page,
            searchValue: tableRef?.current?.searchValue,
          },
          totalData: newData?.length,
          tableRef,
          t,
          isActiveTab: true,
          refetchTable
        },
      })),
    []
  );

  const refetchTable = () => {
    tableRef?.current?.forceRefetch();
  };

  const redirectHrefFunction = (userId) => {
    return {
      pathname: replaceInRoute(EDIT_USER, { userId: userId }),
      state: {
        page: tableRef?.current?.page,
        searchValue: tableRef?.current?.searchValue,
      },
    };
  };
  return (
    <UsersTableContainer>
      <Table
        tableColumns={tableColumns}
        smallerTextForMobile
        searchPlaceholder={t("users.searchPlaceholder")}
        redirectHrefFunction={redirectHrefFunction}
        dispatchFunction={fetchUsers}
        customPayload={{ isActive: true }}
        initialPage={location?.state?.page}
        addButtonHref={{
          pathname: NEW_USER,
          state: {
            page: tableRef?.current?.page,
            searchValue: tableRef?.current?.searchValue,
          },
        }}
        initialSearchValue={location?.state?.searchValue}
        dataSelector={selectUsersData}
        totalDataSelector={selectTotalUsers}
        mapDataFunction={mapDataFunction}
        clearDispatchFunction={fetchUsersClear}
        ref={tableRef}
      />
    </UsersTableContainer>
  );
};

UsersTable.propTypes = {
  children: PropTypes.node,
};

export default UsersTable;
