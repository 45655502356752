import { createSelector } from "reselect";
import { statusTableConstants } from "../../constants/findingStatusConstants";
// import _ from "lodash";

const findingsSelector = (state) => state.findings;

export const selectFindings = createSelector(
  findingsSelector,
  (state) => state.findings
);
export const selectTotalFindings = createSelector(
  findingsSelector,
  (state) => state.totalFindings
);
export const selectSingleFinding = createSelector(
  findingsSelector,
  (state) => state.singleFinding
);
export const selectSingleFindingPreview = createSelector(
  findingsSelector,
  (state) => state.previewFinding
);
export const selectSingleFindingOld = createSelector(
  findingsSelector,
  (state) => state.singleFindingOld
);
export const selectSingleFindingToEdit = createSelector(
  findingsSelector,
  (state) => state.singleFindingToEdit
);
export const selectFindingsData = createSelector(
  findingsSelector,
  (state) => state.findings.data
);
export const selectSecondFindingsData = createSelector(
  findingsSelector,
  (state) => state.findingsSecond.data
);
export const selectFindingsError = createSelector(
  findingsSelector,
  (state) => state.errorMessage
);
export const selectFindingSubjectsAll = createSelector(
  findingsSelector,
  (state) => state?.singleFinding?.findingSubjects
);
export const selectSubjectsToAdd = createSelector(
  findingsSelector,
  (state) => state?.scanningSubjectsToAdd
);
export const selectSubjectsToRemove = createSelector(
  findingsSelector,
  (state) => state?.scanningSubjectsToRemove
);
export const selectIsFindingSettingsChanged = createSelector(
  findingsSelector,
  (state) => state?.findingsChanged
);
export const selectFindingMeasuringInstruments = (findingSubjectId) =>
  createSelector(findingsSelector, (state) => {
    let subject =
      state?.singleFinding?.findingSubjects?.find?.(
        (singleSubject) => singleSubject?.id === findingSubjectId
      ) || state?.singleFinding?.findingSubjects?.[0];
    return subject?.measuringInstruments;
  });
export const selectFindingStandards = (findingSubjectId) =>
  createSelector(findingsSelector, (state) => {
    let subject =
      state?.singleFinding?.findingSubjects?.find?.(
        (singleSubject) => singleSubject?.id === findingSubjectId
      ) || state?.singleFinding?.findingSubjects?.[0];
    return subject?.standards;
  });
export const selectFindingSubject = (findingSubjectId) =>
  createSelector(
    findingsSelector,
    (state) =>
      state?.singleFinding?.findingSubjects?.find?.(
        (singleSubject) => singleSubject?.id === findingSubjectId
      ) ||
      state?.singleFinding?.findingSubjects?.filter?.(
        (singleSubject) => !singleSubject?.removed
      )?.[0]
  );
export const selectFindingSubjectOld = (findingSubjectId) =>
  createSelector(
    findingsSelector,
    (state) =>
      state?.singleFindingOld?.findingSubjects?.find?.(
        (singleSubject) => singleSubject?.id === findingSubjectId
      ) || state?.singleFindingOld?.findingSubjects?.[0]
  );
export const selectFindingSubjectSection = (findingSubjectSectionId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex;
    let sectionIndex;
    let found = false;
    for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
      findingSubjectIndex = i;
      if (state?.singleFinding?.findingSubjects?.[i]?.removed) continue;
      for (
        let j = 0;
        j < state?.singleFinding?.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        sectionIndex = j;
        // debugger; //eslint-disable-line
        if (
          state?.singleFinding?.findingSubjects?.[i]?.sections?.[j]?.id ===
          findingSubjectSectionId
        ) {
          found = true;
          break;
        }
      }
      if (found) break;
    }
    if (!found) {
      findingSubjectIndex = 0;
      sectionIndex = 0;
    }
    return state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
      ?.sections?.[sectionIndex];
  });
export const selectFindingSubjectSectionOld = (findingSubjectSectionId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex;
    let sectionIndex;
    let found = false;
    for (let i = 0; i < state?.singleFindingOld?.findingSubjects?.length; i++) {
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state?.singleFindingOld?.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        sectionIndex = j;
        // debugger; //eslint-disable-line
        if (
          state?.singleFindingOld?.findingSubjects?.[i]?.sections?.[j]?.id ===
          findingSubjectSectionId
        ) {
          found = true;
          break;
        }
      }
      if (found) break;
    }
    if (!found) return null;
    return state?.singleFindingOld?.findingSubjects?.[findingSubjectIndex]
      ?.sections?.[sectionIndex];
  });

export const selectFindingQuestion = (questionId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex;
    let sectionIndex;
    let questionIndex;
    let found = false;
    for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state?.singleFinding?.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        sectionIndex = j;
        // debugger; //eslint-disable-line
        for (
          let l = 0;
          l <
          state?.singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          questionIndex = l;
          if (
            state?.singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[
              l
            ]?.id === questionId
          ) {
            found = true;
            break;
          }
        }
      }
      if (found) break;
    }
    if (!found) {
      findingSubjectIndex = 0;
      sectionIndex = 0;
    }
    return state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
      ?.sections?.[sectionIndex]?.items?.[questionIndex];
  });

export const selectFindingQuestionOld = (questionId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex;
    let sectionIndex;
    let questionIndex;
    let found = false;
    for (let i = 0; i < state?.singleFindingOld?.findingSubjects?.length; i++) {
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state?.singleFindingOld?.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        sectionIndex = j;
        // debugger; //eslint-disable-line
        for (
          let l = 0;
          l <
          state?.singleFindingOld?.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          questionIndex = l;
          if (
            state?.singleFindingOld?.findingSubjects?.[i]?.sections?.[j]
              ?.items?.[l]?.id === questionId
          ) {
            found = true;
            break;
          }
        }
        if (found) break;
      }
      if (found) break;
    }
    if (!found) return null;
    return state?.singleFindingOld?.findingSubjects?.[findingSubjectIndex]
      ?.sections?.[sectionIndex]?.items?.[questionIndex];
  });

export const selectFindingSavingDetails = createSelector(
  findingsSelector,
  (state) => {
    try {
      let singleFinding = state?.singleFinding;
      let singleFindingOld = state?.singleFindingOld || {};
      let isClosedStateAllowed =
        singleFindingOld?.findingStateId === statusTableConstants.completed.id;
      let isSavingAllowed = false;
      let itemsToEdit = [];
      for (let i = 0; i < singleFinding?.findingSubjects?.length; i++) {
        if (singleFinding?.findingSubjects?.[i]?.removed) continue;
        if (
          !isSavingAllowed &&
          // singleFinding?.findingSubjects?.[i]?.scanningSubjectId ===
          // singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId &&
          singleFinding?.findingSubjects?.[i]?.conclusion?.edited
        ) {
          isSavingAllowed = true;
        }
        for (
          let j = 0;
          j < singleFinding?.findingSubjects?.[i]?.sections?.length;
          j++
        ) {
          if (
            !isSavingAllowed &&
            // singleFinding?.findingSubjects?.[i]?.scanningSubjectId ===
            // singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId &&
            singleFinding?.findingSubjects?.[i]?.sections?.[j]?.note?.edited
          ) {
            isSavingAllowed = true;
          }
          for (
            let l = 0;
            l <
            singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.length;
            l++
          ) {
            if (
              !isSavingAllowed &&
              // singleFinding?.findingSubjects?.[i]?.scanningSubjectId ===
              // singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId &&
              (singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
                ?.answer?.edited ||
                singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
                  ?.isInRange?.edited ||
                singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[
                  l
                ]?.images?.some(
                  (singleImage) =>
                    singleImage?.added ||
                    singleImage?.removed ||
                    singleImage?.edited
                ))
            ) {
              isSavingAllowed = true;
            }
            if (
              // singleFinding?.findingSubjects?.[i]?.scanningSubjectId ===
              //   singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId &&
              singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
                ?.isMandatory &&
              (singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
                ?.answer?.value?.length === 0 ||
                singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
                  ?.answer?.value === "<p></p>\n") &&
              singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[
                l
              ]?.images?.filter?.((singleImage) => !singleImage?.removed)
                ?.length === 0 &&
              singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
                ?.columnAnswers?.length === 0
            ) {
              itemsToEdit?.push?.({
                item: {
                  index: l,
                  question:
                    singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[
                      l
                    ]?.question,
                },
                section: {
                  index: j,
                  id: singleFinding?.findingSubjects?.[i]?.sections?.[j]?.id,
                  name: singleFinding?.findingSubjects?.[i]?.sections?.[j]
                    ?.title,
                },
                subject: {
                  index:
                    singleFinding?.findingSubjects?.[i]?.orderNumber?.value ??
                    i,
                  id: singleFinding?.findingSubjects?.[i]?.id,
                  name: singleFinding?.findingSubjects?.[i]?.scanningSubject
                    ?.name,
                },
              });
            }
          }
        }
      }
      return {
        isClosedStateAllowed,
        itemsToEdit: itemsToEdit?.sort(
          (a, b) => a?.subject?.index - b?.subject?.index
        ),
        isSavingAllowed,
        isCompleteStateAllowed: itemsToEdit?.length === 0,
      };
    } catch (e) {
      console.log(e);
      return {
        isClosedStateAllowed: false,
        itemsToEdit: [],
        isSavingAllowed: false,
        isCompleteStateAllowed: false,
      };
    }
  }
);

//NEW

// export const selectSingleFinding = createSelector(
//   findingsSelector,
//   (state) => state?.singleFinding
// );

export const selectSingleFindingSchemas = createSelector(
  findingsSelector,
  (state) =>
    // state?.isEditing
    // ? reverseEditing(state?.singleFinding?.workspaceSchemas.value)
    state?.singleFinding?.workspaceSchemas
);
export const selectSingleFindingSchemasOld = createSelector(
  findingsSelector,
  (state) =>
    // state?.isEditing
    // ? reverseEditing(state?.singleFinding?.workspaceSchemas.value)
    state?.singleFindingOld?.workspaceSchemas
);

export const selectSingleFindingIsEditing = createSelector(
  findingsSelector,
  (state) => state?.isEditing
);

export const selectSingleFindingStaffs = createSelector(
  findingsSelector,
  (state) => state?.singleFinding?.staffs
);

export const selectSingleFindingStaffsOld = createSelector(
  findingsSelector,
  (state) =>
    // state?.isEditing
    // ? reverseEditing(state?.singleFinding?.staffs.value)
    state?.singleFindingOld?.staffs
);

export const selectSingleFindingSettings = createSelector(
  findingsSelector,
  (state) => {
    const settings = {
      description: state?.singleFinding?.description,
      closingDate: state?.singleFinding?.closingDate,
      clientId: state?.singleFinding?.clientId,
      staffId: state?.singleFinding?.staffId,
      client: state?.singleFinding?.client,
      staff: state?.singleFinding?.staff,
      location: state?.singleFinding?.location,
      date: state?.singleFinding?.date,
      note: state?.singleFinding?.note,
    };
    return settings;
  }
);
export const selectSingleFindingSettingsOld = createSelector(
  findingsSelector,
  (state) => {
    const settings = {
      description: state?.singleFindingOld?.description,
      closingDate: state?.singleFindingOld?.closingDate,
      clientId: state?.singleFindingOld?.clientId,
      client: state?.singleFindingOld?.client,
      staffId: state?.singleFindingOld?.staffId,
      staff: state?.singleFindingOld?.staff,
      location: state?.singleFindingOld?.location,
      date: state?.singleFindingOld?.date,
      note: state?.singleFindingOld?.note,
    };
    return settings;
  }
);

export const selectSingleFindingNote = createSelector(
  findingsSelector,
  (state) =>
    // state?.isEditing
    // ? state?.singleFinding?.conclusion.value
    state?.singleFinding?.conclusion
);
export const selectSingleFindingNoteOld = createSelector(
  findingsSelector,
  (state) =>
    // state?.isEditing
    // ? state?.singleFinding?.conclusion.value
    state?.singleFindingOld?.conclusion
);

export const selectScanningSubjectConclusion = (sectionId) =>
  createSelector(findingsSelector, (state) =>
    state?.scanningSubjectsSelectedConclusions?.find(
      (conclusion) => conclusion?.id === sectionId
    )
  );

export const selectFindingQuestionWithSameName = (questionId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex;
    let sectionIndex;
    let questionIndex;
    let found = false;
    for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state?.singleFinding?.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        sectionIndex = j;
        // debugger; //eslint-disable-line
        for (
          let l = 0;
          l <
          state?.singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          questionIndex = l;
          if (
            state?.singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[
              l
            ]?.id === questionId
          ) {
            found = true;
            break;
          }
        }
        if (found) break;
      }
      if (found) break;
    }
    if (!found) {
      findingSubjectIndex = 0;
      sectionIndex = 0;
    }
    const foundQuestion =
      state?.singleFinding?.findingSubjects?.[findingSubjectIndex]?.sections?.[
        sectionIndex
      ]?.items?.[questionIndex];
    let aaa =
      state?.singleFinding?.findingSubjects?.[findingSubjectIndex]?.sections?.[
        sectionIndex
      ]?.items?.filter(
        (singleQuestion) => singleQuestion?.question === foundQuestion?.question
      )?.length > 1;
    return aaa;
  });
export const selectFindingColumn = (columnId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex = 0;
    let sectionIndex = 0;
    let questionIndex = 0;
    let columnIndex = 0;
    let found = false;
    for (let i = 0; i < state.singleFinding.findingSubjects.length; i++) {
      if (found) continue;
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state.singleFinding.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        if (found) continue;
        sectionIndex = j;
        for (
          let l = 0;
          l <
          state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          if (found) continue;
          questionIndex = l;
          for (
            let k = 0;
            k <
            state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
              ?.columns?.value?.length;
            k++
          ) {
            if (found) continue;
            columnIndex = k;
            if (
              state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items?.[
                l
              ]?.columns?.value?.[k]?.columnId === columnId
            ) {
              found = true;
              break;
            }
          }
        }
      }
    }
    if (found) {
      return state.singleFinding.findingSubjects?.[findingSubjectIndex]
        ?.sections?.[sectionIndex]?.items?.[questionIndex]?.columns?.value?.[
        columnIndex
      ];
    } else {
      return null;
    }
  });

export const selectFindingColumnAnswers = (questionId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex = 0;
    let sectionIndex = 0;
    let questionIndex = 0;
    let found = false;
    for (let i = 0; i < state.singleFinding.findingSubjects.length; i++) {
      if (found) continue;
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state.singleFinding.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        if (found) continue;
        sectionIndex = j;
        for (
          let l = 0;
          l <
          state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          if (found) continue;
          questionIndex = l;
          if (
            questionId ===
            state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
              ?.id
          ) {
            found = true;
            break;
          }
        }
      }
    }
    if (found) {
      return state.singleFinding.findingSubjects?.[findingSubjectIndex]
        ?.sections?.[sectionIndex]?.items?.[questionIndex]?.columnAnswers;
    } else {
      return null;
    }
  });
export const selectFindingColumnAnswersToEdit = (questionId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex = 0;
    let sectionIndex = 0;
    let questionIndex = 0;
    let found = false;
    for (
      let i = 0;
      i < state?.singleFindingToEdit?.findingSubjects?.length;
      i++
    ) {
      if (found) continue;
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        if (found) continue;
        sectionIndex = j;
        for (
          let l = 0;
          l <
          state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          if (found) continue;
          questionIndex = l;
          if (
            questionId ===
            state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.[j]
              ?.items?.[l]?.id
          ) {
            found = true;
            break;
          }
        }
      }
    }
    if (found) {
      return state?.singleFindingToEdit?.findingSubjects?.[findingSubjectIndex]
        ?.sections?.[sectionIndex]?.items?.[questionIndex]?.columnAnswers;
    } else {
      return null;
    }
  });
export const selectFindingColumnAnswer = (payload) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex = 0;
    let sectionIndex = 0;
    let questionIndex = 0;
    let found = false;
    for (let i = 0; i < state.singleFinding.findingSubjects.length; i++) {
      if (found) continue;
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state.singleFinding.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        if (found) continue;
        sectionIndex = j;
        for (
          let l = 0;
          l <
          state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          if (found) continue;
          questionIndex = l;
          if (
            payload?.questionId ===
            state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
              ?.id
          ) {
            found = true;
            break;
          }
        }
      }
    }
    if (found) {
      let columnIndex = state.singleFinding.findingSubjects?.[
        findingSubjectIndex
      ]?.sections?.[sectionIndex]?.items?.[
        questionIndex
      ]?.columns?.value?.findIndex(
        (singleColumn) =>
          singleColumn?.id === payload?.columnId ||
          singleColumn?.columnId === payload?.columnId
      );
      return state.singleFinding.findingSubjects?.[
        findingSubjectIndex
      ]?.sections?.[sectionIndex]?.items?.[questionIndex]?.columns?.value?.[
        columnIndex
      ]?.answers?.find?.(
        (singleColumnAnswer) =>
          singleColumnAnswer?.rowIndex === payload?.rowIndex &&
          !singleColumnAnswer?.removed
      )?.answer;
    } else {
      return "";
    }
  });

export const selectFindingColumns = (questionId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex = 0;
    let sectionIndex = 0;
    let questionIndex = 0;
    let found = false;
    for (let i = 0; i < state.singleFinding.findingSubjects.length; i++) {
      if (found) continue;
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state.singleFinding.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        if (found) continue;
        sectionIndex = j;
        for (
          let l = 0;
          l <
          state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          if (found) continue;
          questionIndex = l;
          if (
            questionId ===
            state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
              ?.id
          ) {
            found = true;
            break;
          }
        }
      }
    }
    if (found) {
      return state.singleFinding.findingSubjects?.[findingSubjectIndex]
        ?.sections?.[sectionIndex]?.items?.[questionIndex]?.columns?.value;
    } else {
      return null;
    }
  });


export const selectSingleFindingAttendees = createSelector(
  findingsSelector,
  (state) => state?.singleFinding?.findingAttendees
);
export const selectColumnFromAnswerId = (answerId) =>
  createSelector(findingsSelector, (state) => {
    let findingSubjectIndex = 0;
    let sectionIndex = 0;
    let questionIndex = 0;
    let columnIndex = 0;
    let found = false;
    for (let i = 0; i < state.singleFinding.findingSubjects.length; i++) {
      if (found) continue;
      findingSubjectIndex = i;
      for (
        let j = 0;
        j < state.singleFinding.findingSubjects?.[i]?.sections?.length;
        j++
      ) {
        if (found) continue;
        sectionIndex = j;
        for (
          let l = 0;
          l <
          state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items
            ?.length;
          l++
        ) {
          if (found) continue;
          questionIndex = l;
          for (
            let k = 0;
            k <
            state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
              ?.columns?.value?.length;
            k++
          ) {
            if (found) continue;
            columnIndex = k;
            if (
              state.singleFinding.findingSubjects?.[i]?.sections?.[j]?.items?.[
                l
              ]?.columns?.value?.[k]?.answers?.some(
                (singleAnswer) => singleAnswer?.id === answerId
              )
            ) {
              found = true;
              break;
            }
          }
        }
      }
    }
    return state.singleFinding.findingSubjects?.[findingSubjectIndex]
      ?.sections?.[sectionIndex]?.items?.[questionIndex]?.columns?.value?.[
      columnIndex
    ];
  });
