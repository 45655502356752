import React, { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  ButtonsContainer,
  QuestionOrdinalNumber,
  QuestionOrdinalNumberContainer,
  CancelButton,
  SaveButton,
  TemplateSetQuestionContainer,
  TemplateSetQuestionTitle,
  TextInput,
  TextInputContainer,
  ChapterTitle,
  DescriptionContainer,
  ModalErrorContainer,
} from "./SetScanningObjectModal.styled";
import { useTranslation } from "react-i18next";
import QuestionTypeSelector from "./QuestionTypeSelector/QuestionTypeSelector";
import questionTypeConstants from "../../../constants/questionTypeConstants";
import TextTypeInputs from "./QuestionTypesInputs/TextTypeInputs/TextTypeInputs";
import SelectTypeInputs from "./QuestionTypesInputs/SelectTypeInputs/SelectTypeInputs";
import ImageTypeInputs from "./QuestionTypesInputs/ImageTypeInputs/ImageTypeInputs";
import QuestionCheckbox from "../../Cards/QuestionCheckbox/QuestionCheckbox";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { DEFAULT_PLACEHOLDER } from "../../../constants/defaultPlaceholderConstant";
import { setScanningObjectsChanged } from "../../../store/actions/scanningObjects/scanningObjectsActions";
import TableTypeInputs from "./QuestionTypesInputs/TableTypeInputs/TableTypeInputs";

const SetScanningObjectModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const textInputsRef = useRef(null);
  const selectInputsRef = useRef(null);
  const tableInputsRef = useRef(null);
  const [questionName, setQuestionName] = useState(props?.questionName);
  const [modalError, setModalError] = useState(null);
  const [description, setDescription] = useState({
    descriptionOne: props?.descriptionOne,
    descriptionTwo: props?.descriptionTwo,
  });
  const [questionType, setQuestionType] = useState(props?.questionType);
  const [isRequired, setIsRequired] = useState(props?.isRequired);
  const [hasResearchPassed, setHasResearchPassed] = useState(
    props?.hasResearchPassed
  );
  const [textTypeInputs, setTextTypeInputs] = useState(props?.textTypeInputs);
  const [selectTypeInputs, setSelectTypeInputs] = useState(
    props?.selectTypeInputs
  );
  const [tableTypeInputs, setTableTypeInputs] = useState(
    props?.tableTypeInputs
  );
  const [error, setError] = useState(false);

  // useEffect(() => {
  //   if (props?.questionName) setQuestionName(props?.questionName);

  // })

  const handleChangeIsRequired = () => {
    setIsRequired((prevIsRequired) => !prevIsRequired);
  };

  const handleChangeQuestionName = (event) => {
    setQuestionName(event.target.value);
  };

  const handleCancelForm = () => {
    dispatch(closeModal());
  };

  const handleChangeDescription = (event, id) => {
    id === 1
      ? setDescription((prevState) => ({
          ...prevState,
          descriptionOne: event.target.value,
        }))
      : setDescription((prevState) => ({
          ...prevState,
          descriptionTwo: event.target.value,
        }));
  };

  const checkColumns = () => {
    let columns = tableInputsRef.current?.columns;
    let isValid = true;
    if (columns?.length === 0 || questionType !== questionTypeConstants.TABLE)
      return isValid;
    columns?.forEach?.((singleColumn) => {
      let columnTitle = singleColumn.columnTitle;
      if (singleColumn?.columnSubtitle?.length === 0) {
        if (
          columns.some(
            (singleColumn) =>
              singleColumn.columnTitle === columnTitle &&
              singleColumn.columnSubtitle?.length !== 0
          )
        )
          isValid = false;
      }
    });
    return isValid;
  };

  const handleSubmitForm = () => {
    setTimeout(() => {
      if (questionName?.length === 0) {
        setError(true);
        return;
      }
      if (checkColumns()) {
        setModalError(null);
      } else {
        return setModalError(t("questions.tableError"));
      }
      let questionObject = {
        questionName,
        isRequired,
        hasResearchPassed,
        questionType,
        descriptionOne: description?.descriptionOne,
        descriptionTwo: description?.descriptionTwo,
      };
      if (questionType === questionTypeConstants.TEXT) {
        questionObject = {
          ...questionObject,
          textTypeInputs: {
            defaultAnswer: textInputsRef.current?.defaultAnswer,
            note: textInputsRef.current?.note,
          },
        };
      }
      if (questionType === questionTypeConstants.SELECT) {
        questionObject = {
          ...questionObject,
          selectTypeInputs: {
            possibleAnswers: selectInputsRef.current?.possibleAnswers,
            defaultAnswerIndex:
              selectInputsRef.current?.defaultAnswerIndex ===
              DEFAULT_PLACEHOLDER
                ? selectInputsRef.current?.defaultAnswerIndex
                : Number(selectInputsRef.current?.defaultAnswerIndex),
          },
        };
      }
      if (questionType === questionTypeConstants.TABLE) {
        questionObject = {
          ...questionObject,
          tableTypeInputs: {
            columns: tableInputsRef.current?.columns,
          },
        };
      }
      if (props?.id) questionObject = { ...questionObject, id: props?.id };
      props?.handleSubmitForm(questionObject);
      dispatch(setScanningObjectsChanged(true));
      dispatch(closeModal());
    }, 0);
  };

  const handleChangeHasResearchPassed = () => {
    setHasResearchPassed((prevHasResearchPassed) => !prevHasResearchPassed);
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const errorMessage = useMemo(() => {
    if (questionName?.length === 0 && error) return t("questions.nameRequired");
    return "";
  }, [questionName, error]);

  const changeQuestionType = (newQuestionType) => {
    if (questionType === questionTypeConstants.TEXT)
      setTextTypeInputs({
        defaultAnswer: textInputsRef.current?.defaultAnswer,
        note: textInputsRef.current?.note,
      });
    if (questionType === questionTypeConstants.SELECT)
      setSelectTypeInputs({
        possibleAnswers: selectInputsRef.current?.possibleAnswers,
        defaultAnswerIndex: selectInputsRef.current?.defaultAnswerIndex,
      });
    if (questionType === questionTypeConstants.TABLE)
      setTableTypeInputs({
        columns: tableInputsRef.current?.columns,
      });
    setQuestionType(newQuestionType);
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <TemplateSetQuestionContainer
        questionType={questionType === questionTypeConstants.IMAGE}
      >
        <TemplateSetQuestionTitle>{props?.title}</TemplateSetQuestionTitle>
        <TextInputContainer>
          <TextInput
            placeholder={t("questions.namePlaceholder")}
            value={questionName}
            onChange={handleChangeQuestionName}
            label={t("questions.name")}
            requiredField={true}
            error={errorMessage}
          />
          <QuestionOrdinalNumberContainer>
            <ChapterTitle>{t("common.ordNo")}</ChapterTitle>
            <QuestionOrdinalNumber>
              {props?.ordinalNumber}
            </QuestionOrdinalNumber>
          </QuestionOrdinalNumberContainer>
        </TextInputContainer>
        {questionType !== questionTypeConstants.IMAGE &&
          questionType !== questionTypeConstants.TABLE && (
            <DescriptionContainer>
              <ChapterTitle>{t("questions.description")}</ChapterTitle>
              <TextInputContainer>
                <TextInput
                  value={description?.descriptionOne}
                  onChange={(event) => handleChangeDescription(event, 1)}
                  requiredField={true}
                />
                <TextInput
                  value={description?.descriptionTwo}
                  onChange={(event) => handleChangeDescription(event, 2)}
                  requiredField={true}
                />
              </TextInputContainer>
            </DescriptionContainer>
          )}
        <QuestionTypeSelector
          setSelectedOption={changeQuestionType}
          selectedOption={questionType}
        />
        {questionType === questionTypeConstants.TEXT && (
          <TextTypeInputs ref={textInputsRef} {...textTypeInputs} />
        )}
        {questionType === questionTypeConstants.SELECT && (
          <SelectTypeInputs
            ref={selectInputsRef}
            key={selectTypeInputs}
            {...selectTypeInputs}
          />
        )}
        {questionType === questionTypeConstants.IMAGE && <ImageTypeInputs />}
        {questionType === questionTypeConstants.TABLE && (
          <TableTypeInputs
            ref={tableInputsRef}
            key={tableTypeInputs}
            {...tableTypeInputs}
          />
        )}
        <QuestionCheckbox
          label={t("questions.requiredAnswer")}
          checked={isRequired}
          onChange={handleChangeIsRequired}
        />
        {questionType !== questionTypeConstants.TABLE && (
          <QuestionCheckbox
            label={t("questions.researchPassed")}
            labelSatisfaction={t("questions.researchPassedSatisfaction")}
            checked={hasResearchPassed}
            onChange={handleChangeHasResearchPassed}
          />
        )}
        {modalError && <ModalErrorContainer>{modalError}</ModalErrorContainer>}
        <ButtonsContainer>
          <CancelButton onClick={handleCancelForm}>
            {t("common.cancel")}
          </CancelButton>
          <SaveButton onClick={handleSubmitForm}>{t("common.save")}</SaveButton>
        </ButtonsContainer>
      </TemplateSetQuestionContainer>
    </>
  );
};

SetScanningObjectModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  ordinalNumber: PropTypes.number,
  questionName: PropTypes.string,
  descriptionOne: PropTypes.string,
  descriptionTwo: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  questionType: PropTypes.number,
  isRequired: PropTypes.bool,
  hasResearchPassed: PropTypes.bool,
  textTypeInputs: PropTypes.object,
  selectTypeInputs: PropTypes.object,
  tableTypeInputs: PropTypes.object,
  handleSubmitForm: PropTypes.func,
};
SetScanningObjectModal.defaultProps = {
  questionName: "",
  questionType: questionTypeConstants.TEXT,
  isRequired: false,
  hasResearchPassed: false,

  descriptionOne: "",
  descriptionTwo: "",

  textTypeInputs: {
    defaultAnswer: "",
    note: "",
  },
  tableTypeInputs: {
    columns: [""],
  },
  selectTypeInputs: {
    possibleAnswers: [""],
    defaultAnswerIndex: undefined,
  },
};

export default SetScanningObjectModal;
