import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../components/Buttons/PrimaryButton/PrimaryButton";
import TextInputField from "../../components/Field/TextInputField/TextInputField";
import {
  fetchEditUser,
  fetchSingleUser,
} from "../../store/actions/user/userActions";
import { selectMyProfileId } from "../../store/selectors/loginSelectors";
import { selectSingleUser } from "../../store/selectors/usersSelectors";
import { newUserInitialValues } from "../../initialValues/newUserInitialValues";
import { useHistory } from "react-router-dom";
import { makeToastMessage } from "../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../constants/toastConstants";
import {
  FormContainer,
  ProfilePageContainer,
  ButtonContainer,
  ButtonsAreaContainer,
} from "./ProfilePage.styled";
import settingsNewUserValidationSchema from "../../validations/settingsNewUserValidationSchema";
import { selectIsLoadingByActionType } from "../../store/selectors/loadingSelectors";
import { SINGLE_USER_SCOPE } from "../../store/actions/user/userActionConstants";
import CircularLoader from "../../components/Loader/CircularLoader/CircularLoader";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
} from "../../store/actions/modal/modalActions";
import Title from "../../components/Typography/Title/Title";
import { RequiredFieldsText } from "../../components/Clients/NewClient/NewClientForm.styled";
import i18n from "../../i18n";

const ProfilePage = () => {
  const { t } = useTranslation();
  const myProfileId = useSelector(selectMyProfileId);
  const dispatch = useDispatch();
  const prefilledData = useSelector(selectSingleUser);
  const history = useHistory();
  const isLoading = useSelector(selectIsLoadingByActionType(SINGLE_USER_SCOPE));
  useEffect(() => {
    dispatch(fetchSingleUser({ userId: myProfileId }));
  }, []);

  const handleApiResponseSuccess = () => {
    dispatch(closeModal());
    makeToastMessage(
      {
        title: t("users.userChangedSuccess"),
        description: i18n.t("toast.success.editedDescription", {
          name: `${formik?.values?.firstName} ${formik?.values?.lastName}`,
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    const userData = {
      firstname: values?.firstName,
      lastname: values?.lastName,
      email: values?.email,
      password: "",
      createdDate: values?.createdDate,
      roles: prefilledData.userRole,
    };

    dispatch(
      fetchEditUser({
        userData,
        userId: myProfileId,
        handleApiResponseSuccess,
        handleApiResponseError,
      })
    );
  };

  const formik = useFormik({
    initialValues: newUserInitialValues(prefilledData),
    validationSchema: settingsNewUserValidationSchema(),
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });
  return (
    <ProfilePageContainer>
      <Title>{t("pages.profile")}</Title>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <>
          <FormContainer onSubmit={formik?.handleSubmit}>
            <TextInputField
              name="firstName"
              label={t("users.firstName")}
              value={formik?.values?.firstName}
              onChange={formik?.handleChange}
              error={formik?.touched?.firstName && formik?.errors?.firstName}
              helperText={formik?.touched?.firstName && formik?.errors?.firstName}
              requiredField={
                settingsNewUserValidationSchema?.()?.fields?.firstName?.exclusiveTests
                  ?.required
              }
            />
            <TextInputField
              name="lastName"
              label={t("users.lastName")}
              value={formik?.values?.lastName}
              onChange={formik?.handleChange}
              error={formik?.touched?.lastName && formik?.errors?.lastName}
              helperText={formik?.touched?.lastName && formik?.errors?.lastName}
              requiredField={
                settingsNewUserValidationSchema?.()?.fields?.lastName?.exclusiveTests
                  ?.required
              }
            />
            <TextInputField
              name="email"
              label={t("users.email")}
              value={formik?.values?.email}
              onChange={formik?.handleChange}
              error={formik?.touched?.email && formik?.errors?.email}
              helperText={formik?.touched?.email && formik?.errors?.email}
              requiredField={
                settingsNewUserValidationSchema?.()?.fields?.email?.exclusiveTests
                  ?.required
              }
            />
          </FormContainer>
          <ButtonsAreaContainer>
            <RequiredFieldsText>
              {t("common.requiredFieldsText")}
            </RequiredFieldsText>
            <ButtonContainer>
              <PrimaryButton onClick={() => history.goBack()}>
                {t("companySettings.cancel")}
              </PrimaryButton>
              <PrimaryButton
                variant="contained"
                type="submit"
                onClick={formik?.handleSubmit}
              >
                {t("companySettings.save")}
              </PrimaryButton>
            </ButtonContainer>
          </ButtonsAreaContainer>
        </>
      )}
    </ProfilePageContainer>
  );
};

export default ProfilePage;
