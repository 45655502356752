import { useFormik } from "formik";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { DOCUMENTS_EDIT_PAGE, DOCUMENTS_PAGE } from "../../../constants/pages";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import useIsMobile from "../../../hooks/useIsMobile";
import {
  documentFormikConstants,
  documentInitialValues,
} from "../../../initialValues/documentInitialValues";
import { fetchDeleteDocument } from "../../../store/actions/documents/documentsActions";
import {
  closeLoadingModal,
  closeModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import history from "../../../store/utils/history";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { replaceInRoute } from "../../../util/helpers/routeHelpers";
import documentValidationSchema from "../../../validations/documentValidationSchema";
import CircularLoader from "../../Loader/CircularLoader/CircularLoader";
import PageTitle from "../../PageTitle/PageTitle";
import ClientField from "./ClientField/ClientField";
import DocumentDateField from "./DocumentDateField/DocumentDateField";
import DocumentNumberField from "./DocumentNumberField/DocumentNumberField";
import DocumentTypeField from "./DocumentTypeField/DocumentTypeField";
import LinkField from "./LinkField/LinkField";
import {
  NewDocumentContentContainer,
  NewDocumentInputsContainer,
  NewDocumentLeftColumn,
  NewDocumentRightColumn,
} from "./NewDocumentContent.styled";
import ActionButtons from "../../PageTitle/ActionButtons/ActionButtons";

const NewDocumentContent = (props) => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();
  const routeMatch = useRouteMatch();
  const { t } = useTranslation();
  const documentId = routeMatch.params?.documentId;

  const handleApiResponseSuccess = (payload) => {
    dispatch(closeModal());
    const titleI18Key = payload?.isDeleted
      ? "toast.success.deletedTitle"
      : payload?.isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = payload?.isDeleted
      ? "toast.success.deletedDescription"
      : payload?.isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("documents.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: payload?.name,
          typeOfData: t("documents.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
    if (!props?.isEditing) {
      history.replace(
        replaceInRoute(DOCUMENTS_EDIT_PAGE, {
          documentId: payload?.data?.id,
        })
      );
    }
    if (payload?.isDeleted) {
      history?.replace(DOCUMENTS_PAGE);
    }
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 1 }));
    const documentData = { ...values };
    dispatch(
      props?.dispatchFunction({
        documentData,
        documentId,
        handleApiResponseSuccess: (document) =>
          handleApiResponseSuccess({
            name: values?.documentNumber,
            isDeleted: false,
            data: document,
            isEditing: props?.isEditing,
          }),
        handleApiResponseError: handleApiResponseError,
      })
    );
  };

  const initialValues = useMemo(
    () => documentInitialValues(props?.prefilledData),
    [props?.prefilledData]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: documentValidationSchema(),
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  const handleDeleteDocument = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchDeleteDocument({
        id: documentId,
        handleApiResponseSuccess: (data) =>
          handleApiResponseSuccess({
            name: data?.name,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  return (
    <NewDocumentContentContainer onSubmit={formik?.handleSubmit}>
      <PageTitle
        title={props?.title}
        highlightedText={props?.prefilledData?.documentNumber}
        isEditing={props?.isEditing}
        showDelete
        deleteTooltip="documents.tooltip.deleteDocument"
        onDeleteClick={handleDeleteDocument}
        onSubmitClick={formik?.handleSubmit}
      />
      {props?.isLoading ? (
        <CircularLoader />
      ) : (
        <NewDocumentInputsContainer>
          <NewDocumentLeftColumn>
            <ClientField formik={formik} />
            <DocumentNumberField formik={formik} />
            <DocumentDateField
              formik={formik}
              key={formik?.values?.[documentFormikConstants.documentTypeId]}
            />
          </NewDocumentLeftColumn>

          <NewDocumentRightColumn>
            <DocumentTypeField formik={formik} />
            <LinkField formik={formik} />
          </NewDocumentRightColumn>
        </NewDocumentInputsContainer>
      )}
      {!props?.readOnly && isMobile && (
        <ActionButtons
          disabledSaveButton={isSavingEnabled}
          handleClickSubmitButton={formik?.handleSubmit}
        />
      )}
    </NewDocumentContentContainer>
  );
};

NewDocumentContent.propTypes = {
  title: PropTypes.string,
  submitText: PropTypes.string,
  prefilledData: PropTypes.any,
  dispatchFunction: PropTypes.func,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default NewDocumentContent;
