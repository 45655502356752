import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import {
  closeLoadingModal,
  closeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../store/actions/modal/modalActions";
import {
  fetchCreateFindingType,
  fetchDeleteFindingType,
  fetchEditFindingType,
} from "../../../store/actions/findingTypes/findingTypesActions";
import { newFindingTypeInitialValues } from "../../../initialValues/newFindingTypeInitialValues";
import { useFormik } from "formik";
import newFindingTypeValidationSchema from "../../../validations/newFindingTypeValidationSchema";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import {
  DeleteBtn,
  DeleteBtnContainer,
  DeleteBtnLabel,
  InputsContainer,
  SetFindingTypeModalContainer,
} from "./SetFindingTypeModal.styled";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import TrashIcon from "../../Icon/Icons/TrashIcon";
import i18n from "../../../i18n";
import CaptionField from "./CaptionField/CaptionField";
import SubcaptionField from "./SubcaptionField/SubcaptionField";
import NameField from "./NameField/NameField";

const SetFindingTypeModal = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { hasRole } = useAuth();

  const readOnlyModal = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleSubmit = (values) => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    if (props?.isEditing)
      dispatch(
        fetchEditFindingType({
          id: props?.id,
          name: values?.name?.replace?.(/  +/g, " ")?.trim?.(),
          caption: values?.caption?.replace?.(/  +/g, " ")?.trim?.(),
          subcaption: values?.subcaption?.replace?.(/  +/g, " ")?.trim?.(),
          handleApiResponseSuccess: () =>
            handleApiResponseSuccess({
              inputValue: values?.name,
              isDeleted: false,
            }),
          handleApiResponseError,
        })
      );
    else
      dispatch(
        fetchCreateFindingType({
          name: values?.name?.replace?.(/  +/g, " ")?.trim?.(),
          caption: values?.caption?.replace?.(/  +/g, " ")?.trim?.(),
          subcaption: values?.subcaption?.replace?.(/  +/g, " ")?.trim?.(),
          handleApiResponseSuccess: () =>
            handleApiResponseSuccess({
              inputValue: values?.name,
              isDeleted: false,
            }),
          handleApiResponseError: handleApiResponseError,
        })
      );
  };

  const initialValues = useMemo(
    () => newFindingTypeInitialValues(props?.prefilledData),
    [props?.prefilledData]
  );

  const formik = useFormik({
    initialValues,
    validationSchema: newFindingTypeValidationSchema(),
    onSubmit: handleSubmit,
  });

  const handleDelete = () => {
    dispatch(
      toggleSetDeleteModal({
        title: t("findingTypes.modal.delete.title"),
        description: {
          first: t("findingTypes.modal.delete.description.1"),
          second: t("findingTypes.modal.delete.description.2"),
        },
        onSubmit: handleDeleteFindingType,
        rank: 2,
      })
    );
  };

  const handleApiResponseSuccess = (values) => {
    dispatch(closeModal());
    props?.refetchTable(values);
  };

  const handleApiResponseError = () => {
    dispatch(closeLoadingModal());
  };

  const handleDeleteFindingType = () => {
    dispatch(toggleSetLoadingModal({ rank: 2 }));
    dispatch(
      fetchDeleteFindingType({
        id: props?.id,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccess({
            inputValue: formik?.values?.name,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const isSavingEnabled = useMemo(() => {
    if (formik?.dirty) {
      return false;
    }
    return true;
  }, [formik?.dirty]);

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <SetFindingTypeModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{t(props?.title)}</ModalTitle>
        <InputsContainer>
          <NameField formik={formik} disabled={readOnlyModal} />
          <CaptionField formik={formik} disabled={readOnlyModal} />
          <SubcaptionField formik={formik} disabled={readOnlyModal} />
        </InputsContainer>
        {props?.isEditing === true && !readOnlyModal ? (
          <DeleteBtnContainer>
            <DeleteBtn onClick={handleDelete}>
              <TrashIcon />
              <DeleteBtnLabel>
                {t("findingTypes.modal.deleteBtnLabel")}
              </DeleteBtnLabel>
            </DeleteBtn>
          </DeleteBtnContainer>
        ) : (
          <></>
        )}
        {!readOnlyModal && (
          <ButtonsContainer>
            <CancelButton onClick={handleCloseModal}>
              {t("common.cancel")}
            </CancelButton>
            <SubmitButton
              onClick={isSavingEnabled ? () => {} : formik?.handleSubmit}
              disabled={isSavingEnabled}
            >
              {t("common.save")}
            </SubmitButton>
          </ButtonsContainer>
        )}
      </SetFindingTypeModalContainer>
    </>
  );
};

SetFindingTypeModal.propTypes = {
  children: PropTypes.node,
  rank: PropTypes.number,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  deleteProps: PropTypes.object,
  isEditing: PropTypes.bool,
  selectedTypes: PropTypes.any,
  tableRef: PropTypes.any,
  id: PropTypes.number,
  refetchTable: PropTypes.func,
  prefilledData: PropTypes.object,
};
SetFindingTypeModal.defaultProps = {
  title: i18n.t("findingTypes.modal.defaultTitle"),
  onSubmit: () => {},
};

export default SetFindingTypeModal;
