import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
//import selectedTheme from "../../../themes";

export const GoogleDriveInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const VerificationContent = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  max-width: 370px;
  align-items: center;
  padding: 0px;
  gap: 32px;
  margin-top: 30px;
  @media (max-width: 600px) {
    padding-bottom: ${(props) => props?.$extraBottomPadding && "32px"};
  }
`;

export const VerificationTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 11px;
  max-width: 250px;
  & svg {
    width: 17px;
    height: 17px;
    margin-top: 2px;
  }
`;
export const VerificationText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 200px;
  word-break: break-word;
  color: ${selectedTheme.colors.labelTextColor};
`;
export const VerificationButton = styled(Button)`
  background-color: ${(props) =>
    props?.disabled
      ? selectedTheme.colors.disabledButtonBackground
      : selectedTheme.colors.primaryButtonDarkColor};
  color: ${selectedTheme.colors.white};
  text-transform: none;
  padding: 8px 14px;
  gap: 8px;
  width: 85px;
  height: 36px;
  border-radius: 6px;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;
  &:hover {
    background-color: ${selectedTheme.colors.orangeLightHover};
  }
`;

export const FileNameText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: inline;
  color: ${selectedTheme.colors.gray};
`;

export const ErrorMessage = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 12px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  text-align: ${(props) => props?.$rightAlign && "right"};
  margin-top: 5px;
`;
