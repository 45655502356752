import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";

export const UsersContentContainer = styled(Box)``;

export const TabsContainer = styled(Box)`
  display: flex;
  margin-top: 38px;
  margin-bottom: 20px;
`;

export const ActiveUser = styled(Box)`
  margin-right: 24px;
  cursor: pointer;

  &:hover {
    border-bottom: 3px solid #161616;
  }

  ${(props) => props?.active && `border-bottom: 3px solid #161616;`}
`;

export const NavText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
`;

export const DeactiveUser = styled(Box)`
  cursor: pointer;

  &:hover {
    border-bottom: 3px solid #161616;
  }

  ${(props) => !props?.active && `border-bottom: 3px solid #161616;`}
`;
