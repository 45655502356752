import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Prompt, useLocation } from "react-router-dom";
import NewFindingContent from "../../../components/Findings/NewFinding/NewFindingContent";
import { GET_CLIENTS_SCOPE } from "../../../store/actions/clients/clientsActionConstants";
import { fetchClients } from "../../../store/actions/clients/clientsActions";
import { SINGLE_FINDING_SCOPE } from "../../../store/actions/findings/findingsActionConstants";
import {
  clearSingleFinding,
  revertOldFinding,
  setFindingsChanged,
} from "../../../store/actions/findings/findingsActions";
import { SCANNING_OBJECTS_SCOPE } from "../../../store/actions/scanningObjects/scanningObjectsActionConstants";
import {
  clearSingleScanningObject,
  fetchScanningObjects,
} from "../../../store/actions/scanningObjects/scanningObjectsActions";
import { STANDARDS_SCOPE } from "../../../store/actions/standards/standardsActionConstants";
import { fetchStandards } from "../../../store/actions/standards/standardsActions";
import { GET_STUFFS_SCOPE } from "../../../store/actions/stuffs/stuffsActionConstants";
import { fetchStuffs } from "../../../store/actions/stuffs/stuffsActions";
import {
  selectIsFindingSettingsChanged,
  selectSingleFinding,
} from "../../../store/selectors/findingsSelectors";
import { selectIsLoadingByActionTypes } from "../../../store/selectors/loadingSelectors";
import {
  ASC_KEY,
  CAPTION_KEY,
  FIRST_NAME_KEY,
  NAME_KEY,
} from "../../../constants/queryConstants";

const NewFindingPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [startedLoading, setStartedLoading] = useState(false);
  const [isCopyingFinding, setIsCopyingFinding] = useState(false);
  const location = useLocation();
  const singleFinding = useSelector(selectSingleFinding);
  const isFindingSettingsChanged = useSelector(selectIsFindingSettingsChanged);

  useEffect(() => {
    if (location?.state?.prefilledData) {
      dispatch(revertOldFinding());
      setIsCopyingFinding(true);
      dispatch(setFindingsChanged(true));
    } else {
      dispatch(clearSingleFinding());
    }
  }, [location]);

  const prefilledData = useMemo(() => {
    if (isCopyingFinding) {
      return singleFinding;
    }
    return undefined;
  }, [isCopyingFinding, singleFinding]);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes([
      SINGLE_FINDING_SCOPE,
      STANDARDS_SCOPE,
      GET_STUFFS_SCOPE,
      SCANNING_OBJECTS_SCOPE,
      GET_CLIENTS_SCOPE,
    ])
  );
  useEffect(() => {
    dispatch(
      fetchStandards({
        page: 1,
        size: 200,
        sorting: {
          column: CAPTION_KEY,
          sortDirection: ASC_KEY,
        },
      })
    );
    dispatch(
      fetchStuffs({
        page: 1,
        size: 200,
        sorting: {
          column: FIRST_NAME_KEY,
          sortDirection: ASC_KEY,
        },
      })
    );
    dispatch(
      fetchClients({
        page: 1,
        size: 200,
        sorting: {
          column: NAME_KEY,
          sortDirection: ASC_KEY,
        },
      })
    );
    setStartedLoading(true);
    return () => {
      dispatch(clearSingleFinding());
      dispatch(setFindingsChanged(false));
    };
  }, []);

  useEffect(() => {
    if (location?.state?.findingType) {
      dispatch(
        fetchScanningObjects({
          page: 1,
          size: 200,
          findingTypeId: location?.state?.findingType?.id,
          sorting: {
            column: NAME_KEY,
            sortDirection: ASC_KEY,
          },
        })
      );
    }

    return () => {
      dispatch(clearSingleScanningObject());
    };
  }, [location?.state]);
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    if (isFindingSettingsChanged)
      window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, [isFindingSettingsChanged]);
  return (
    <>
      <Prompt
        when={isFindingSettingsChanged}
        message={t("common.promptLeave")}
      />
      <NewFindingContent
        isLoading={
          (isLoading || isLoading === undefined || isLoading === null) &&
          startedLoading
        }
        prefilledData={prefilledData}
        title={t("findings.createFindingTitle")}
      />
    </>
  );
};

export default NewFindingPage;
