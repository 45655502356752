import styled from "styled-components";
import { Box } from "@mui/system";
import { Typography, TextField, Button, Checkbox } from "@mui/material";
import LanguagePicker from "../../LanguagePicker/LanguagePicker";

export const LoginContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
  background: white;
  margin-top: 64px;
  border-radius: 12px;
  width: 75%;
  @media (max-width: 600px) {
    width: 100%;
    padding: 0;
    margin-top: 36px;
  }
`;

export const LoginImageContainer = styled(Box)``;

export const LoginHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const LoginTitle = styled(Typography)`
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  margin-top: 24px;
`;

export const Label = styled(Typography)`
  margin-top: 20px;
`;

export const TextFieldInput = styled(TextField)`
  input {
    padding: 10px 12px;
    border-radius: 6px;
  }
`;

export const CheckboxLabel = styled(Typography)`
  display: inline;
`;

export const LoginCheckbox = styled(Checkbox)`
  margin-left: -10px;
`;

export const LoginButton = styled(Button)`
  text-transform: capitalize;
  background-color: #232323;
`;
export const LoginLanguagePicker = styled(LanguagePicker)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
