import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  HeaderContainer,
  HeaderInfo,
  HeaderRightContainer,
  SidebarToggleButton,
} from "./Header.styled";
import HeaderProfileImage from "./HeaderProfileImage/HeaderProfileImage";
import { Redirect } from "react-router-dom";
import { LOGIN_PAGE } from "../../constants/pages";
import { useDispatch, useSelector } from "react-redux";
import { selectJWTToken } from "../../store/selectors/loginSelectors";
import HeaderBreadCrumbs from "./HeaderBreadCrumbs/HeaderBreadCrumbs";
import { selectIsSidebarOpened } from "../../store/selectors/sidebarSelector";
import { toggleSetSidebarOpen } from "../../store/actions/sidebar/sidebarActions";
import HeaderIsOnline from "./HeaderIsOnline/HeaderIsOnline";
import { selectIsOnline } from "../../store/selectors/appSelectors";
import { useCallback } from "react";
import { setIsOnline } from "../../store/actions/app/appActions";
import { selectCurrentLanguage } from "../../store/selectors/translationsSelectors";
import { fetchTranslationsResource } from "../../store/actions/translations/translationsActions";

const Header = () => {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const jwtToken = useSelector(selectJWTToken);
  const sidebar = useSelector(selectIsSidebarOpened);
  const isOnline = useSelector(selectIsOnline);
  const currentLangauge = useSelector(selectCurrentLanguage);

  useEffect(() => {
    if (currentLangauge) {
      dispatch(fetchTranslationsResource({ culture: currentLangauge }));
    }
  }, [currentLangauge]);

  const listener = useCallback(() => {
    dispatch(setIsOnline(false));
  }, [dispatch]);

  useEffect(() => {
    if (isOnline) {
      window.addEventListener("offline", listener);
    }
    return () => {
      window.removeEventListener("offline", listener);
    };
  }, [isOnline]);

  useEffect(() => {
    if (jwtToken) {
      if (jwtToken?.jwtToken?.token) setIsLoggedIn(true);
      else setIsLoggedIn(false);
    } else setIsLoggedIn(false);
  }, [jwtToken]);

  const handleOpenSidebar = () => {
    dispatch(toggleSetSidebarOpen());
  };

  return isLoggedIn !== null ? (
    isLoggedIn ? (
      <HeaderContainer $sidebar={sidebar}>
        <HeaderInfo>
          <SidebarToggleButton onClick={handleOpenSidebar} />
          <HeaderBreadCrumbs />
        </HeaderInfo>
        <HeaderRightContainer>
          {!isOnline && <HeaderIsOnline />}
          <HeaderProfileImage />
        </HeaderRightContainer>
      </HeaderContainer>
    ) : (
      <Redirect to={LOGIN_PAGE} />
    )
  ) : (
    <></>
  );
};

Header.propTypes = {
  icon: PropTypes.any,
  route: PropTypes.any,
};

export default Header;
