import { all, call, put, takeLatest } from "@redux-saga/core/effects";
import {
  attemptCreateFindingType,
  attemptDeleteFindingType,
  attemptEditSingleFindingType,
  attemptFetchFindingTypes,
  attemptFetchSingleFindingType,
} from "../../request/findingTypesRequest";
import {
  CREATE_FINDING_TYPE_FETCH,
  DELETE_FINDING_TYPE_FETCH,
  EDIT_FINDING_TYPE_FETCH,
  GET_FINDING_TYPES_FETCH,
  SINGLE_FINDING_TYPE_FETCH,
} from "../actions/findingTypes/findingTypesActionConstants";
import {
  fetchFindingTypesError,
  fetchFindingTypesSuccess,
  fetchCreateFindingTypeError,
  fetchCreateFindingTypeSuccess,
  fetchDeleteFindingTypeError,
  fetchDeleteFindingTypeSuccess,
  fetchEditFindingTypeError,
  fetchEditFindingTypeSuccess,
  fetchSingleFindingTypeError,
  fetchSingleFindingTypeSuccess,
  setFindingTypes,
  setSingleFindingType,
  setTotalFindingTypes,
} from "../actions/findingTypes/findingTypesActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";

function* fetchFindingTypes({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload, ["name"]);
    const data = yield call(attemptFetchFindingTypes, queryObject);
    yield put(
      setFindingTypes({
        data: data?.data?.findingTypes,
      })
    );
    yield put(setTotalFindingTypes(data?.data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchFindingTypesSuccess());
  } catch (e) {
    console.log("greska", e);
    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError);
    }
    yield put(fetchFindingTypesError());
  }
}

function* fetchCreateFindingType({ payload }) {
  try {
    const data = yield call(attemptCreateFindingType, {
      name: payload?.name,
      caption: payload?.caption,
      subcaption: payload?.subcaption,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.data?.id);
    }
    yield put(fetchCreateFindingTypeSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchCreateFindingTypeError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchCreateFindingTypeError());
    }
  }
}

function* fetchSingleFindingType({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleFindingType, {
      findingTypeId: payload?.findingTypeId,
    });
    yield put(setSingleFindingType(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleFindingTypeSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchSingleFindingTypeError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchSingleFindingTypeError());
    }
  }
}

function* fetchEditSingleFindingType({ payload }) {
  try {
    yield call(attemptEditSingleFindingType, {
      findingTypeId: payload?.id,
      data: {
        id: payload?.id,
        name: payload?.name,
        caption: payload?.caption,
        subcaption: payload?.subcaption,
      },
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEditFindingTypeSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchEditFindingTypeError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchEditFindingTypeError());
    }
  }
}

function* fetchDeleteFindingType({ payload }) {
  try {
    yield call(attemptDeleteFindingType, {
      findingTypeId: payload?.id,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, { isDeleted: true });
    }
    yield put(fetchDeleteFindingTypeSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteFindingTypeError(errorMessage));
  }
}

export default function* findingTypesSaga() {
  yield all([
    takeLatest(GET_FINDING_TYPES_FETCH, fetchFindingTypes),
    takeLatest(CREATE_FINDING_TYPE_FETCH, fetchCreateFindingType),
    takeLatest(EDIT_FINDING_TYPE_FETCH, fetchEditSingleFindingType),
    takeLatest(SINGLE_FINDING_TYPE_FETCH, fetchSingleFindingType),
    takeLatest(DELETE_FINDING_TYPE_FETCH, fetchDeleteFindingType),
  ]);
}
