import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import TableHeader from "./TableHeader/TableHeader";
import { PagingContainer, TableContainer } from "./Table.styled";
import TableContent from "./TableContent/TableContent";
import Paging from "../Paging/Paging";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ASC_KEY, DESC_KEY } from "../../constants/queryConstants";
import { selectIsLoadingByActionTypes } from "../../store/selectors/loadingSelectors";
import CircularLoader from "../Loader/CircularLoader/CircularLoader";
import { tableFetchActions } from "../../store/utils/actionsArray";
import DropdownRecords from "../Dropdown/DropdownRecords/DropdownRecords";
import useIsMobile from "../../hooks/useIsMobile";
import history from "../../store/utils/history";
import { useRef } from "react";
// import { formatDate } from "../../util/helpers/dateHelpers";
// import { useEffect } from "react";
// import { useDispatch } from "react-redux";

const Table = forwardRef((props, ref) => {
  const [page, setPage] = useState(props?.initialPage);
  const [size, setSize] = useState(props?.initialSize || 10);
  const [searchValue, setSearchValue] = useState(props?.initialSearchValue);
  const [sorting, setSorting] = useState({
    column: props?.sortingColumn || props?.tableColumns[0]?.backendProperty,
    sortDirection: props?.sortDesc ? DESC_KEY : ASC_KEY,
  });
  const [forceRefetchValue, forceRefetch] = useState(false);
  const [forceRefetchHelperValue, setForceRefetchHelperValue] = useState(true);
  const dispatch = useDispatch();
  const headerRef = useRef(null);
  const data = useSelector(props?.dataSelector);
  const totalData = useSelector(props?.totalDataSelector);
  const isLoading = useSelector(
    selectIsLoadingByActionTypes(
      props?.loadingActionType ? [props?.loadingActionType] : tableFetchActions
    )
  );
  const { isMobile } = useIsMobile();
  useImperativeHandle(
    ref,
    () => ({
      forceRefetch: () => forceRefetch(true),
      resetPage: () => setPage(1),
      page,
      size,
      setPage,
      searchValue,
      setSearchValue,
    }),
    []
  );

  useEffect(() => {
    return () => {
      if (props?.custom) return;
      dispatch(props?.clearDispatchFunction());
    };
  }, []);

  useEffect(() => {
    setSorting(
      history?.location?.state?.sortPayload || {
        column: props?.sortingColumn || props?.tableColumns[0]?.backendProperty,
        sortDirection: props?.sortDesc ? DESC_KEY : ASC_KEY,
      }
    );
    setSearchValue(history?.location?.state?.searchValue || "");
    headerRef?.current?.changeSearchValue?.(
      history?.location?.state?.searchValue || ""
    );
    setPage(history?.location?.state?.page || props?.initialPage);
    setSize(history?.location?.state?.size || props?.initialSize || 10);
  }, [history?.location?.state]);

  const dataToShow = useMemo(() => {
    const dataToReturn = props?.customData || data || [];
    return props?.mapDataFunction(dataToReturn);
  }, [props?.mapDataFunction, data, props?.customData]);

  useEffect(() => {
    if (forceRefetchValue) {
      setForceRefetchHelperValue((prevValue) => !prevValue);
    }
  }, [forceRefetchValue]);

  useEffect(() => {
    if (props?.custom) return;

    const timer = setTimeout(
      () => {
        dispatch(
          props?.dispatchFunction({
            page,
            size,
            searchValue,
            sorting,
            customPayload: props?.customPayload,
            forceRefetch: forceRefetchValue,
            filters: props?.filters,
          })
        );
        if (forceRefetchValue) forceRefetch(false);
      },
      props?.timeout ? 1000 : 0
    );

    return () => clearTimeout(timer);
  }, [
    searchValue,
    sorting,
    page,
    size,
    props?.customPayload,
    props?.dispatchFunction,
    forceRefetchHelperValue,
  ]);

  const handleChangeSize = (newSize) => {
    const maxPage = Math.ceil(totalData / newSize);
    if (page > maxPage) setPage(maxPage);
    setSize(newSize);
    history?.replace({
      state: {
        ...history?.location?.state,
        size: newSize,
      },
    });
  };

  const handleChangePage = (newPage) => {
    if (newPage < 1) return;
    if (newPage > Math.ceil(totalData / size)) return;
    setPage(newPage);
    history?.replace({
      state: {
        ...history?.location?.state,
        page: newPage,
      },
    });
  };

  const handleChangeSearch = (newSearchValue) => {
    setSearchValue(newSearchValue);
    history?.replace({
      state: {
        ...history?.location?.state,
        searchValue: newSearchValue,
      },
    });
  };

  const handleChangeSorting = (newSorting) => {
    setSorting(newSorting);
    history?.replace?.({
      state: {
        ...history?.location?.state,
        sortPayload: { ...newSorting },
      },
    });
  };

  return (
    <TableContainer>
      {!props?.hideHeader && (
        <TableHeader
          ref={headerRef}
          addButtonHref={props?.addButtonHref}
          searchPlaceholder={props?.searchPlaceholder}
          onChangeSearch={handleChangeSearch}
          hideAddButton={props?.hideAddButton}
          handleClickAddButton={props?.handleClickAddButton}
          showFilter={props?.showFilter}
          filterResetFunc={props?.filterResetFunc}
          applyFiltersFunction={props?.applyFiltersFunction}
          hideSearch={props?.hideSearch}
          filterComponents={props?.filterComponents}
        />
      )}
      {!props?.hidePaging && isMobile && (
        <DropdownRecords
          totalElements={totalData}
          value={size}
          onChange={handleChangeSize}
        />
      )}
      {isLoading ? (
        <CircularLoader isStatic={props?.isStatic} />
      ) : (
        <>
          <TableContent
            onRowClick={props?.onRowClick}
            tableColumns={props?.tableColumns}
            tableData={dataToShow}
            sorting={sorting}
            onChangeSorting={handleChangeSorting}
            redirectFunction={props?.redirectFunction}
            redirectHrefFunction={props?.redirectHrefFunction}
            redirectHref={props?.redirectHref}
            smallerTextForMobile={props?.smallerTextForMobile}
            stickyActions={props?.stickyActions}
            isMobile={isMobile}
          />
          {!props?.hidePaging && (
            <PagingContainer>
              {!isMobile && (
                <DropdownRecords
                  totalElements={totalData}
                  value={size}
                  onChange={handleChangeSize}
                />
              )}
              <Paging
                elementsPerPage={size}
                totalElements={totalData}
                current={page}
                changePage={handleChangePage}
              />
            </PagingContainer>
          )}
        </>
      )}
    </TableContainer>
  );
});

Table.displayName = "Table";

Table.propTypes = {
  children: PropTypes.node,
  tableColumns: PropTypes.array,
  tableData: PropTypes.array,
  onRowClick: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  sortingColumn: PropTypes.string,
  dispatchFunction: PropTypes.func,
  dataSelector: PropTypes.any,
  totalDataSelector: PropTypes.any,
  mapDataFunction: PropTypes.func,
  redirectFunction: PropTypes.func,
  handleClickAddButton: PropTypes.func,
  clearDispatchFunction: PropTypes.func,
  customData: PropTypes.array,
  hideHeader: PropTypes.bool,
  hidePaging: PropTypes.bool,
  sortDesc: PropTypes.bool,
  showFilter: PropTypes.bool,
  filterResetFunc: PropTypes.func,
  applyFiltersFunction: PropTypes.func,
  redirectHrefFunction: PropTypes.func,
  hideSearch: PropTypes.bool,
  custom: PropTypes.bool,
  hideAddButton: PropTypes.bool,
  initialPage: PropTypes.number,
  initialSearchValue: PropTypes.string,
  addButtonHref: PropTypes.string,
  filters: PropTypes.object,
  filterComponents: PropTypes.node,
  initialSize: PropTypes.number,
  loadingActionType: PropTypes.string,
  isStatic: PropTypes.bool,
  customPayload: PropTypes.object,
  timeout: PropTypes.bool,
  smallerTextForMobile: PropTypes.bool,
  redirectHref: PropTypes.string,
  stickyActions: PropTypes.bool,
};
Table.defaultProps = {
  tableColumns: [],
  tableData: [],
  initialPage: 1,
  initialSearchValue: "",
  handleClickAddButton: () => {},
  dataSelector: () => {},
  totalDataSelector: () => {},
  redirectFunction: () => {},
  stickyActions: false,
};

export default Table;
