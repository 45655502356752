import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import {
  InputsContainer,
  ModalContainer,
  TextField,
} from "./RenameScanningSubjectModal.styled";
import { closeModal } from "../../../store/actions/modal/modalActions";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonsContainer,
  CancelButton,
  CloseButton,
  ModalTitle,
  SubmitButton,
} from "../Modal.styled";
import { selectActiveScanningSubjects } from "../../../store/selectors/scanningObjectsSelectors";
import { useTranslation } from "react-i18next";
import {
  fetchFindingSubject,
  setFindingsChanged,
  setNewSubjectName,
} from "../../../store/actions/findings/findingsActions";
import useIsMobile from "../../../hooks/useIsMobile";
import AutocompleteField from "../../Field/AutocompleteField/AutocompleteField";
import { useCallback } from "react";

const RenameScanningSubjectModal = (props) => {
  const [selectedScanningSubject, setSelectedScanningSubject] = useState(null);
  const [specificName, setSpecificName] = useState(
    props?.sectionData?.specificName?.value
  );
  const scanningSubjects = useSelector(selectActiveScanningSubjects);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isMobile } = useIsMobile();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const isSubmitEnabled = useMemo(() => {
    if (
      props?.isEditing &&
      specificName !== props?.sectionData?.specificName?.value
    )
      return true;
    if (selectedScanningSubject) {
      return true;
    }
    return false;
  }, [selectedScanningSubject, specificName]);

  const handleSubmit = useCallback(() => {
    if (isSubmitEnabled) {
      if (!props?.isEditing) {
        dispatch(setFindingsChanged(true));
        dispatch(closeModal());
        dispatch(
          fetchFindingSubject({
            subjectId: selectedScanningSubject?.id,
            specificName: specificName,
          })
        );
      } else {
        dispatch(closeModal());
        dispatch(
          setNewSubjectName({
            subjectId: props?.subjectId,
            specificName: specificName,
          })
        );
      }
    }
  }, [isSubmitEnabled, specificName, selectedScanningSubject]);

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <ModalContainer rank={props?.rank}>
        <CloseButton onClick={handleCloseModal} />
        <ModalTitle>{props?.title}</ModalTitle>
        <InputsContainer isEditing={props?.isEditing}>
          {!props?.isEditing && (
            <AutocompleteField
              options={scanningSubjects}
              label={t("findings.renameScanningSubjectModal.placeholder")}
              onChange={(value) => {
                if (value) setSelectedScanningSubject(value);
                else setSelectedScanningSubject(null);
              }}
              title={t("findings.renameScanningSubjectModal.label")}
              dropdownLabel={["name"]}
              value={selectedScanningSubject}
            />
          )}
          <TextField
            label={t("findings.renameScanningSubjectModal.specificName")}
            value={specificName}
            onChange={(e) => setSpecificName(e.target.value)}
            isMobile={isMobile}
          />
        </InputsContainer>
        <ButtonsContainer>
          <CancelButton onClick={handleCloseModal}>
            {t("common.close")}
          </CancelButton>
          <SubmitButton onClick={handleSubmit} disabled={!isSubmitEnabled}>
            {t("common.add")}
          </SubmitButton>
        </ButtonsContainer>
      </ModalContainer>
    </>
  );
};

RenameScanningSubjectModal.propTypes = {
  title: PropTypes.string,
  rank: PropTypes.number,
  isEditing: PropTypes.bool,
  subjectId: PropTypes.number,
  sectionData: PropTypes.object,
};

export default RenameScanningSubjectModal;
