import {
  all,
  call,
  put,
  takeLatest,
  select,
  takeEvery,
} from "@redux-saga/core/effects";
import { EXISTING_FACTORY_NUMBER } from "../../constants/backendErrorMessages";
import { statusTableConstants } from "../../constants/findingStatusConstants";
import { FINDING_NUMBER_KEY } from "../../constants/queryConstants";
import i18n from "../../i18n";
import {
  attemptChangeFindingState,
  attemptChangeSectionNotes,
  attemptChangeSubjectConclusions,
  attemptCopyFinding,
  attemptFetchCreateFinding,
  attemptFetchEditFinding,
  attemptFetchFindingPreview,
  attemptFetchFindingSubject,
  attemptFetchFindings,
  attemptFetchPrintFinding,
  attemptFetchSingleFinding,
  attemptFillFindingQuestion,
  attemptPatchFinding,
  attemptSendFindingMails,
} from "../../request/findingsRequest";
import {
  FILL_FINDING_QUESTIONS_FETCH,
  FINDINGS_CHANGE_STATE_FETCH,
  FINDINGS_CREATE_FETCH,
  FINDINGS_EDIT_FETCH,
  FINDINGS_FETCH,
  FINDINGS_PRINT_FETCH,
  FINDINGS_SEND_MAILS_FETCH,
  FINDINGS_SECOND_FETCH,
  SINGLE_FINDING_FETCH,
  FINDINGS_PATCH_FETCH,
  COPY_FINDING_FETCH,
  FETCH_FINDING_SUBJECT_FETCH,
  PREVIEW_FINDING_FETCH,
} from "../actions/findings/findingsActionConstants";
import {
  addFindingChange,
  addFindingScanningSubject,
  fetchChangeStateFindingError,
  fetchChangeStateFindingSuccess,
  fetchCopyFindingError,
  fetchCopyFindingSuccess,
  fetchCreateFindingError,
  fetchCreateFindingSuccess,
  fetchEditFindingError,
  fetchEditFindingSuccess,
  fetchFindingPreviewError,
  fetchFindingPreviewSuccess,
  fetchFindingSubjectError,
  fetchFindingSubjectSuccess,
  fetchFindingsError,
  fetchFindingsSuccess,
  fetchPatchFindingError,
  fetchPatchFindingSuccess,
  fetchPrintFindingError,
  fetchPrintFindingSuccess,
  fetchSecondFindingsError,
  fetchSecondFindingsSuccess,
  fetchSingleFindingError,
  fetchSingleFindingSuccess,
  fillFindingQuestionsError,
  fillFindingQuestionsSuccess,
  sendFindingMailsError,
  sendFindingMailsSuccess,
  setFindingPreview,
  setFindings,
  setFindingsChanged,
  setFindingsTotal,
  setSingleFinding,
  setSingleFindingOld,
  setSingleFindingToEdit,
} from "../actions/findings/findingsActions";
import {
  selectSingleFinding,
  selectSingleFindingOld,
  selectSubjectsToAdd,
  selectSubjectsToRemove,
} from "../selectors/findingsSelectors";
import { deleteCRUDInfo } from "../utils/deleteCRUDInfo";
import {
  mapFetchAllFunction,
  mapFetchFindingsFunction,
} from "../utils/mapFetchAllFunctions";
import _ from "lodash";
import {
  addEditFlag,
  mapFindingObjectForBE,
  mapFindingObjectForFE,
  mapFindingSubjectForFE,
} from "../../util/mappers/findingsMappers";
import {
  selectMineCompanyId,
  selectMyProfileId,
} from "../selectors/loginSelectors";
import { selectMyUser } from "../selectors/usersSelectors";

function* fetchFindings({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload, [FINDING_NUMBER_KEY]);
    mapFetchFindingsFunction(queryObject, payload?.customPayload);
    const data = yield call(attemptFetchFindings, queryObject);
    yield put(
      setFindings({
        data: data?.data?.findings,
        isSecondTable: payload?.customPayload?.isSecondTable,
      })
    );
    yield put(setFindingsTotal(data?.data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(
      payload?.customPayload?.isSecondTable
        ? fetchSecondFindingsSuccess()
        : fetchFindingsSuccess()
    );
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(
        payload?.customPayload?.isSecondTable
          ? fetchSecondFindingsError(errorMessage)
          : fetchFindingsError(errorMessage)
      );
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(
        payload?.customPayload?.isSecondTable
          ? fetchSecondFindingsError()
          : fetchFindingsError()
      );
    }
  }
}

function* fetchSingleFindingSaga({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleFinding, payload?.findingId);
    yield put(setSingleFinding(mapFindingObjectForFE(data)));
    yield put(setSingleFindingOld(mapFindingObjectForFE(data)));
    yield put(setSingleFindingToEdit(mapFindingObjectForFE(data)));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleFindingSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleFindingError(errorMessage));
  }
}

function* fetchFindingPreview({ payload }) {
  try {
    const { data } = yield call(attemptFetchFindingPreview, payload?.findingId);
    yield put(setFindingPreview(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchFindingPreviewSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchFindingPreviewError(errorMessage));
  }
}
function* fetchCreateFinding({ payload }) {
  try {
    const singleFinding = yield select(selectSingleFinding);
    const requestObject = {
      findingTypeId: payload?.findingTypeId,
      clientId: payload?.data?.clientId?.value,
      staffId: payload?.data?.staffId?.value,
      description: payload?.data?.description?.value,
      location: payload?.data?.location?.value,
      facility: payload?.data?.facility?.value,
      conclusion: singleFinding?.conclusion?.value || "",
      workspaceImages:
        singleFinding?.workspaceSchemas
          ?.filter?.((singleSchema) => !singleSchema?.removed)
          ?.map?.((singleSchema) => ({
            ...singleSchema?.value,
            imageUrl: singleSchema?.value?.imageUrl?.value,
          })) || [],
      date: new Date(
        new Date(payload?.data?.date?.value)?.getTime() -
          new Date(payload?.data?.date?.value)?.getTimezoneOffset() * 60000
      ).toISOString(),
      closingDate: payload?.data?.closingDate?.value
        ? new Date(
            new Date(payload?.data?.closingDate?.value)?.getTime() -
              new Date(payload?.data?.closingDate?.value)?.getTimezoneOffset() *
                60000
          ).toISOString()
        : null,
      findingStaff:
        singleFinding?.staffs?.map?.((singleStaff) => ({
          staffId: singleStaff?.value?.id,
          staffRole: singleStaff?.value?.staffRole,
        })) || [],
      findingAttendees:
        singleFinding?.findingAttendees?.map?.((singleData) => {
          return {
            firstName: singleData?.value?.firstName?.value,
            lastName: singleData?.value?.lastName?.value,
            title: singleData?.value?.title?.value,
          };
        }) || [],
      // standards: payload?.data?.standards?.map(
      //   (singleStandard) => singleStandard?.id
      // ),
      scanningSubjects: [],
    };
    const { data: singleFindingOld } = yield call(
      attemptFetchCreateFinding,
      requestObject
    );
    yield call(fetchPatchFinding, {
      payload: {
        data: {
          ...payload?.data,
          clientId: {
            edited: false,
            value: payload?.data?.clientId?.value,
          },
          staffId: {
            edited: false,
            value: payload?.data?.staffId?.value,
          },
          description: {
            edited: false,
            value: payload?.data?.description?.value,
          },
          location: {
            edited: false,
            value: payload?.data?.location?.value,
          },
          facility: {
            edited: false,
            value: payload?.data?.facility?.value,
          },
          conclusion: {
            edited: false,
            value: singleFinding?.conclusion?.value || "",
          },
          date: {
            edited: false,
            value: singleFinding?.date?.value || "",
          },
          closingDate: {
            edited: false,
            value: singleFinding?.closingDate?.value || "",
          },
          staffs: singleFinding?.staffs?.map?.((singleStaff) => ({
            edited: false,
            value: singleStaff?.value,
          })),
          findingAttendees: singleFinding?.findingAttendees?.map?.(
            (singleData) => ({
              edited: false,
              value: singleData?.value,
            })
          ),
          workspaceSchemas: singleFinding?.workspaceSchemas?.map?.(
            (singleSchema) => ({
              edited: false,
              value: {
                ...singleSchema?.value,
                imageUrl: {
                  edited: false,
                  value: singleSchema?.value?.imageUrl?.value,
                },
              },
            })
          ),
        },
        stateId: payload?.stateId,
        findingId: singleFindingOld?.id,
      },
    });
    // let itemsToEdit = [];
    // let sectionIdsToEdit = [];
    // let subjectIdsToEdit = [];
    // for (let i = 0; i < singleFindingOld?.findingSubjects?.length; i++) {
    //   if (
    //     singleFinding?.findingSubjects?.[i]?.scanningSubjectId ===
    //       singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId &&
    //     (singleFinding?.findingSubjects?.[i]?.conclusion !==
    //       singleFindingOld?.findingSubjects?.[i]?.conclusion ||
    //       singleFinding?.findingSubjects?.[i]?.orderNumber !==
    //         singleFindingOld?.findingSubjects?.[i]?.orderNumber)
    //   ) {
    //     subjectIdsToEdit?.push({
    //       id: singleFindingOld?.findingSubjects?.[i]?.id,
    //       findingId: singleFindingOld?.id,
    //       orderNumber: singleFinding?.findingSubjects?.[i]?.orderNumber,
    //       scanningSubjectId:
    //         singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId,
    //       conclusion: singleFinding?.findingSubjects?.[i]?.conclusion,
    //     });
    //   }
    //   for (
    //     let j = 0;
    //     j < singleFindingOld?.findingSubjects[i]?.sections?.length;
    //     j++
    //   ) {
    //     if (
    //       singleFinding?.findingSubjects?.[i]?.scanningSubjectId ===
    //         singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId &&
    //       singleFinding?.findingSubjects?.[i]?.sections?.[j]?.note !==
    //         singleFindingOld?.findingSubjects?.[i]?.sections?.[j]?.note
    //     ) {
    //       sectionIdsToEdit?.push({
    //         id: singleFindingOld?.findingSubjects?.[i]?.sections?.[j]?.id,
    //         findingSubjectId:
    //           singleFindingOld?.findingSubjects?.[i]?.sections?.[j]
    //             ?.findingSubjectId,
    //         title: singleFindingOld?.findingSubjects?.[i]?.sections?.[j]?.title,
    //         orderNumber:
    //           singleFindingOld?.findingSubjects?.[i]?.sections?.[j]
    //             ?.orderNumber,
    //         note: singleFinding?.findingSubjects?.[i]?.sections?.[j]?.note,
    //       });
    //     }
    //     for (
    //       let l = 0;
    //       l <
    //       singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.length;
    //       l++
    //     ) {
    //       // let newItem = singleFinding?.findingSubjects
    //       //   ?.find?.(
    //       //     (singleSubject) =>
    //       //       singleSubject?.id === singleFindingOld?.findingSubjects?.[i]?.id
    //       //   )
    //       //   ?.sections?.find?.(
    //       //     (singleSection) =>
    //       //       singleSection?.id ===
    //       //       singleFinding?.findingSubjects[i]?.sections?.[j]?.id
    //       //   )
    //       //   ?.items?.find?.(
    //       //     (singleItem) =>
    //       //       singleItem?.id ===
    //       //       singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]?.id
    //       //   );
    //       // console.log(newItem);
    //       if (
    //         singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
    //           ?.answer !==
    //           singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.[l]
    //             ?.answer ||
    //         singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
    //           ?.isInRange !==
    //           singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.[l]
    //             ?.isInRange ||
    //         !_.isEqual(
    //           singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
    //             ?.images,
    //           singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.[l]
    //             ?.images
    //         )
    //       ) {
    //         itemsToEdit.push({
    //           ...singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.[
    //             l
    //           ],
    //           answer:
    //             singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
    //               ?.answer,
    //           isInRange:
    //             singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
    //               ?.isInRange,
    //           imageUrls: singleFinding?.findingSubjects[i]?.sections?.[
    //             j
    //           ]?.items?.[l]?.images?.map?.(
    //             (singleImage) => singleImage?.imageUrl
    //           ),
    //           images: singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[
    //             l
    //           ]?.images?.map?.((singleImage) => singleImage?.imageUrl),
    //         });
    //       }
    //     }
    //   }
    // }
    // if (itemsToEdit?.length !== 0) {
    //   yield call(attemptFillFindingQuestion, {
    //     findingId: singleFindingOld?.id,
    //     data: itemsToEdit?.map((singleItem) =>
    //       deleteCRUDInfo({
    //         ...singleItem,
    //         optionIds: singleItem?.options?.map(
    //           (singleOption) => singleOption?.id
    //         ),
    //       })
    //     ),
    //   });
    // }
    // if (sectionIdsToEdit?.length !== 0) {
    //   yield call(attemptChangeSectionNotes, {
    //     findingId: singleFindingOld?.id,
    //     body: sectionIdsToEdit,
    //   });
    // }
    // if (subjectIdsToEdit?.length !== 0) {
    //   yield call(attemptChangeSubjectConclusions, {
    //     findingId: singleFindingOld?.id,
    //     body: subjectIdsToEdit,
    //   });
    // }
    // yield call(fetchSingleFindingSaga, {
    //   payload: { findingId: singleFindingOld?.id },
    // });
    // const { data } = yield call(attemptFetchCreateFinding, requestObject);
    yield put(setFindingsChanged(false));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, {
        findingNumber: singleFindingOld?.findingNumber,
        id: singleFindingOld?.id,
        stateId: payload?.stateId,
      });
    }
    yield put(fetchCreateFindingSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    if (errorMessage?.trim() === EXISTING_FACTORY_NUMBER.trim()) {
      yield put(
        fetchCreateFindingError(i18n.t("findings.errors.factoryNumberExisting"))
      );
    } else yield put(fetchCreateFindingError(errorMessage));
  }
}

function* fetchPrintFinding({ payload }) {
  try {
    const { data } = yield call(attemptFetchPrintFinding, payload?.findingId);
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `SN_${payload?.findingName}.pdf`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchPrintFindingSuccess());
  } catch (e) {
    console.dir(e?.message);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchPrintFindingError(errorMessage));
  }
}
function* fetchEditFinding({ payload }) {
  try {
    const companyId = yield select(selectMineCompanyId);
    const scanningSubjectToAddIds = yield select(selectSubjectsToAdd);
    const scanningSubjectToRemoveIds = yield select(selectSubjectsToRemove);
    const dataToSend = {
      id: payload?.findingId,
      companyId,
      clientId: payload?.data?.client,
      description: payload?.data?.description,
      staffId: payload?.data?.staff,
      location: payload?.data?.location,
      staffIds: payload?.data?.staffs?.map((singleStaff) => singleStaff?.id),
      conclusion: payload?.data?.note,
      workspaceImages: payload?.data?.images,
      findingStateId: payload?.findingStateId,
      date: new Date(
        payload?.data?.dateOfChecking?.getTime() -
          payload?.data?.dateOfChecking?.getTimezoneOffset() * 60000
      ).toISOString(),
      closingDate: payload?.data?.dateOfFinding
        ? new Date(
            payload?.data?.dateOfFinding?.getTime() -
              payload?.data?.dateOfFinding?.getTimezoneOffset() * 60000
          ).toISOString()
        : payload?.data?.stateId === statusTableConstants.closed.id
        ? new Date(
            new Date().getTime() - new Date().getTimezoneOffset() * 60000
          ).toISOString()
        : null,
      scanningSubjectToAddIds,
      scanningSubjectToRemoveIds,
      standardIds: payload?.data?.standards?.map(
        (singleStandard) => singleStandard?.id
      ),
    };
    let singleFindingFromBE = yield select(selectSingleFindingOld);
    const queryObject = new URLSearchParams();
    queryObject.set("findingId", payload?.findingId);
    queryObject.set("stateId", payload?.data?.stateId);
    let changedState =
      payload?.data?.stateId === singleFindingFromBE?.findingStateId;

    if (
      payload?.data?.stateId === statusTableConstants.inProgress.id &&
      !changedState
    ) {
      yield call(attemptChangeFindingState, queryObject);
      changedState = true;
    }
    // const singleFinding = yield select(selectSingleFinding);
    const singleFinding = yield select(selectSingleFinding);
    const { data: singleFindingOld } = yield call(attemptFetchEditFinding, {
      findingId: payload?.findingId,
      data: dataToSend,
    });
    let itemsToEdit = [];
    let sectionIdsToEdit = [];
    let subjectIdsToEdit = [];
    for (let i = 0; i < singleFindingOld?.findingSubjects?.length; i++) {
      if (
        singleFinding?.findingSubjects?.[i]?.scanningSubjectId !==
          singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId ||
        singleFinding?.findingSubjects?.[i]?.conclusion !==
          singleFindingOld?.findingSubjects?.[i]?.conclusion ||
        singleFinding?.findingSubjects?.[i]?.orderNumber !==
          singleFindingOld?.findingSubjects?.[i]?.orderNumber
      ) {
        subjectIdsToEdit?.push({
          id: singleFindingOld?.findingSubjects?.[i]?.id,
          findingId: payload?.findingId,
          orderNumber: singleFinding?.findingSubjects?.[i]?.orderNumber,
          scanningSubjectId:
            singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId,
          conclusion: singleFinding?.findingSubjects?.[i]?.conclusion,
        });
      }
      for (
        let j = 0;
        j < singleFindingOld?.findingSubjects[i]?.sections?.length;
        j++
      ) {
        if (
          singleFinding?.findingSubjects?.[i]?.scanningSubjectId ===
            singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId &&
          singleFinding?.findingSubjects?.[i]?.sections?.[j]?.note !==
            singleFindingOld?.findingSubjects?.[i]?.sections?.[j]?.note
        ) {
          sectionIdsToEdit?.push({
            id: singleFindingOld?.findingSubjects?.[i]?.sections?.[j]?.id,
            findingSubjectId:
              singleFindingOld?.findingSubjects?.[i]?.sections?.[j]
                ?.findingSubjectId,
            title: singleFindingOld?.findingSubjects?.[i]?.sections?.[j]?.title,
            orderNumber:
              singleFindingOld?.findingSubjects?.[i]?.sections?.[j]
                ?.orderNumber,
            note: singleFinding?.findingSubjects?.[i]?.sections?.[j]?.note,
          });
        }
        for (
          let l = 0;
          l <
          singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.length;
          l++
        ) {
          if (
            singleFinding?.findingSubjects?.[i]?.scanningSubjectId ===
              singleFindingOld?.findingSubjects?.[i]?.scanningSubjectId &&
            singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
              ?.edited &&
            (singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
              ?.answer !==
              singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.[l]
                ?.answer ||
              singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
                ?.isInRange !==
                singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.[l]
                  ?.isInRange ||
              !_.isEqual(
                singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
                  ?.images,
                singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.[l]
                  ?.images
              ))
          ) {
            itemsToEdit.push({
              ...singleFindingOld?.findingSubjects[i]?.sections?.[j]?.items?.[
                l
              ],
              answer:
                singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
                  ?.answer,
              isInRange:
                singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
                  ?.isInRange,
              imageUrls: singleFinding?.findingSubjects[i]?.sections?.[
                j
              ]?.items?.[l]?.images?.map(
                (singleImage) => singleImage?.imageUrl
              ),
              images: singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[
                l
              ]?.images?.map((singleImage) => singleImage?.imageUrl),
            });
          }
        }
      }
    }

    if (itemsToEdit?.length !== 0) {
      yield call(attemptFillFindingQuestion, {
        findingId: payload?.findingId,
        data: itemsToEdit?.map((singleItem) =>
          deleteCRUDInfo({
            ...singleItem,
            optionIds: singleItem?.options?.map(
              (singleOption) => singleOption?.id
            ),
          })
        ),
      });
      // } else {
      //   const { data } = yield call(
      //     attemptFetchSingleFinding,
      //     payload?.findingId
      //   );
      //   newSingleFinding = data;
    }
    if (sectionIdsToEdit?.length !== 0) {
      yield call(attemptChangeSectionNotes, {
        findingId: payload?.findingId,
        body: sectionIdsToEdit,
      });
    }
    if (subjectIdsToEdit?.length !== 0) {
      yield call(attemptChangeSubjectConclusions, {
        findingId: payload?.findingId,
        body: subjectIdsToEdit,
      });
    }
    if (!changedState) {
      yield call(attemptChangeFindingState, queryObject);
    }

    yield call(fetchSingleFindingSaga, {
      payload: { findingId: payload?.findingId },
    });

    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(setFindingsChanged(false));
    yield put(fetchEditFindingSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditFindingError(errorMessage));
  }
}

// function* changeSectionNotes({ payload }) {
//   try {
//     let sectionsToEdit = [];
//     for (let i = 0; i < payload?.findingObject?.findingSubjects?.length; i++) {
//       let singleSubject = payload?.findingObject?.findingSubjects?.[i];
//       for (let j = 0; j < singleSubject?.sections?.length; j++) {
//         let changedSection = payload?.sectionChanges?.find(
//           (singleSection) =>
//             singleSection?.sectionId === singleSubject?.sections?.[j]?.id
//         );
//         if (changedSection) {
//           sectionsToEdit?.push(
//             deleteCRUDInfo({
//               ...singleSubject?.sections?.[j],
//               note: changedSection?.note,
//             })
//           );
//         }
//       }
//     }
//     console.log(sectionsToEdit);
//   } catch (e) {
//     console.log("greska", e);
//     let errorMessage = e?.response?.data?.message;

//     if (payload?.handleApiResponseError) {
//       yield call(payload?.handleApiResponseError, errorMessage);
//     }
//   }
// }

function* fillFindingQuestions({ payload }) {
  try {
    const singleFinding = yield select(selectSingleFinding);
    let editedItems = [];
    for (let i = 0; i < payload?.items?.length; i++) {
      const oldItem = singleFinding?.findingSubjects
        ?.find((singleSubject) => singleSubject?.id === payload?.subjectId)
        ?.sections?.find(
          (singleSection) => singleSection?.id === payload?.sectionId
        )?.items?.[i];

      if (
        oldItem?.answer !== payload?.items?.[i]?.answer ||
        oldItem?.isInRange !== payload?.items?.[i]?.isInRange
      ) {
        editedItems?.push(payload?.items?.[i]);
      }
    }
    if (editedItems?.length !== 0) {
      yield call(attemptFillFindingQuestion, {
        findingId: payload?.findingId,
        data: editedItems?.map((singleItem) =>
          deleteCRUDInfo({
            ...singleItem,
            optionIds: singleItem?.options?.map(
              (singleOption) => singleOption?.id
            ),
          })
        ),
      });
      yield call(fetchSingleFindingSaga, {
        payload: { findingId: payload?.findingId },
      });
    }
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fillFindingQuestionsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fillFindingQuestionsError(errorMessage));
  }
}

function* fetchChangeStateFinding({ payload }) {
  try {
    const queryObject = new URLSearchParams();
    queryObject.set("findingId", payload?.findingId);
    queryObject.set("stateId", payload?.stateId);
    yield call(attemptChangeFindingState, queryObject);
    // yield call(fetchSingleFindingSaga, {
    //   payload: { findingId: payload?.findingId },
    // });
    //const user = yield select(selectMyUser);
    // yield put(
    //   addFindingStateChange({
    //     firstName: user?.firstName,
    //     lastName: user?.lastName,
    //     time: new Date().toISOString(),
    //     newStatusId: payload?.stateId,
    //   })
    // );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchChangeStateFindingSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchChangeStateFindingError(errorMessage));
  }
}

function* sendFindingMails({ payload }) {
  try {
    const queryObject = new URLSearchParams();
    queryObject.set("findingId", payload?.findingId);
    queryObject.set("recipientList", payload?.mails);
    yield call(attemptSendFindingMails, queryObject);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(sendFindingMailsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(sendFindingMailsError(errorMessage));
  }
}

function* fetchFindingSubject({ payload }) {
  try {
    const { data } = yield call(attemptFetchFindingSubject, payload?.subjectId);
    const singleFinding = yield select(selectSingleFinding);
    yield put(
      addFindingScanningSubject(
        mapFindingSubjectForFE({
          ...data,
          id: Math.random(),
          specificName: payload?.specificName
            ? payload?.specificName
            : data?.specificName,
          scanningSubject: (() => {
            let subjectDetails = {
              ...data?.scanningSubject,
            };
            delete subjectDetails.createdByUser;
            delete subjectDetails.updatedByUser;
            delete subjectDetails.type;
            return subjectDetails;
          })(),
          added: true,
          orderNumber: addEditFlag(
            singleFinding?.findingSubjects?.filter?.(
              (singleSubject) => !singleSubject?.removed
            )?.length
          ),
          sections: data?.sections?.map((singleSection) => ({
            ...singleSection,
            id: Math.random(),
            items: singleSection?.items?.map?.((singleItem) => ({
              ...singleItem,
              answer:
                singleItem?.itemTypeId !== 3
                  ? singleItem?.answer
                  : singleItem?.options?.find((el) => el?.isSelected === true)
                      ?.answer || "",
              id: Math.random(),
            })),
          })),
        })
      )
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchFindingSubjectSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchFindingSubjectError(errorMessage));
  }
}

function* fetchPatchFinding({ payload }) {
  try {
    const userId = yield select(selectMyProfileId);
    const singleFindingOld = yield select(selectSingleFindingOld);
    const myUser = yield select(selectMyUser);
    let singleFinding = yield select(selectSingleFinding);
    let shouldAddChange = false;
    const { data: oldData } = yield call(
      attemptFetchSingleFinding,
      singleFindingOld.id || payload?.findingId
    );
    let requestObject = mapFindingObjectForBE(
      { ...singleFinding, ...payload?.data },
      {
        userId,
        newerFinding: oldData,
      }
    );
    let requestResult;
    // let shouldRevertState = false;
    if (requestObject?.length !== 0) {
      shouldAddChange = true;
      if (
        singleFindingOld?.findingStateId !== statusTableConstants.inProgress.id
      ) {
        // shouldRevertState = true;
        yield call(fetchChangeStateFinding, {
          payload: {
            findingId: singleFindingOld?.id || payload?.findingId,
            stateId: statusTableConstants.inProgress.id,
          },
        });
      }
      const { data } = yield call(attemptPatchFinding, {
        findingId: singleFindingOld.id || payload?.findingId,
        data: requestObject,
      });
      requestResult = data;
    }
    if (
      payload?.stateId !== statusTableConstants?.inProgress?.id ||
      (requestObject?.length === 0 &&
        payload?.stateId !== singleFindingOld?.stateId)
      // shouldRevertState ||
      // singleFindingOld?.findingStateId === 0 ||
      // (singleFindingOld?.findingStateId != null && payload?.stateId !== 1)
    ) {
      shouldAddChange = true;
      yield call(fetchChangeStateFinding, {
        payload: {
          findingId: singleFindingOld?.id || payload?.findingId,
          stateId: payload?.stateId,
        },
      });
      singleFinding = yield select(selectSingleFinding);
      yield call(fetchSingleFindingSaga, {
        payload: {
          findingId: singleFindingOld?.id || payload?.findingId,
        },
      });
    } else {
      const newFinding = {
        // ...singleFinding,
        ...requestResult,
        findingStateId: payload?.stateId,
      };
      yield put(
        setSingleFinding(
          requestResult
            ? mapFindingObjectForFE(newFinding)
            : { ...singleFinding, findingStateId: payload?.stateId }
        )
      );
      yield put(
        setSingleFindingOld(
          requestResult
            ? mapFindingObjectForFE(newFinding)
            : { ...singleFinding, findingStateId: payload?.stateId }
        )
      );
      if (shouldAddChange) {
        yield put(
          addFindingChange({
            firstName: myUser?.firstName,
            lastName: myUser?.lastName,
          })
        );
      }
    }
    yield put(setFindingsChanged(false));

    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, {
        stateId: payload?.stateId,
      });
    }
    yield put(fetchPatchFindingSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchPatchFindingError(errorMessage));
  }
}
function* copyFinding({ payload }) {
  try {
    const data = yield call(attemptCopyFinding, {
      findingId: payload?.findingId,
      data: {
        findingId: payload?.findingId,
        date: payload?.date,
      },
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data);
    }
    yield put(fetchCopyFindingSuccess());
  } catch (e) {
    console.log(e);
    let errorMessage = e?.response?.data?.message;
    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCopyFindingError(errorMessage));
  }
}

export default function* findingsSaga() {
  yield all([
    takeEvery([FINDINGS_FETCH, FINDINGS_SECOND_FETCH], fetchFindings),
    takeLatest(FILL_FINDING_QUESTIONS_FETCH, fillFindingQuestions),
    takeLatest(SINGLE_FINDING_FETCH, fetchSingleFindingSaga),
    takeLatest(FINDINGS_CREATE_FETCH, fetchCreateFinding),
    takeLatest(FINDINGS_EDIT_FETCH, fetchEditFinding),
    takeLatest(FINDINGS_PRINT_FETCH, fetchPrintFinding),
    takeLatest(FINDINGS_CHANGE_STATE_FETCH, fetchChangeStateFinding),
    takeLatest(FINDINGS_SEND_MAILS_FETCH, sendFindingMails),
    takeLatest(FINDINGS_PATCH_FETCH, fetchPatchFinding),
    takeLatest(COPY_FINDING_FETCH, copyFinding),
    takeLatest(FETCH_FINDING_SUBJECT_FETCH, fetchFindingSubject),
    takeLatest(PREVIEW_FINDING_FETCH, fetchFindingPreview),
  ]);
}
