import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteDocumentTypeIconContainer,
  ActionRendererEditDocumentTypeIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon.js";
import {
  closeLoadingModal,
  closeModal,
  toggleDocumentTypeModal,
  toggleSetDeleteModal,
  toggleSetLoadingModal,
} from "../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../i18n";
import {
  fetchDeleteDocumentType,
  fetchEditDocumentType,
} from "../../../../../store/actions/documentTypes/documentTypesActions";
import Tooltip from "../../../../Tooltip/Tooltip";
import WithPermissions from "../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../constants/rolesConstants";

const ActionRenderer = (props) => {
  const handleApiResponseSuccess = (name) => {
    props?.dispatch(closeModal());
    props?.value?.refetchTable(name);
  };

  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleEditDocumentType = () => {
    props?.dispatch(
      toggleDocumentTypeModal({
        title: i18n.t("documentTypes.modal.editDocumentType"),
        prefilledData: {
          caption: props?.value?.caption,
          description: props?.value?.description,
        },
        isEditing: true,
        handleSubmit: (values) =>
          props?.dispatch(
            fetchEditDocumentType({
              ...values,
              id: props?.value?.id,
              handleApiResponseSuccess: (values) =>
                props?.value?.handleApiResponseSuccess(values, true),
            })
          ),
        rank: 1,
        id: props?.value?.id,
      })
    );
  };

  const handleDeleteDocumentType = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteDocumentType({
        id: props?.value?.id,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccess({
            data: props?.value,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("documentTypes.modal.delete.title"),
        description: {
          first: i18n.t("documentTypes.modal.delete.description.1"),
          second: i18n.t("documentTypes.modal.delete.description.2"),
        },
        onSubmit: handleDeleteDocumentType,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("documentTypes.tooltip.changeDocumentType")}>
        <ActionRendererEditDocumentTypeIconContainer
          onClick={handleEditDocumentType}
        >
          <EditIcon />
        </ActionRendererEditDocumentTypeIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("documentTypes.tooltip.deleteDocumentType")}>
          <ActionRendererDeleteDocumentTypeIconContainer onClick={handleDelete}>
            <DeleteIcon />
          </ActionRendererDeleteDocumentTypeIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
  handleApiResponseSuccess: PropTypes.func,
};

export default ActionRenderer;
