import React from "react";
import PropTypes from "prop-types";
import {
  TableHeaderContainer,
  TableHeaderLeftContainer,
} from "./TableHeader.styled";
import FilterButton from "../../Buttons/FilterButton/FilterButton";
import SearchInput from "../../Field/SearchInput/SearchInput";
import AddButton from "../../Buttons/AddButton/AddButton";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useRef } from "react";

const TableHeader = forwardRef((props, ref) => {
  const searchRef = useRef(null);

  useImperativeHandle(ref, () => ({
    changeSearchValue: searchRef?.current?.changeSearchValue,
  }));

  return (
    <TableHeaderContainer>
      <TableHeaderLeftContainer>
        {props?.showFilter && (
          <FilterButton
            applyFiltersFunction={props?.applyFiltersFunction}
            filterComponents={props?.filterComponents}
            onReset={props?.filterResetFunc}
          />
        )}
        {!props?.hideSearch && (
          <SearchInput
            ref={searchRef}
            onChange={props?.onChangeSearch}
            placeholder={props?.searchPlaceholder}
          />
        )}
      </TableHeaderLeftContainer>
      {!props?.hideAddButton && (
        <AddButton
          to={props?.addButtonHref}
          onClick={props?.handleClickAddButton}
        />
      )}
    </TableHeaderContainer>
  );
});

TableHeader.displayName = "TableHeader";

TableHeader.propTypes = {
  children: PropTypes.node,
  onChangeSearch: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  addButtonHref: PropTypes.string,
  handleClickAddButton: PropTypes.func,
  hideAddButton: PropTypes.bool,
  showFilter: PropTypes.bool,
  filterResetFunc: PropTypes.func,
  applyFiltersFunction: PropTypes.func,
  hideSearch: PropTypes.bool,
  filterComponents: PropTypes.node,
};
TableHeader.defaultProps = {
  handleClickAddButton: () => {},
};

export default TableHeader;
