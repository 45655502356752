import { Grid } from "@mui/material";
import styled from "styled-components";

export const LoginLayoutContainer = styled(Grid)`
  display: flex;
  min-height: 100%;
  min-width: 100%;
  align-content: center;
  margin-top: -25px;
  @media (max-width: 600px) {
    align-content: flex-start;
  }
`;
export const LeftImage = styled(Grid)`
  width: 100%;
`;

export const Content = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
