// import { mapForEditing } from "../../../util/mappers/findingsMappers";
import {
  SINGLE_FINDING_SET,
  FINDINGS_CLEAR,
  FINDINGS_SET,
  FINDINGS_SET_TOTAL,
  FINDINGS_SET_ERROR_MESSAGE,
  FINDINGS_CREATE_ERROR,
  FINDINGS_ERROR,
  FINDINGS_EDIT_ERROR,
  SINGLE_FINDING_ERROR,
  FINDINGS_ITEM_SET,
  SINGLE_FINDING_SET_OLD,
  SINGLE_FINDING_ADD_SCANNING,
  SINGLE_FINDING_REMOVE_SCANNING,
  SINGLE_FINDING_SET_SCANNING,
  SINGLE_FINDING_SET_SECTION_NOTE,
  SINGLE_FINDING_SET_SUBJECT_NOTE,
  SINGLE_FINDING_CLEAR,
  FINDINGS_CHANGED_SET,
  SINGLE_FINDING_SUBJECT_MOVE_ABOVE,
  SINGLE_FINDING_SUBJECT_MOVE_BELOW,
  SINGLE_FINDING_SUBJECT_ORDER_NUMBER_REPLACE,
  SINGLE_FINDING_REVERT_OLD,
  SINGLE_FINDING_TO_EDIT_SET,
  SINGLE_FINDING_SCHEMAS_SET,
  SINGLE_FINDING_STAFFS_SET,
  SINGLE_FINDING_SETTINGS_SET,
  SINGLE_FINDING_NOTE_SET,
  SINGLE_FINDING_OLD_CLEAR,
  SINGLE_FINDING_ADD_STATE_CHANGE,
  SINGLE_FINDING_ADD_CHANGE,
  SET_CONCLUSION,
  SCANNING_SUBJECT_CHANGE_NAME,
  SINGLE_FINDING_COLUMN_SET,
  SINGLE_FINDING_COLUMN_ANSWER_SET,
  SINGLE_FINDING_COLUMN_ANSWER_ADD,
  SINGLE_FINDING_COLUMN_ANSWER_REMOVE,
  SINGLE_FINDING_SINGLE_STUFF_EDIT,
  FINDING_TYPES_SET,
  SINGLE_FINDING_ATTENDEES_SET,
  REMOVE_ATTENDED_PERSON,
  FINDING_TYPES_CLEAR,
  SINGLE_FINDING_COLUMN_FROM_EDITED_SET,
  SINGLE_FINDING_COLUMN_ANSWER_COPY,
  SINGLE_FINDING_COLUMN_SET_FROM_MODAL,
  PREVIEW_FINDING_SET,
} from "../../actions/findings/findingsActionConstants";
import createReducer from "../../utils/createReducer";

const initialState = {
  findings: {
    loaded: false,
    data: [],
  },
  findingsSecond: {
    loaded: false,
    data: [],
  },
  totalFindings: 0,
  singleFinding: {
    findingSubjects: [],
    staffs: [],
    findingAttendees: [],
    workspaceSchemas: [],
    findingStateChanges: [],
  },
  singleFindingOld: {},
  scanningSubjectsToRemove: [],
  scanningSubjectsToAdd: [],
  errorMessage: "",
  findingsChanged: false,
  isEditing: false,
  scanningSubjectsSelectedConclusions: [],
  previewFinding: "",
  singleFindingToEdit: {
    workspaceSchemas: [],
    staffs: [],
  },
  findingTypes: [],
};

export default createReducer(
  {
    [FINDINGS_SET]: setFindings,
    [FINDINGS_SET_TOTAL]: setFindingsTotal,
    [FINDINGS_CLEAR]: clearFindings,
    [SINGLE_FINDING_SET]: setSingleFinding,
    [SINGLE_FINDING_TO_EDIT_SET]: setSingleFindingToEdit,
    [PREVIEW_FINDING_SET]: setFindingPreview,
    [FINDINGS_SET_ERROR_MESSAGE]: setErrorMessage,
    [FINDINGS_CREATE_ERROR]: setErrorMessage,
    [FINDINGS_ERROR]: setErrorMessage,
    [FINDINGS_EDIT_ERROR]: setErrorMessage,
    [SINGLE_FINDING_ERROR]: setErrorMessage,
    [FINDINGS_ITEM_SET]: setFindingItem,
    [SINGLE_FINDING_SET_OLD]: setSingleFindingOld,
    [SINGLE_FINDING_ADD_SCANNING]: addFindingScanningSubject,
    [SINGLE_FINDING_REMOVE_SCANNING]: removeFindingScanningSubject,
    [SINGLE_FINDING_SET_SCANNING]: setFindingScanningSubject,
    [SINGLE_FINDING_COLUMN_SET]: setFindingColumn,
    [SINGLE_FINDING_COLUMN_SET_FROM_MODAL]: setFindingColumnFromModal,
    [SINGLE_FINDING_COLUMN_ANSWER_SET]: setFindingColumnAnswer,
    [SINGLE_FINDING_COLUMN_FROM_EDITED_SET]: setFindingColumnsFromEdited,
    [SINGLE_FINDING_COLUMN_ANSWER_ADD]: addFindingColumnAnswer,
    [SINGLE_FINDING_COLUMN_ANSWER_COPY]: copyFindingColumnAnswer,
    [SINGLE_FINDING_COLUMN_ANSWER_REMOVE]: removeFindingColumnAnswer,
    [SINGLE_FINDING_SET_SECTION_NOTE]: setFindingSectionNote,
    [SINGLE_FINDING_SET_SUBJECT_NOTE]: setFindingSubjectNote,
    [SINGLE_FINDING_CLEAR]: clearSingleFinding,
    [SINGLE_FINDING_OLD_CLEAR]: clearSingleFindingOld,
    [FINDINGS_CHANGED_SET]: setFindingChanged,
    [SINGLE_FINDING_SUBJECT_MOVE_ABOVE]: moveFindingSubjectAbove,
    [SINGLE_FINDING_SUBJECT_MOVE_BELOW]: moveFindingSubjectBelow,
    [SINGLE_FINDING_SUBJECT_ORDER_NUMBER_REPLACE]:
      replaceFindingSubjectOrderNumber,
    [SINGLE_FINDING_REVERT_OLD]: revertOldFinding,
    //new
    [SINGLE_FINDING_SCHEMAS_SET]: setWorkspaceSchemas,
    [SINGLE_FINDING_STAFFS_SET]: setStaffs,
    [SINGLE_FINDING_SINGLE_STUFF_EDIT]: editFindingSingleStuff,
    [SINGLE_FINDING_SETTINGS_SET]: setSettings,
    [SINGLE_FINDING_NOTE_SET]: setNote,
    [SINGLE_FINDING_ADD_STATE_CHANGE]: addFindingStateChange,
    [SINGLE_FINDING_ADD_CHANGE]: addFindingChange,
    [SET_CONCLUSION]: setConclusion,
    [SCANNING_SUBJECT_CHANGE_NAME]: setNewSubjectName,
    [FINDING_TYPES_SET]: setFindingTypes,
    [FINDING_TYPES_CLEAR]: clearFindingTypes,
    [SINGLE_FINDING_ATTENDEES_SET]: setAttendees,
    [REMOVE_ATTENDED_PERSON]: removeAttendedPerson,
  },
  initialState
);

function addFindingChange(state, { payload }) {
  return {
    ...state,
    singleFinding: {
      ...state.singleFinding,
      updatedAtUtc: new Date().toISOString(),
      updatedByUser: {
        ...state.singleFinding.updatedByUser,
        firstName: payload?.firstName,
        lastName: payload?.lastName,
      },
    },
  };
}

function clearSingleFinding(state) {
  return {
    ...state,
    singleFinding: initialState.singleFinding,
    singleFindingToEdit: initialState.singleFindingToEdit,
    isEditing: false,
  };
}
function clearSingleFindingOld(state) {
  return {
    ...state,
    singleFinding: initialState.singleFinding,
    singleFindingOld: initialState.singleFindingToEdit,
    isEditing: false,
  };
}
function setSingleFinding(state, { payload }) {
  const conclusionsArray = [];
  payload?.findingSubjects?.map((subject) =>
    conclusionsArray.push({ id: subject.id, checkedConclusion: null })
  );
  return {
    ...state,
    singleFinding: { ...payload },
    scanningSubjectsToAdd: [],
    scanningSubjectsToRemove: [],
    errorMessage: "",
    scanningSubjectsSelectedConclusions: conclusionsArray,
  };
}
function setSingleFindingOld(state, { payload }) {
  return {
    ...state,
    singleFindingOld: payload,
    errorMessage: "",
  };
}
function setFindingPreview(state, { payload }) {
  return {
    ...state,
    previewFinding: payload,
  };
}
function setFindings(state, { payload }) {
  if (payload?.isSecondTable)
    return {
      ...state,
      findingsSecond: payload,
    };
  return {
    ...state,
    findings: payload,
  };
}

function setFindingSectionNote(state, { payload }) {
  const subjectIndex = state?.singleFinding?.findingSubjects?.findIndex?.(
    (singleSubject) => singleSubject?.id === payload?.subjectId
  );
  const sectionIndex = state?.singleFinding?.findingSubjects?.[
    subjectIndex
  ]?.sections?.findIndex?.(
    (singleSection) => singleSection?.id === payload?.sectionId
  );
  return {
    ...state,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: [
        ...state?.singleFinding?.findingSubjects?.slice(0, subjectIndex),
        {
          ...state?.singleFinding?.findingSubjects?.[subjectIndex],
          sections: [
            ...state?.singleFinding?.findingSubjects?.[
              subjectIndex
            ]?.sections?.slice(0, sectionIndex),
            {
              ...state?.singleFinding?.findingSubjects?.[subjectIndex]
                ?.sections?.[sectionIndex],
              note: payload?.note,
            },
            ...state?.singleFinding?.findingSubjects?.[
              subjectIndex
            ]?.sections?.slice(sectionIndex + 1),
          ],
        },
        ...state?.singleFinding?.findingSubjects?.slice(subjectIndex + 1),
      ],
    },
  };
}

function setFindingSubjectNote(state, { payload }) {
  const subjectIndex = state?.singleFinding?.findingSubjects?.findIndex?.(
    (singleSubject) => singleSubject?.id === payload?.subjectId
  );
  return {
    ...state,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: [
        ...state?.singleFinding?.findingSubjects?.slice(0, subjectIndex),
        {
          ...state?.singleFinding?.findingSubjects?.[subjectIndex],
          conclusion: payload?.note,
        },
        ...state?.singleFinding?.findingSubjects?.slice(subjectIndex + 1),
      ],
    },
  };
}

function setFindingColumnFromModal(state, { payload }) {
  let findingSubjectIndex;
  let sectionIndex;
  let itemIndex;
  let columnIndex;
  let found = false;
  for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
    findingSubjectIndex = i;

    for (
      let j = 0;
      j < state?.singleFinding?.findingSubjects[i]?.sections?.length;
      j++
    ) {
      sectionIndex = j;
      for (
        let l = 0;
        l <
        state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.length;
        l++
      ) {
        itemIndex = l;
        for (
          let k = 0;
          k <
          state?.singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[l]
            ?.columns?.value?.length;
          k++
        ) {
          columnIndex = k;
          if (
            state?.singleFinding?.findingSubjects?.[i]?.sections?.[j]?.items?.[
              l
            ]?.columns?.value?.[k]?.columnId === payload?.column?.columnId
          ) {
            found = true;
            break;
          }
        }
        if (found) break;
      }
      if (found) break;
    }
    if (found) break;
  }
  let oldColumn =
    state?.singleFinding?.findingSubjects?.[findingSubjectIndex]?.sections?.[
      sectionIndex
    ]?.items?.[itemIndex]?.columns?.value?.[columnIndex];
  let stateToReturn = {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: [
        ...state?.singleFinding?.findingSubjects?.slice?.(
          0,
          findingSubjectIndex
        ),
        {
          ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex],
          sections: [
            ...state?.singleFinding?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(0, sectionIndex),
            {
              ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
                ?.sections?.[sectionIndex],
              items: [
                ...state?.singleFinding?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(0, itemIndex),
                {
                  ...state?.singleFinding?.findingSubjects?.[
                    findingSubjectIndex
                  ]?.sections?.[sectionIndex]?.items?.[itemIndex],
                  columns: {
                    edited:
                      payload?.column?.isEdited ||
                      state?.singleFinding?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns
                        ?.edited,
                    value: [
                      ...state?.singleFinding?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[
                        itemIndex
                      ]?.columns?.value?.slice(0, columnIndex),
                      {
                        ...oldColumn,
                        columnTitle: payload?.column?.columnTitle,
                        columnSubtitle: payload?.column?.columnSubtitle,
                      },
                      ...state?.singleFinding?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[
                        itemIndex
                      ]?.columns?.value?.slice(columnIndex + 1),
                    ],
                  },
                  // edited: true,
                },
                ...state?.singleFinding?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(itemIndex + 1),
              ],
            },
            ...state?.singleFinding?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(sectionIndex + 1),
          ],
        },
        ...state?.singleFinding?.findingSubjects?.slice(
          findingSubjectIndex + 1
        ),
      ],
    },
  };
  return {
    ...stateToReturn,
    singleFindingToEdit: { ...stateToReturn.singleFinding },
  };
}

function setFindingColumn(state, { payload }) {
  if (state?.singleFinding?.findingSubjects?.length == 0) return { ...state };
  let findingSubjectIndex;
  let sectionIndex;
  let itemIndex;
  let columnIndex;
  let found = false;
  for (
    let i = 0;
    i < state?.singleFindingToEdit?.findingSubjects?.length;
    i++
  ) {
    findingSubjectIndex = i;
    for (
      let j = 0;
      j < state?.singleFindingToEdit?.findingSubjects[i]?.sections?.length;
      j++
    ) {
      sectionIndex = j;
      for (
        let l = 0;
        l <
        state?.singleFindingToEdit?.findingSubjects[i]?.sections?.[j]?.items
          ?.length;
        l++
      ) {
        itemIndex = l;
        for (
          let k = 0;
          k <
          state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.[j]
            ?.items?.[l]?.columns?.value?.length;
          k++
        ) {
          columnIndex = k;
          if (
            state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.[j]
              ?.items?.[l]?.columns?.value?.[k]?.columnId ===
            payload?.column?.columnId
          ) {
            found = true;
            break;
          }
        }
        if (found) break;
      }
      if (found) break;
    }
    if (found) break;
  }
  let oldColumn =
    state?.singleFindingToEdit?.findingSubjects?.[findingSubjectIndex]
      ?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.value?.[
      columnIndex
    ];
  let columnsToChange = [
    ...state?.singleFindingToEdit?.findingSubjects?.[
      findingSubjectIndex
    ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.value
      ?.filter?.(
        (singleColumn) =>
          payload?.column?.columnTitle != null &&
          singleColumn?.columnTitle === oldColumn?.columnTitle
      )
      ?.map?.((singleColumn) => singleColumn?.id),
    payload?.column?.columnId,
  ];
  let newColumns = [
    ...state?.singleFindingToEdit?.findingSubjects?.[
      findingSubjectIndex
    ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.value?.map?.(
      (singleColumn) => ({
        ...singleColumn,
        columnTitle:
          columnsToChange?.includes(singleColumn?.id) &&
          payload?.column?.columnTitle != null
            ? payload?.column?.columnTitle
            : singleColumn?.columnTitle,

        columnSubtitle:
          columnsToChange?.includes(singleColumn?.id) &&
          payload?.column?.columnSubtitle != null
            ? payload?.column?.columnSubtitle
            : singleColumn?.columnSubtitle,
      })
    ),
  ];
  let stateToReturn = {
    ...state,
    findingsChanged: true,
    singleFindingToEdit: {
      ...state?.singleFindingToEdit,
      findingSubjects: [
        ...state?.singleFindingToEdit?.findingSubjects?.slice?.(
          0,
          findingSubjectIndex
        ),
        {
          ...state?.singleFindingToEdit?.findingSubjects?.[findingSubjectIndex],
          sections: [
            ...state?.singleFindingToEdit?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(0, sectionIndex),
            {
              ...state?.singleFindingToEdit?.findingSubjects?.[
                findingSubjectIndex
              ]?.sections?.[sectionIndex],
              items: [
                ...state?.singleFindingToEdit?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(0, itemIndex),
                {
                  ...state?.singleFindingToEdit?.findingSubjects?.[
                    findingSubjectIndex
                  ]?.sections?.[sectionIndex]?.items?.[itemIndex],
                  columns: {
                    edited:
                      payload?.column?.isEdited ||
                      state?.singleFindingToEdit?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns
                        ?.edited,
                    value: newColumns,
                  },
                  // edited: true,
                },
                ...state?.singleFindingToEdit?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(itemIndex + 1),
              ],
            },
            ...state?.singleFindingToEdit?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(sectionIndex + 1),
          ],
        },
        ...state?.singleFindingToEdit?.findingSubjects?.slice(
          findingSubjectIndex + 1
        ),
      ],
    },
  };
  return {
    ...stateToReturn,
    singleFinding: { ...stateToReturn?.singleFindingToEdit },
  };
}
function setFindingColumnAnswer(state, { payload }) {
  if (state?.singleFinding?.findingSubjects?.length == 0) return { ...state };
  let findingSubjectIndex;
  let sectionIndex;
  let itemIndex;
  let columnIndex;
  let found = false;
  let answerInColumnsIndex = -1;
  for (
    let i = 0;
    i < state?.singleFindingToEdit?.findingSubjects?.length;
    i++
  ) {
    findingSubjectIndex = i;

    for (
      let j = 0;
      j < state?.singleFindingToEdit?.findingSubjects[i]?.sections?.length;
      j++
    ) {
      sectionIndex = j;
      for (
        let l = 0;
        l <
        state?.singleFindingToEdit?.findingSubjects[i]?.sections?.[j]?.items
          ?.length;
        l++
      ) {
        itemIndex = l;
        for (
          let k = 0;
          k <
          state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.[j]
            ?.items?.[l]?.columnAnswers?.[payload?.rowIndex]?.length;
          k++
        ) {
          columnIndex = k;
          if (
            state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.[j]
              ?.items?.[l]?.columnAnswers?.[payload?.rowIndex]?.[k]
              ?.columnId === payload?.columnId ||
            state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.[j]
              ?.items?.[l]?.columnAnswers?.[payload?.rowIndex]?.[k]?.id ===
              payload?.columnId
          ) {
            answerInColumnsIndex =
              state?.singleFindingToEdit?.findingSubjects?.[i]?.sections?.[
                j
              ]?.items?.[l]?.columns?.value?.[k]?.answers?.findIndex(
                (singleAnswer) => singleAnswer?.id === payload?.answerId
              );
            found = true;
            break;
          }
        }
        if (found) break;
      }
      if (found) break;
    }
    if (found) break;
  }

  let hasAnswerInColumns = answerInColumnsIndex !== -1;

  let oldAnswer = hasAnswerInColumns
    ? state?.singleFindingToEdit?.findingSubjects?.[findingSubjectIndex]
        ?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.value?.[
        columnIndex
      ]?.answers?.[answerInColumnsIndex]
    : {};

  let newColumnAnswer = {
    id: oldAnswer?.id,
    columnId: oldAnswer?.columnId || oldAnswer?.id,
    answer: payload?.answer,
    rowIndex: payload?.rowIndex,
    createdByUserId: payload?.userId,
    createdAtUtc: new Date(
      new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
    ).toISOString(),
    updatedByUserId: payload?.userId,
    updatedAtUtc: new Date(
      new Date()?.getTime() - new Date()?.getTimezoneOffset() * 60000
    ).toISOString(),
  };
  return {
    ...state,
    findingsChanged: true,
    singleFindingToEdit: {
      ...state?.singleFindingToEdit,
      findingSubjects: [
        ...state?.singleFindingToEdit?.findingSubjects?.slice?.(
          0,
          findingSubjectIndex
        ),
        {
          ...state?.singleFindingToEdit?.findingSubjects?.[findingSubjectIndex],
          sections: [
            ...state?.singleFindingToEdit?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(0, sectionIndex),
            {
              ...state?.singleFindingToEdit?.findingSubjects?.[
                findingSubjectIndex
              ]?.sections?.[sectionIndex],
              items: [
                ...state?.singleFindingToEdit?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(0, itemIndex),
                {
                  ...state?.singleFindingToEdit?.findingSubjects?.[
                    findingSubjectIndex
                  ]?.sections?.[sectionIndex]?.items?.[itemIndex],
                  columns: {
                    edited:
                      payload?.isEdited ||
                      state?.singleFindingToEdit?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns
                        ?.edited,
                    value: [
                      ...state?.singleFindingToEdit?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[
                        itemIndex
                      ]?.columns?.value?.slice(0, columnIndex),
                      {
                        ...state?.singleFindingToEdit?.findingSubjects?.[
                          findingSubjectIndex
                        ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns
                          ?.value?.[columnIndex],
                        answers: [
                          ...state?.singleFindingToEdit?.findingSubjects?.[
                            findingSubjectIndex
                          ]?.sections?.[sectionIndex]?.items?.[
                            itemIndex
                          ]?.columns?.value?.[columnIndex]?.answers?.slice(
                            0,
                            answerInColumnsIndex
                          ),
                          newColumnAnswer,
                          ...state?.singleFindingToEdit?.findingSubjects?.[
                            findingSubjectIndex
                          ]?.sections?.[sectionIndex]?.items?.[
                            itemIndex
                          ]?.columns?.value?.[columnIndex]?.answers?.slice(
                            answerInColumnsIndex + 1
                          ),
                        ],
                      },
                      ...state?.singleFindingToEdit?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[
                        itemIndex
                      ]?.columns?.value?.slice(columnIndex + 1),
                    ],
                  },
                  columnAnswers: [
                    ...state?.singleFindingToEdit?.findingSubjects?.[
                      findingSubjectIndex
                    ]?.sections?.[sectionIndex]?.items?.[
                      itemIndex
                    ]?.columnAnswers?.slice(0, payload?.rowIndex),
                    [
                      ...state?.singleFindingToEdit?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[
                        itemIndex
                      ]?.columnAnswers?.[payload?.rowIndex]?.slice(
                        0,
                        columnIndex
                      ),
                      {
                        ...newColumnAnswer,
                        columnId: payload?.columnId,
                      },
                      ...state?.singleFindingToEdit?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[
                        itemIndex
                      ]?.columnAnswers?.[payload?.rowIndex]?.slice(
                        columnIndex + 1
                      ),
                    ],
                    ...state?.singleFindingToEdit?.findingSubjects?.[
                      findingSubjectIndex
                    ]?.sections?.[sectionIndex]?.items?.[
                      itemIndex
                    ]?.columnAnswers?.slice(payload?.rowIndex + 1),
                  ],
                  // edited: true,
                },
                ...state?.singleFindingToEdit?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(itemIndex + 1),
              ],
            },
            ...state?.singleFindingToEdit?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(sectionIndex + 1),
          ],
        },
        ...state?.singleFindingToEdit?.findingSubjects?.slice(
          findingSubjectIndex + 1
        ),
      ],
    },
  };
}

function setFindingColumnsFromEdited(state, { payload }) {
  if (state?.singleFinding?.findingSubjects?.length == 0) return { ...state };
  let findingSubjectIndex;
  let sectionIndex;
  let found = false;
  for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
    findingSubjectIndex = i;

    for (
      let j = 0;
      j < state?.singleFinding?.findingSubjects[i]?.sections?.length;
      j++
    ) {
      sectionIndex = j;
      for (
        let l = 0;
        l <
        state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.length;
        l++
      ) {
        if (
          state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
            ?.id === payload?.itemId
        ) {
          found = true;
          break;
        }
      }
      if (found) break;
    }
    if (found) break;
  }
  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: [
        ...state?.singleFinding?.findingSubjects?.slice?.(
          0,
          findingSubjectIndex
        ),
        {
          ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex],
          sections: [
            ...state?.singleFinding?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice?.(0, sectionIndex),
            {
              ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
                ?.sections?.[sectionIndex],
              items: [
                ...state?.singleFinding?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.map?.(
                  (singleItem, index) => ({
                    ...singleItem,
                    columns: {
                      ...state?.singleFindingToEdit?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[index]?.columns,
                    },
                  })
                ),
              ],
            },
            ...state?.singleFinding?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice?.(sectionIndex + 1),
          ],
        },
        ...state?.singleFinding?.findingSubjects?.slice?.(
          findingSubjectIndex + 1
        ),
      ],
    },
  };
}

function setFindingItem(state, { payload }) {
  let findingSubjectIndex;
  let sectionIndex;
  let itemIndex;
  let found = false;
  for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
    findingSubjectIndex = i;

    for (
      let j = 0;
      j < state?.singleFinding?.findingSubjects[i]?.sections?.length;
      j++
    ) {
      sectionIndex = j;
      for (
        let l = 0;
        l <
        state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.length;
        l++
      ) {
        if (
          state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
            ?.id === payload?.itemId
        ) {
          itemIndex = l;
          found = true;
          break;
        }
      }
      if (found) break;
    }
    if (found) break;
  }
  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: [
        ...state?.singleFinding?.findingSubjects?.slice?.(
          0,
          findingSubjectIndex
        ),
        {
          ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex],
          sections: [
            ...state?.singleFinding?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(0, sectionIndex),
            {
              ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
                ?.sections?.[sectionIndex],
              items: [
                ...state?.singleFinding?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(0, itemIndex),
                {
                  ...payload?.item,
                  // edited: true,
                },
                ...state?.singleFinding?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(itemIndex + 1),
              ],
            },
            ...state?.singleFinding?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(sectionIndex + 1),
          ],
        },
        ...state?.singleFinding?.findingSubjects?.slice(
          findingSubjectIndex + 1
        ),
      ],
    },
  };
}
function addFindingColumnAnswer(state, { payload }) {
  let findingSubjectIndex;
  let sectionIndex;
  let itemIndex;
  let found = false;
  for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
    findingSubjectIndex = i;

    for (
      let j = 0;
      j < state?.singleFinding?.findingSubjects[i]?.sections?.length;
      j++
    ) {
      sectionIndex = j;
      for (
        let l = 0;
        l <
        state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.length;
        l++
      ) {
        if (
          state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
            ?.id === payload?.questionId
        ) {
          itemIndex = l;
          found = true;
          break;
        }
      }
      if (found) break;
    }
    if (found) break;
  }
  let newColumnAnswer = state?.singleFinding?.findingSubjects?.[
    findingSubjectIndex
  ]?.sections?.[sectionIndex]?.items?.[itemIndex].columns?.value?.map?.(
    (singleColumn) => ({
      columnId:
        singleColumn?.columnId === 0 || singleColumn?.id === 0
          ? Math.random()
          : singleColumn?.columnId || singleColumn?.id,
      answer: "",
      rowIndex:
        state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
          ?.sections?.[sectionIndex]?.items?.[itemIndex].columns?.value?.[0]
          ?.answers?.length,
    })
  );
  let stateToReturn = {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: [
        ...state?.singleFinding?.findingSubjects?.slice?.(
          0,
          findingSubjectIndex
        ),
        {
          ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex],
          sections: [
            ...state?.singleFinding?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(0, sectionIndex),
            {
              ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
                ?.sections?.[sectionIndex],
              items: [
                ...state?.singleFinding?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(0, itemIndex),
                {
                  ...state?.singleFinding?.findingSubjects?.[
                    findingSubjectIndex
                  ]?.sections?.[sectionIndex]?.items?.[itemIndex],
                  columns: {
                    edited: true,
                    value: [
                      ...state?.singleFinding?.findingSubjects?.[
                        findingSubjectIndex
                      ]?.sections?.[sectionIndex]?.items?.[
                        itemIndex
                      ]?.columns?.value?.map?.((singleColumn) => ({
                        ...singleColumn,
                        answers: [
                          ...singleColumn?.answers,
                          {
                            id: Math.random(),
                            columnId:
                              singleColumn?.id || singleColumn?.columnId,
                            rowIndex: singleColumn?.answers?.length,
                            answer: "",
                            createdByUserId: payload?.userId,
                            createdAtUtc: new Date(
                              new Date()?.getTime() -
                                new Date()?.getTimezoneOffset() * 60000
                            ).toISOString(),
                            updatedByUserId: payload?.userId,
                            updatedAtUtc: new Date(
                              new Date()?.getTime() -
                                new Date()?.getTimezoneOffset() * 60000
                            ).toISOString(),
                          },
                        ],
                      })),
                    ],
                  },
                  columnAnswers: [
                    ...state?.singleFinding?.findingSubjects?.[
                      findingSubjectIndex
                    ]?.sections?.[sectionIndex]?.items?.[itemIndex]
                      ?.columnAnswers,
                    newColumnAnswer,
                  ],
                },
                ...state?.singleFinding?.findingSubjects?.[
                  findingSubjectIndex
                ]?.sections?.[sectionIndex]?.items?.slice(itemIndex + 1),
              ],
            },
            ...state?.singleFinding?.findingSubjects?.[
              findingSubjectIndex
            ]?.sections?.slice(sectionIndex + 1),
          ],
        },
        ...state?.singleFinding?.findingSubjects?.slice(
          findingSubjectIndex + 1
        ),
      ],
    },
  };
  return {
    ...stateToReturn,
    singleFindingToEdit: { ...stateToReturn?.singleFinding },
  };
}
function removeFindingColumnAnswer(state, { payload }) {
  let findingSubjectIndex;
  let sectionIndex;
  let itemIndex;
  let found = false;
  for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
    findingSubjectIndex = i;

    for (
      let j = 0;
      j < state?.singleFinding?.findingSubjects[i]?.sections?.length;
      j++
    ) {
      sectionIndex = j;
      for (
        let l = 0;
        l <
        state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.length;
        l++
      ) {
        if (
          state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
            ?.id === payload?.questionId
        ) {
          itemIndex = l;
          found = true;
          break;
        }
      }
      if (found) break;
    }
    if (found) break;
  }
  let newColumnAnswers = [
    ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]?.sections?.[
      sectionIndex
    ]?.items?.[itemIndex]?.columnAnswers,
  ];
  newColumnAnswers?.splice(payload?.answerIndex, 1);

  // payload?.answerIndex === 0
  //   ? [
  //       ...state?.singleFinding?.findingSubjects?.[
  //         findingSubjectIndex
  //       ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columnAnswers?.slice(
  //         payload?.answerIndex + 1
  //       ),
  //     ]
  //   : [
  //       ...state?.singleFinding?.findingSubjects?.[
  //         findingSubjectIndex
  //       ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columnAnswers?.slice(
  //         0,
  //         payload?.answerIndex - 1
  //       ),
  //       ...state?.singleFinding?.findingSubjects?.[
  //         findingSubjectIndex
  //       ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columnAnswers?.slice(
  //         payload?.answerIndex + 1
  //       ),
  //     ];

  const newSingleFinding = {
    ...state?.singleFinding,
    findingSubjects: [
      ...state?.singleFinding?.findingSubjects?.slice?.(0, findingSubjectIndex),
      {
        ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex],
        sections: [
          ...state?.singleFinding?.findingSubjects?.[
            findingSubjectIndex
          ]?.sections?.slice(0, sectionIndex),
          {
            ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
              ?.sections?.[sectionIndex],
            items: [
              ...state?.singleFinding?.findingSubjects?.[
                findingSubjectIndex
              ]?.sections?.[sectionIndex]?.items?.slice(0, itemIndex),
              {
                ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
                  ?.sections?.[sectionIndex]?.items?.[itemIndex],
                columns: {
                  edited: true,
                  value: [
                    ...state?.singleFinding?.findingSubjects?.[
                      findingSubjectIndex
                    ]?.sections?.[sectionIndex]?.items?.[
                      itemIndex
                    ]?.columns?.value?.map((singleColumn) => ({
                      ...singleColumn,
                      answers: singleColumn?.answers
                        ?.filter?.(
                          (singleAnswer) =>
                            singleAnswer?.rowIndex !== payload?.answerIndex
                        )
                        ?.map?.((singleAnswer, index) => ({
                          ...singleAnswer,
                          rowIndex: index,
                        })),
                    })),
                  ],
                },
                columnAnswers: newColumnAnswers,
              },
              ...state?.singleFinding?.findingSubjects?.[
                findingSubjectIndex
              ]?.sections?.[sectionIndex]?.items?.slice(itemIndex + 1),
            ],
          },
          ...state?.singleFinding?.findingSubjects?.[
            findingSubjectIndex
          ]?.sections?.slice(sectionIndex + 1),
        ],
      },
      ...state?.singleFinding?.findingSubjects?.slice(findingSubjectIndex + 1),
    ],
  };

  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...newSingleFinding,
    },
    singleFindingToEdit: {
      ...newSingleFinding,
    },
  };
}
function copyFindingColumnAnswer(state, { payload }) {
  let findingSubjectIndex;
  let sectionIndex;
  let itemIndex;
  let found = false;
  for (let i = 0; i < state?.singleFinding?.findingSubjects?.length; i++) {
    findingSubjectIndex = i;

    for (
      let j = 0;
      j < state?.singleFinding?.findingSubjects[i]?.sections?.length;
      j++
    ) {
      sectionIndex = j;
      for (
        let l = 0;
        l <
        state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.length;
        l++
      ) {
        if (
          state?.singleFinding?.findingSubjects[i]?.sections?.[j]?.items?.[l]
            ?.id === payload?.questionId
        ) {
          itemIndex = l;
          found = true;
          break;
        }
      }
      if (found) break;
    }
    if (found) break;
  }

  let newColumnAnswers = [
    ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]?.sections?.[
      sectionIndex
    ]?.items?.[itemIndex]?.columnAnswers,
    [
      ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
        ?.sections?.[sectionIndex]?.items?.[itemIndex]?.columnAnswers?.[
        payload?.answerIndex
      ],
    ],
  ];

  const newSingleFinding = {
    ...state?.singleFinding,
    findingSubjects: [
      ...state?.singleFinding?.findingSubjects?.slice?.(0, findingSubjectIndex),
      {
        ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex],
        sections: [
          ...state?.singleFinding?.findingSubjects?.[
            findingSubjectIndex
          ]?.sections?.slice(0, sectionIndex),
          {
            ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
              ?.sections?.[sectionIndex],
            items: [
              ...state?.singleFinding?.findingSubjects?.[
                findingSubjectIndex
              ]?.sections?.[sectionIndex]?.items?.slice(0, itemIndex),
              {
                ...state?.singleFinding?.findingSubjects?.[findingSubjectIndex]
                  ?.sections?.[sectionIndex]?.items?.[itemIndex],
                columns: {
                  edited: true,
                  value: [
                    ...state?.singleFinding?.findingSubjects?.[
                      findingSubjectIndex
                    ]?.sections?.[sectionIndex]?.items?.[
                      itemIndex
                    ]?.columns?.value?.map((singleColumn) => ({
                      ...singleColumn,
                      answers: [
                        ...singleColumn?.answers,
                        {
                          ...singleColumn?.answers?.[payload?.answerIndex],
                          id: Math.random(),
                          rowIndex: singleColumn?.answers?.length,
                        },
                      ],
                    })),
                  ],
                },
                columnAnswers: newColumnAnswers,
              },
              ...state?.singleFinding?.findingSubjects?.[
                findingSubjectIndex
              ]?.sections?.[sectionIndex]?.items?.slice(itemIndex + 1),
            ],
          },
          ...state?.singleFinding?.findingSubjects?.[
            findingSubjectIndex
          ]?.sections?.slice(sectionIndex + 1),
        ],
      },
      ...state?.singleFinding?.findingSubjects?.slice(findingSubjectIndex + 1),
    ],
  };

  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...newSingleFinding,
    },
    singleFindingToEdit: {
      ...newSingleFinding,
    },
  };
}
function addFindingScanningSubject(state, { payload }) {
  let scanningSubjectsToAdd = [...state?.scanningSubjectsToAdd];
  let scanningSubjectsToRemove = [...state?.scanningSubjectsToRemove];
  let scanningSubjectInRemoveList = scanningSubjectsToRemove?.findIndex(
    (singleSubject) => singleSubject === payload?.scanningSubjectId
  );
  if (scanningSubjectInRemoveList !== -1) {
    scanningSubjectsToRemove?.splice(scanningSubjectInRemoveList, 1);
  } else {
    scanningSubjectsToAdd?.push?.(payload?.scanningSubjectId);
  }
  // if (
  //   state?.singleFindingOld?.findingSubjects?.filter?.(
  //     (singleSubject) =>
  //       singleSubject?.scanningSubjectId === payload?.scanningSubjectId
  //   )?.length >
  //   state?.singleFinding?.findingSubjects?.filter?.(
  //     (singleSubject) =>
  //       singleSubject?.scanningSubjectId === payload?.scanningSubjectId
  //   )?.length
  // ) {
  //   scanningSubjectsToAdd?.push?.(payload?.scanningSubjectId);
  // }
  return {
    ...state,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: [
        ...state?.singleFinding?.findingSubjects,
        {
          ...payload,
          orderNumber: {
            edited: false,
            manuallyEdited: false,
            value: state?.singleFinding?.findingSubjects?.filter(
              (singleSubject) => !singleSubject?.removed
            )?.length,
          },
        },
      ],
    },
    scanningSubjectsToAdd,
    scanningSubjectsToRemove,
    scanningSubjectsSelectedConclusions: [
      ...state?.scanningSubjectsSelectedConclusions,
      { id: payload?.id, checkedConclusion: null },
    ],
  };
}
function setFindingScanningSubject(state, { payload }) {
  return {
    ...state,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: payload,
    },
  };
}
function removeFindingScanningSubject(state, { payload }) {
  let scanningSubject = state?.singleFinding?.findingSubjects?.find?.(
    (singleSubject) => singleSubject?.id === payload
  );
  let scanningSubjectId = scanningSubject?.scanningSubjectId;
  let scanningSubjectOrderNumber = scanningSubject?.orderNumber?.value;
  let scanningSubjectsToAdd = [...state?.scanningSubjectsToAdd];
  let scanningSubjectsToRemove = [...state?.scanningSubjectsToRemove];
  let scanningSubjectInAddList = scanningSubjectsToAdd?.findIndex(
    (singleSubject) => singleSubject === scanningSubjectId
  );
  if (scanningSubjectInAddList !== -1) {
    scanningSubjectsToAdd?.splice(scanningSubjectInAddList, 1);
  } else {
    scanningSubjectsToRemove?.push?.(scanningSubjectId);
  }
  let newSubjects = [...state?.singleFinding?.findingSubjects]?.reduce(
    (acc, val) => {
      if (val?.id === payload) {
        if (val?.added) return [...acc];
        else return [...acc, { ...val, removed: true }];
      }
      return [...acc, val];
    },
    []
  );
  return {
    ...state,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: [
        ...newSubjects
          // ?.filter?.((singleSubject) => singleSubject?.id !== payload)
          ?.map((singleSubject) => {
            if (
              singleSubject?.orderNumber?.value >= scanningSubjectOrderNumber
            ) {
              return {
                ...singleSubject,
                orderNumber: {
                  edited: true,
                  manuallyEdited: singleSubject?.orderNumber?.manuallyEdited,
                  value: singleSubject?.orderNumber?.value - 1,
                },
              };
            } else {
              return { ...singleSubject };
            }
          }),
      ],
    },
    scanningSubjectsToAdd,
    scanningSubjectsToRemove,
  };
}
function setFindingsTotal(state, { payload }) {
  return {
    ...state,
    totalFindings: payload,
  };
}
function clearFindings(state) {
  return {
    ...state,
    findings: initialState.findings,
    findingsSecond: initialState.findingsSecond,
    totalFindings: initialState.totalFindings,
  };
}
function setErrorMessage(state, { payload }) {
  return {
    ...state,
    errorMessage: payload,
  };
}
function setFindingChanged(state, { payload }) {
  return {
    ...state,
    findingsChanged: payload,
  };
}
function moveFindingSubjectAbove(state, { payload }) {
  if (payload === state?.singleFinding?.findingSubjects?.length - 1)
    return state;
  return {
    ...state,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: state?.singleFinding?.findingSubjects?.map?.(
        (singleSubject) => {
          if (singleSubject?.orderNumber?.value === payload)
            return {
              ...singleSubject,
              orderNumber: {
                edited: true,
                manuallyEdited: true,
                value: singleSubject?.orderNumber?.value + 1,
              },
            };
          if (singleSubject?.orderNumber?.value === payload + 1)
            return {
              ...singleSubject,
              orderNumber: {
                edited: true,
                manuallyEdited: true,
                value: singleSubject?.orderNumber?.value - 1,
              },
            };
          return singleSubject;
        }
      ),
    },
  };
}
function moveFindingSubjectBelow(state, { payload }) {
  if (payload === 0) return state;
  return {
    ...state,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: state?.singleFinding?.findingSubjects?.map?.(
        (singleSubject) => {
          if (singleSubject?.orderNumber?.value === payload)
            return {
              ...singleSubject,
              orderNumber: {
                edited: true,
                manuallyEdited: true,
                value: singleSubject?.orderNumber?.value - 1,
              },
            };
          if (singleSubject?.orderNumber?.value === payload - 1)
            return {
              ...singleSubject,
              orderNumber: {
                edited: true,
                manuallyEdited: true,
                value: singleSubject?.orderNumber?.value + 1,
              },
            };
          return singleSubject;
        }
      ),
    },
  };
}
function replaceFindingSubjectOrderNumber(state, { payload }) {
  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: state?.singleFinding?.findingSubjects?.map?.(
        (singleSubject) => {
          if (payload?.firstOrderNumber < payload?.secondOrderNumber) {
            if (singleSubject?.orderNumber?.value === payload?.firstOrderNumber)
              return {
                ...singleSubject,
                orderNumber: {
                  edited: true,
                  manuallyEdited: true,
                  value: payload?.secondOrderNumber,
                },
              };
            if (
              singleSubject?.orderNumber?.value > payload?.firstOrderNumber &&
              singleSubject?.orderNumber?.value <= payload?.secondOrderNumber
            ) {
              return {
                ...singleSubject,
                orderNumber: {
                  edited: true,
                  manuallyEdited: true,
                  value: singleSubject?.orderNumber?.value - 1,
                },
              };
            } else {
              return {
                ...singleSubject,
                orderNumber: {
                  edited: singleSubject?.orderNumber?.edited,
                  manuallyEdited: singleSubject?.orderNumber?.manuallyEdited,
                  value: singleSubject?.orderNumber?.value,
                },
              };
            }
          }
          if (payload?.firstOrderNumber > payload?.secondOrderNumber) {
            if (singleSubject?.orderNumber?.value === payload?.firstOrderNumber)
              return {
                ...singleSubject,
                orderNumber: {
                  edited: true,
                  manuallyEdited: true,
                  value: payload?.secondOrderNumber,
                },
              };
            if (
              singleSubject?.orderNumber?.value < payload?.firstOrderNumber &&
              singleSubject?.orderNumber?.value >= payload?.secondOrderNumber
            ) {
              return {
                ...singleSubject,
                orderNumber: {
                  edited: true,
                  manuallyEdited: true,
                  value: singleSubject?.orderNumber?.value + 1,
                },
              };
            } else {
              return {
                ...singleSubject,
                orderNumber: {
                  edited: singleSubject?.orderNumber?.edited,
                  manuallyEdited: singleSubject?.orderNumber?.manuallyEdited,
                  value: singleSubject?.orderNumber?.value,
                },
              };
            }
          }
          // return singleSubject;

          // if (singleSubject?.orderNumber === payload?.secondOrderNumber)
          //   return {
          //     ...singleSubject,
          //     orderNumber: payload?.firstOrderNumber,
          //   };
          // return singleSubject;
        }
      ),
    },
  };
}
function revertOldFinding(state) {
  return {
    ...state,
    singleFinding: {
      ...state?.singleFindingOld,
      date: new Date(),
      closingDate: "",
      findingNumber: undefined,
    },
  };
}

//NEW
function setSingleFindingToEdit(state, { payload }) {
  return {
    ...state,
    singleFindingToEdit: payload,
    // isEditing: true,
  };
}

function setWorkspaceSchemas(state, { payload }) {
  return {
    ...state,
    singleFinding: {
      ...state.singleFinding,
      workspaceSchemas: [...payload],
    },
  };
}

function setStaffs(state, { payload }) {
  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state.singleFinding,
      staffs: [...payload],
    },
  };
}

function editFindingSingleStuff(state, { payload }) {
  const stuffIndex = state?.singleFinding?.staffs?.findIndex(
    (singleStuff) => singleStuff?.value?.id === payload?.staff?.value?.id
  );
  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state.singleFinding,
      staffs: [
        ...state?.singleFinding?.staffs?.slice(0, stuffIndex),
        {
          ...payload?.staff,
        },
        ...state?.singleFinding?.staffs?.slice(stuffIndex + 1),
      ],
    },
  };
}

function setSettings(state, { payload }) {
  return {
    ...state,
    singleFinding: {
      ...state.singleFinding,
      ...payload,
    },
  };
}

function setNote(state, { payload }) {
  return {
    ...state,
    singleFinding: {
      ...state.singleFinding,
      conclusion: payload,
    },
  };
}
function addFindingStateChange(state, { payload }) {
  return {
    ...state,
    singleFinding: {
      ...state.singleFinding,
      findingStateChanges: [
        ...state.singleFinding.findingStateChanges,
        {
          createdByUser: {
            firstName: payload?.firstName,
            lastName: payload?.lastName,
          },
          createdAtUtc: payload?.time,
          findingStateTypeId: payload?.newStatusId,
        },
      ],
    },
  };
}

function setConclusion(state, { payload }) {
  const conclusionsArray = [...state?.scanningSubjectsSelectedConclusions];
  conclusionsArray?.map((conclusion) => {
    if (conclusion?.id === payload?.id)
      conclusion.checkedConclusion = payload?.checkedConclusion;
  });
  return {
    ...state,
    scanningSubjectsSelectedConclusions: conclusionsArray,
  };
}

function setNewSubjectName(state, { payload }) {
  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state?.singleFinding,
      findingSubjects: state?.singleFinding?.findingSubjects?.map?.(
        (singleSubject) => {
          if (singleSubject?.id === payload?.subjectId)
            return {
              ...singleSubject,
              specificName: { value: payload?.specificName, edited: true },
            };
          else return singleSubject;
        }
      ),
    },
  };
}

function setFindingTypes(state, { payload }) {
  return {
    ...state,
    findingTypes: payload?.data,
  };
}
function clearFindingTypes(state) {
  return {
    ...state,
    findingTypes: initialState.findingTypes,
  };
}

function setAttendees(state, { payload }) {
  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state.singleFinding,
      findingAttendees: [...payload],
    },
  };
}

function removeAttendedPerson(state, { payload }) {
  let newAttendees = [...state.singleFinding.findingAttendees];
  let dataIndex = newAttendees.findIndex(
    (entity) => entity.value.id === payload
  );
  if (newAttendees[dataIndex]?.added) newAttendees?.splice(dataIndex, 1);
  else
    newAttendees?.splice(dataIndex, 1, {
      value: state.singleFinding.findingAttendees[dataIndex],
      removed: true,
    });
  return {
    ...state,
    findingsChanged: true,
    singleFinding: {
      ...state?.singleFinding,
      findingAttendees: newAttendees,
    },
  };
}
