import React from "react";
import PropTypes from "prop-types";
import { ButtonsContainer, CloseButton, ModalTitle } from "../Modal.styled";
import { useDispatch } from "react-redux";
import { closeModal } from "../../../store/actions/modal/modalActions";
import {
  CancelButton,
  SubmitButton,
  DocumentTypeModalContainer,
  InputsContainer,
} from "./DocumentTypeModal.styled";
import { useTranslation } from "react-i18next";
import BackdropComponent from "../../Modals/ModalComponents/BackdropComponent";
import CaptionField from "./CaptionField/CaptionField";
import DescriptionField from "./DescriptionField/DescriptionField";
import { useFormik } from "formik";
import { useMemo } from "react";
import { documentTypesInitialValues } from "../../../initialValues/documentTypesInitialValues";
import documentTypesValidationSchema from "../../../validations/documentTypesValidationSchema";
import WithPermissions from "../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import useAuth from "../../../hooks/useAuth";

const DocumentTypeModal = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isManager } = useAuth();

  const initialValues = useMemo(() => {
    return documentTypesInitialValues(props?.prefilledData);
  }, [props?.prefilledData]);

  const handleSubmit = (values) => {
    props?.handleSubmit?.(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: documentTypesValidationSchema(),
    onSubmit: handleSubmit,
  });

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  return (
    <>
      {props?.rank > 1 ? (
        <BackdropComponent
          isLoading
          handleClose={handleCloseModal}
          position="fixed"
        />
      ) : (
        <></>
      )}
      <DocumentTypeModalContainer rank={props?.rank}>
        <ModalTitle>{props?.title}</ModalTitle>
        <CloseButton onClick={handleCloseModal} />
        <InputsContainer>
          <CaptionField formik={formik} disabled={!isManager} />
          <DescriptionField formik={formik} disabled={!isManager} />
        </InputsContainer>

        <WithPermissions roles={[MANAGER_ROLE]}>
          <ButtonsContainer>
            <CancelButton
              onClick={handleCloseModal}
              $variant={props?.cancelButtonVariant}
            >
              {t("common.cancel")}
            </CancelButton>
            <SubmitButton onClick={formik?.handleSubmit}>
              {props?.isEditing ? t("common.save") : t("common.add")}
            </SubmitButton>
          </ButtonsContainer>
        </WithPermissions>
      </DocumentTypeModalContainer>
    </>
  );
};

DocumentTypeModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelButtonVariant: PropTypes.number,
  rank: PropTypes.number,
  history: PropTypes.any,
  handleSubmit: PropTypes.func,
  prefilledData: PropTypes.object,
  isEditing: PropTypes.bool,
};

DocumentTypeModal.defaultProps = {
  cancelButtonVariant: 1,
  data: [],
  handleSubmit: () => {},
};

export default DocumentTypeModal;
