import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    name: Yup.string()
      .trim()
      .max(255, "findingTypes.modal.nameNoOfChars")
      .required("findingTypes.modal.nameEmpty"),
    caption: Yup.string()
      .trim()
      .max(255, "findingTypes.modal.captionNoOfChars")
      .required("findingTypes.modal.captionEmpty"),
    subcaption: Yup.string()
      .trim()
      .max(255, "findingTypes.modal.subcaptionNoOfChars")
      .required("findingTypes.modal.subcaptionEmpty"),
  });
