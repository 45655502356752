import React from "react";
import { ReactComponent as EnFlag } from "../assets/images/languages/en.svg";
import { ReactComponent as SrFlag } from "../assets/images/languages/sr.svg";
import { EN_CULTURE, RS_CULTURE } from "./culturesConstants";

export const LANGUAGES = [
  {
    culture: RS_CULTURE,
    text: "Srb",
    icon: <SrFlag />,
  },
  {
    culture: EN_CULTURE,
    text: "Eng",
    icon: <EnFlag />,
  },
];

export const findLanguageByCulture = (culture) =>
  LANGUAGES.find((singleLanguage) => singleLanguage?.culture === culture);
