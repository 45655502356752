import React from "react";
import PropTypes from "prop-types";
import {
  ActionRendererContainer,
  ActionRendererDeleteFindingTypeIconContainer,
  ActionRendererEditFindingTypeIconContainer,
} from "./ActionRenderer.styled";
import EditIcon from "../../../../Icon/Icons/EditIcon";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon.js";
import {
  closeLoadingModal,
  closeModal,
  toggleSetDeleteModal,
  toggleSetFindingTypeModal,
  toggleSetLoadingModal,
} from "../../../../../store/actions/modal/modalActions";
import i18n from "../../../../../i18n";
import { fetchDeleteFindingType } from "../../../../../store/actions/findingTypes/findingTypesActions";
import Tooltip from "../../../../Tooltip/Tooltip";
import WithPermissions from "../../../../WithPermissions/WithPermissions";
import { MANAGER_ROLE } from "../../../../../constants/rolesConstants";

const ActionRenderer = (props) => {
  const handleApiResponseSuccess = (name) => {
    props?.dispatch(closeModal());
    props?.value?.refetchTable(name);
  };

  const handleApiResponseError = () => {
    props?.dispatch(closeLoadingModal());
  };

  const handleEditFindingType = () => {
    props?.dispatch(
      toggleSetFindingTypeModal({
        title: i18n.t("findingTypes.modal.editFindingType"),
        prefilledData: {
          name: props?.value?.name,
          caption: props?.value?.caption,
          subcaption: props?.value?.subcaption,
        },
        isEditing: true,
        rank: 1,
        id: props?.value?.id,
        refetchTable: props?.value?.refetchTable,
        tableRef: props?.value.tableRef,
      })
    );
  };

  const handleDeleteFindingType = () => {
    props?.dispatch(toggleSetLoadingModal({ rank: 2 }));
    props?.dispatch(
      fetchDeleteFindingType({
        id: props?.value?.id,
        handleApiResponseSuccess: () =>
          handleApiResponseSuccess({
            inputValue: props?.value?.name,
            isDeleted: true,
          }),
        handleApiResponseError,
      })
    );
  };

  const handleDelete = () => {
    props?.dispatch(
      toggleSetDeleteModal({
        title: i18n.t("findingTypes.modal.delete.title"),
        description: {
          first: i18n.t("findingTypes.modal.delete.description.1"),
          second: i18n.t("findingTypes.modal.delete.description.2"),
        },
        onSubmit: handleDeleteFindingType,
        rank: 1,
      })
    );
  };

  return (
    <ActionRendererContainer>
      <Tooltip title={i18n.t("findingTypes.tooltip.changeFindingType")}>
        <ActionRendererEditFindingTypeIconContainer
          onClick={handleEditFindingType}
        >
          <EditIcon />
        </ActionRendererEditFindingTypeIconContainer>
      </Tooltip>
      <WithPermissions roles={[MANAGER_ROLE]}>
        <Tooltip title={i18n.t("findingTypes.tooltip.deleteFindingType")}>
          <ActionRendererDeleteFindingTypeIconContainer onClick={handleDelete}>
            <DeleteIcon />
          </ActionRendererDeleteFindingTypeIconContainer>
        </Tooltip>
      </WithPermissions>
    </ActionRendererContainer>
  );
};

ActionRenderer.propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  value: PropTypes.object,
  rendererProps: PropTypes.any,
  refetchTable: PropTypes.func,
};

export default ActionRenderer;
