import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";
import { NAME_KEY } from "../../constants/queryConstants";
import {
  attemptBlockUser,
  attemptChangeUserPassword,
  attemptCreateUser,
  attemptEditUser,
  attemptFetchSingleUser,
  attemptFetchUsers,
  attemptUnblockUser,
} from "../../request/usersRequest";
import {
  BLOCK_USER_FETCH,
  CREATE_USER_FETCH,
  EDIT_USER_FETCH,
  GET_USERS_FETCH,
  MY_USER_FETCH,
  SINGLE_USER_FETCH,
  UNBLOCK_USER_FETCH,
  USER_CHANGE_PASSWORD_FETCH,
} from "../actions/user/userActionConstants";
import {
  fetchBlockUserError,
  fetchBlockUserSuccess,
  fetchChangeUserPasswordError,
  fetchChangeUserPasswordSuccess,
  fetchCreateUserError,
  fetchCreateUserSuccess,
  fetchEditUserError,
  fetchEditUserSuccess,
  fetchMyUserError,
  fetchMyUserSuccess,
  fetchSingleUserError,
  fetchSingleUserSuccess,
  fetchUnblockUserError,
  fetchUnblockUserSuccess,
  fetchUsersError,
  fetchUsersSuccess,
  setMyUser,
  setSingleUser,
  setTotalUsers,
  setUsers,
} from "../actions/user/userActions";
import { selectMyProfileId } from "../selectors/loginSelectors";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";
import { selectMyCompany } from "../selectors/companiesSelector";

function* fetchUsers({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload, [NAME_KEY]);
    if (payload?.companyId) queryObject.set("CompanyId", payload?.companyId);
    else queryObject.set("isActive", payload?.customPayload?.isActive);
    const data = yield call(attemptFetchUsers, queryObject);
    yield put(
      setUsers({
        data: data?.data?.users,
      })
    );

    yield put(
      setTotalUsers({
        count: data?.data?.count,
      })
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchUsersSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchUsersError(errorMessage));
  }
}

function* fetchCreateUser({ payload }) {
  try {
    let requestObject = {
      ...payload?.userData,
      createdDate: new Date().toISOString(),
    };
    if (payload?.companyId)
      requestObject.companyId = Number(payload?.companyId);
    const data = yield call(attemptCreateUser, requestObject);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.data?.id);
    }
    yield put(fetchCreateUserSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCreateUserError(errorMessage));
  }
}

function* fetchEditUser({ payload }) {
  try {
    let myCompany = yield select(selectMyCompany);
    let requestObject = {
      ...payload?.userData,
      createdDate: new Date().toISOString(),
      companyId: Number(payload?.userData?.companyId) || myCompany?.id,
    };
    if (requestObject.password.length === 0) delete requestObject.password;
    yield call(attemptEditUser, {
      data: requestObject,
      userId: payload?.userId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEditUserSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditUserError(errorMessage));
  }
}

function* fetchChangeUserPassword({ payload }) {
  try {
    const requestObject = {
      newPassword: payload?.newPassword,
    };
    yield call(attemptChangeUserPassword, {
      data: requestObject,
      userId: payload?.userId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchChangeUserPasswordSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchChangeUserPasswordError(errorMessage));
  }
}

function* fetchMyUser({ payload }) {
  try {
    const userId = yield select(selectMyProfileId);
    let myCompany = yield select(selectMyCompany);
    let requestParams = {
      userId,
      query: { companyId: payload?.companyId || myCompany?.id },
    };
    const { data } = yield call(attemptFetchSingleUser, requestParams);
    yield put(
      setMyUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        userRole: data.roles,
        userId: data.id,
        createdAtUtc: data.createdAtUtc,
        createdByUser: data.createdByUser,
        updatedAtUtc: data.updatedAtUtc,
        updatedByUser: data.updatedByUser,
      })
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchMyUserSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchMyUserError(errorMessage));
  }
}

function* fetchSingleUser({ payload }) {
  try {
    let myCompany = yield select(selectMyCompany);
    let requestParams = {
      userId: payload?.userId,
      query: { companyId: payload?.companyId || myCompany?.id },
    };
    const { data } = yield call(attemptFetchSingleUser, requestParams);
    yield put(
      setSingleUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        userRole: data.roles,
        userId: data.id,
        createdAtUtc: data.createdAtUtc,
        createdByUser: data.createdByUser,
        updatedAtUtc: data.updatedAtUtc,
        updatedByUser: data.updatedByUser,
        blocked: data?.blocked,
      })
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleUserSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleUserError(errorMessage));
  }
}

function* fetchBlockUser(payload) {
  try {
    yield call(attemptBlockUser, {
      userId: payload?.payload?.userId,
    });
    if (payload?.payload?.handleApiResponseSuccess) {
      yield call(payload?.payload?.handleApiResponseSuccess, {
        isBlocked: true,
      });
    }
    yield put(fetchBlockUserSuccess());
  } catch (e) {
    console.log("greska", e);
    const errorMessage = e?.response?.data?.message;
    if (payload?.payload?.handleApiResponseError) {
      yield call(payload?.payload?.handleApiResponseError);
    }
    yield put(fetchBlockUserError(errorMessage));
  }
}

function* fetchUnblockUser(payload) {
  try {
    yield call(attemptUnblockUser, {
      userId: payload?.payload?.userId,
    });
    if (payload?.payload?.handleApiResponseSuccess) {
      yield call(payload?.payload?.handleApiResponseSuccess, {
        isUnblocked: true,
      });
    }
    yield put(fetchUnblockUserSuccess());
  } catch (e) {
    console.log("greska", e);

    const errorMessage = e?.response?.data?.message;
    if (payload?.payload?.handleApiResponseError) {
      yield call(payload?.payload?.handleApiResponseError);
    }
    yield put(fetchUnblockUserError(errorMessage));
  }
}

export default function* usersSaga() {
  yield all([
    takeLatest(GET_USERS_FETCH, fetchUsers),
    takeLatest(CREATE_USER_FETCH, fetchCreateUser),
    takeLatest(SINGLE_USER_FETCH, fetchSingleUser),
    takeLatest(EDIT_USER_FETCH, fetchEditUser),
    takeLatest(BLOCK_USER_FETCH, fetchBlockUser),
    takeLatest(UNBLOCK_USER_FETCH, fetchUnblockUser),
    takeLatest(USER_CHANGE_PASSWORD_FETCH, fetchChangeUserPassword),
    takeLatest(MY_USER_FETCH, fetchMyUser),
  ]);
}
