import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { documentFormikConstants } from "../../../../initialValues/documentInitialValues";
import { selectClientsData } from "../../../../store/selectors/clientsSelector";
import AutocompleteField from "../../../Field/AutocompleteField/AutocompleteField";

const ClientField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const clients = useSelector(selectClientsData);

  const value = useMemo(() => {
    let clientToReturn = clients.find(
      (client) =>
        client?.id == formik?.values?.[documentFormikConstants.clientId]
    );
    return clientToReturn;
  }, [formik?.values?.[documentFormikConstants.clientId], clients]);

  const handleChange = (value) => {
    formik?.setFieldValue?.(documentFormikConstants.clientId, value?.id);
  };

  return (
    <AutocompleteField
      options={clients}
      removeMaxWidth
      label={t("documents.newPage.form.client.placeholder")}
      disabled={props?.disabled}
      title={t("documents.newPage.form.client.title")}
      required={formik?.values?.[documentFormikConstants.clientId] == null}
      onChange={handleChange}
      dropdownLabel={["name"]}
      value={value}
      error={
        formik?.touched?.[documentFormikConstants.clientId] &&
        formik?.errors?.[documentFormikConstants.clientId] &&
        t(formik?.errors?.[documentFormikConstants.clientId])
      }
    />
  );
};

ClientField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  stateId: PropTypes.number,
};

export default ClientField;
