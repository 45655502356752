import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { hexToRGB } from "../../../util/helpers/colorHelper";
import Select from "../../Select/Select";
import TextInputField from "../../Field/TextInputField/TextInputField";
import { InputLabel } from "../../Field/TextInputField/TextInputField.styled";
import Title from "../../Typography/Title/Title";

export const TemplateSetQuestionContainer = styled(Box)`
  background-color: ${selectedTheme.colors.white};
  border-radius: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  height: fit-content;
  width: 460px;
  overflow: auto;
  z-index: ${(props) => "15" + props?.rank || "151"};
  @media (max-width: 600px) {
    width: 100%;
    height: ${(props) => (props?.questionType ? "fit-content" : "80%")};
    overflow: auto;
  }
`;
export const TemplateSetQuestionTitle = styled(Title)`
  font-size: 18px;
  line-height: 28px;
`;

export const TextInputContainer = styled(Box)`
  display: flex;
  height: fit-content;
  gap: 6px;
`;

export const DescriptionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: fit-content;
  gap: 6px;
  margin-bottom: 16px;
`;

export const QuestionOrdinalNumberContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 6px;
  width: 76px;
  height: 92px;
  margin-left: 10px;
`;

export const QuestionOrdinalNumber = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  margin-top: 5px;
  gap: 8px;
  width: 76px;
  height: 40px;
  background: ${selectedTheme.colors.tableBorder};
  border: 1px solid ${selectedTheme.colors.inputBorderColor};
  border-radius: 8px;
`;
export const TextInput = styled(TextInputField)`
  height: 44px;
  margin: 0;
  text-align: left;
  & ${InputLabel} {
    color: ${selectedTheme.colors.labelTextColor};
  }
  & div {
    width: 100%;
  }
  & input {
    border: 2px solid ${selectedTheme.colors.disabledButtonBackground};
    border-radius: 12px;
    width: 100%;
  }
`;
export const ChapterTitle = styled(Title)`
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${selectedTheme.colors.labelTextColor};
  font-family: ${selectedTheme.fonts.mainText};
`;
export const ChapterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
`;

export const TextareaContainer = styled(ChapterContainer)`
  & .wrapper-text-editor {
    border: 2px solid ${selectedTheme.colors.disabledButtonBackground};
    border-radius: 12px;
    box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
    overflow: hidden;
  }
`;

export const SelectInput = styled(Select)`
  height: 48px;
  text-align: left;
  & * {
    max-height: 48px;
  }
`;

export const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
`;
export const CancelButton = styled(Box)`
  background-color: ${selectedTheme.colors.white};
  color: ${selectedTheme.colors.primaryButtonTextColor};
  text-transform: none;
  border-radius: 8px;
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  width: 200px;
  white-space: nowrap;
  border: 1px solid ${selectedTheme.fonts.tableBorder};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 18px;
  gap: 8px;
  cursor: pointer;
`;
export const SaveButton = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  height: 44px;
  color: white;
  width: 200px;
  border-radius: 8px;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  background: ${selectedTheme.colors.orange};
  ${(props) =>
    props?.disabled &&
    css`
      background: ${hexToRGB(selectedTheme.colors.pageTitleColor, 0.43)};
      cursor: default;
      color: black;
    `}
`;
export const ModalErrorContainer = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 400;
  color: ${selectedTheme.colors.errorColor};
  margin-top: 3px;
`;
