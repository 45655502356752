import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";
import {
  attemptCreateDocument,
  attemptDeleteDocument,
  attemptEditSingleDocument,
  attemptFetchDocuments,
  attemptFetchSingleDocument,
} from "../../request/documentsRequest";
import {
  CREATE_DOCUMENT_FETCH,
  DELETE_DOCUMENT_FETCH,
  EDIT_DOCUMENT_FETCH,
  GET_DOCUMENTS_FETCH,
  SINGLE_DOCUMENT_FETCH,
} from "../actions/documents/documentsActionConstants";
import {
  fetchDocumentsError,
  fetchDocumentsSuccess,
  fetchCreateDocumentError,
  fetchCreateDocumentSuccess,
  fetchDeleteDocumentError,
  fetchDeleteDocumentSuccess,
  fetchEditDocumentError,
  fetchEditDocumentSuccess,
  fetchSingleDocumentError,
  fetchSingleDocumentSuccess,
  setDocuments,
  setSingleDocument,
  setTotalDocuments,
} from "../actions/documents/documentsActions";
import { mapFetchAllFunction } from "../utils/mapFetchAllFunctions";
import { selectSingleDocument } from "../selectors/documentsSelector";
import { convertDateToISO } from "../../util/helpers/dateHelpers";

function* fetchDocuments({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload, ["name"]);
    if (payload?.customPayload) queryObject.set("Intersect", true);
    if (payload?.customPayload?.client)
      queryObject.set("clientId", payload?.customPayload?.client);
    if (payload?.customPayload?.documentType)
      queryObject.set("documentTypeId", payload?.customPayload?.documentType);
    if (payload?.customPayload?.date)
      queryObject.set(
        "documentDate",
        convertDateToISO(new Date(payload?.customPayload?.date))
      );
    const data = yield call(attemptFetchDocuments, queryObject);
    yield put(
      setDocuments({
        data: data?.data?.documents,
      })
    );
    yield put(setTotalDocuments(data?.data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchDocumentsSuccess());
  } catch (e) {
    console.log("greska", e);
    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError);
    }
    yield put(fetchDocumentsError());
  }
}

function* fetchCreateDocument({ payload }) {
  try {
    const data = yield call(attemptCreateDocument, payload?.documentData);
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.data);
    }
    yield put(fetchCreateDocumentSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchCreateDocumentError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchCreateDocumentError());
    }
  }
}

function* fetchSingleDocument({ payload }) {
  try {
    const { data } = yield call(attemptFetchSingleDocument, {
      documentId: payload?.documentId,
    });
    yield put(setSingleDocument(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleDocumentSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchSingleDocumentError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchSingleDocumentError());
    }
  }
}

function* fetchEditSingleDocument({ payload }) {
  try {
    const { data } = yield call(attemptEditSingleDocument, {
      documentId: payload?.documentId,
      data: { id: payload?.documentId, ...payload?.documentData },
    });
    yield put(setSingleDocument(data));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data);
    }
    yield put(fetchEditDocumentSuccess());
  } catch (e) {
    console.log("greska", e);
    if (e.response && e.response.data) {
      const errorMessage = e?.response?.data?.message;
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError, errorMessage);
      }
      yield put(fetchEditDocumentError(errorMessage));
    } else {
      if (payload?.handleApiResponseError) {
        yield call(payload?.handleApiResponseError);
      }
      yield put(fetchEditDocumentError());
    }
  }
}

function* fetchDeleteDocument({ payload }) {
  try {
    const { documentNumber } = yield select(selectSingleDocument);

    yield call(attemptDeleteDocument, {
      documentId: payload?.id,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, {
        isDeleted: true,
        name: documentNumber,
      });
    }
    yield put(fetchDeleteDocumentSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteDocumentError(errorMessage));
  }
}

export default function* documentsSaga() {
  yield all([
    takeLatest(GET_DOCUMENTS_FETCH, fetchDocuments),
    takeLatest(CREATE_DOCUMENT_FETCH, fetchCreateDocument),
    takeLatest(EDIT_DOCUMENT_FETCH, fetchEditSingleDocument),
    takeLatest(SINGLE_DOCUMENT_FETCH, fetchSingleDocument),
    takeLatest(DELETE_DOCUMENT_FETCH, fetchDeleteDocument),
  ]);
}
