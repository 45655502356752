import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import {
  fetchEditDocument,
  fetchSingleDocument,
} from "../../../store/actions/documents/documentsActions";
import NewDocumentContent from "../../../components/Documents/NewDocument/NewDocumentContent";
import { batch, useDispatch, useSelector } from "react-redux";
import { fetchClients } from "../../../store/actions/clients/clientsActions";
import { ASC_KEY, NAME_KEY } from "../../../constants/queryConstants";
import { fetchDocumentTypes } from "../../../store/actions/documentTypes/documentTypesActions";
import { selectSingleDocument } from "../../../store/selectors/documentsSelector";
import { useRouteMatch } from "react-router-dom";

const EditDocumentPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const document = useSelector(selectSingleDocument);
  const routeMatch = useRouteMatch();

  useEffect(() => {
    batch(() => {
      dispatch(
        fetchSingleDocument({ documentId: routeMatch?.params?.documentId })
      );
      dispatch(
        fetchClients({
          page: 1,
          size: 200,
          sorting: {
            column: NAME_KEY,
            sortDirection: ASC_KEY,
          },
        })
      );
      dispatch(
        fetchDocumentTypes({
          page: 1,
          size: 200,
          sorting: {
            column: NAME_KEY,
            sortDirection: ASC_KEY,
          },
        })
      );
    });
  }, []);

  return (
    <NewDocumentContent
      dispatchFunction={fetchEditDocument}
      prefilledData={document}
      isEditing
      title={t("documents.newPage.document")}
      submitText={t("common.save")}
    />
  );
};

EditDocumentPage.propTypes = {
  children: PropTypes.node,
};

export default EditDocumentPage;
