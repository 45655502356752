import { MenuItem, Select, Typography, Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";
import { ReactComponent as DropdownImage } from "../../../assets/images/svg/dropdown-icon.svg";

export const DropdownContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 16px;
  height: 44px;
  @media (max-width: 600px) {
    height: 32px;
  }
`;
export const FieldText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-weight: 600;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: inline;
  ${(props) =>
    props?.setColor &&
    css`
      color: ${selectedTheme.colors.loader};
    `}
`;

export const SelectOption = styled(MenuItem)``;

export const SelectField = styled(Select)`
  width: 68px;
  height: 44px;
  border-radius: 8px;
  text-align: center;
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 400;
  font-size: 16px;
  margin-left: 10px;
  margin-right: 10px;
  gap: 4px;
  & fieldset {
    border-color: ${selectedTheme.colors.borderColor};
    border-width: 2px;
  }
  &:hover fieldset {
    border-color: ${selectedTheme.colors.borderColor};
  }
  &.Mui-focused fieldset {
    border-color: ${selectedTheme.colors.borderColor};
  }
  &.Mui-error fieldset {
    border-color: ${selectedTheme.colors.borderColor};
  }
  @media (max-width: 600px) {
    height: 32px;
    width: 54px;
    font-size: 12px;
    line-height: 24px;
    & div {
      padding-left: 8px;
      text-overflow: initial !important;
      overflow: initial !important;
    }
  }
`;

export const DropdownIcon = styled(DropdownImage)`
  position: absolute;
  right: 10px;
  pointer-events: none;
  & path {
    stroke: ${selectedTheme.colors.findingDateStateChanged};
  }
  @media (max-width: 600px) {
    width: 10px;
    height: 5px;
  }
`;
