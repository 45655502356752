import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    clientName: Yup.string()
      .trim()
      .max(255, "clients.clientNameNumberOfCharacters")
      .required("clients.clientNameRequired"),
    pib: Yup.string()
      .trim()
      .required("clients.pibRequired")
      .min(9, "clients.pibNumberOfCharacters")
      .max(9, "clients.pibNumberOfCharacters"),
    address: Yup.string()
      .trim()
      .max(255, "clients.addressNumberOfCharacters")
      .required("clients.addressRequired"),
    phoneNumber: Yup.string().max(255, "clients.phoneNumberNumberOfCharacters"),
    idNumber: Yup.string()
      .trim()
      .required("clients.idNumberRequired")
      .min(8, "clients.idNumberNoOfCharacters")
      .max(8, "clients.idNumberNoOfCharacters"),
    contactEmail: Yup.string()
      .email("clients.emailFormat")
      .max(255, "clients.contactEmailNumberOfCharacters"),

    distributionEmails: Yup.array().of(
      Yup.string()
        .email("clients.emailFormat")
        .max(255, "clients.distributionEmailNumberOfCharacters")
    ),
  });
