export const primaryThemeColors = {
  white: "#FFFFFF",
  black: "#000000",
  gray: "#4B515F",
  orange: "#FF7F66",
  orangeBorder: "#F3674C",
  orangeLight: "#FFEDEA",
  orangeLightHover: "#ff9b87",
  filterColor: "#787486",
  addButtonBackground: "#FF7F66",
  tableBorder: "#E5E7EB",
  tableColumnText: "#374151",
  tableRowSelectedBackgroundColor: "#FFEDEA",
  pagingSelected: "#F9FAFB",
  tableBlueColor: "#FF7F66",
  usersTable: {
    roles: {
      admin: {
        background: "#F2FAFE",
        text: "#1C64B9",
      },
      manager: {
        background: "#FEFBF2",
        text: "#B97A1C",
      },
      operator: {
        background: "#F0FDF4",
        text: "#15803D",
      },
      guest: {
        background: "#FDF4F0",
        text: "#803C15",
      },
    },
  },
  titleColor: "#111827",
  inputBorder: "#D1D5DB",
  loginButtonColor: "#232323",
  errorMessage: "#A30027",
  deleteBtnLabel: "#FA1100",
  inputPlaceholder: "#757575",
  saveButtonBackground: "#FF7F66",
  userPopoverItemColor: "#161616",
  userPopoverItemColor2: "#2F2F2F",
  userPopoverSeparator: "#B8C2D7",
  subnavigationText: "#9ea2c6",
  borderColor: "#abadc6",
  inputBorderColor: "#D0D5DD",
  disabledButtonBackground: "#C0C0C0",
  borderColorSecond: "#C0C0C0",
  labelTextColor: "#4B515F",
  distributionEmailsMail: "#6f6f6f",
  pageTitleColor: "#161616",
  primaryButtonDarkColor: "#FF7F66",
  primaryButtonLightColor: "#f5f5ff",
  primaryButtonTextColor: "#2e2e2e",
  tabUnselectedColor: "#475569",
  shadowColor: "#101828",
  emptyMeasuringInstrumentsPlaceholder: "#7e8391",
  secondBorderColor: "#424867",
  cardBackgroundColor: "#F0F0F0",
  cardBackgroundColorHover: "#DFDFDF",
  iconColor: "#292D32",
  newQuestionBackground: "#EFEFEF",
  unselectedOptionBackground: "#DCDCDC",
  selectedOptionBackground: "#161616",
  selectedOptionIcon: "#DCDCDC",
  placeholderColor: "#8f8f8f",
  templateCardBorder: "#7E7B8D",
  templateCardTitle: "#0d062d",
  toastSuccess: "#22C55E",
  toastError: "#A30027",
  errorBorderColor: "#FDA29B",
  errorInputBorderColor: "#FDA29B",
  deleteButtonText: "#d60000",
  imageUploadedBorder: "#8f9dce",
  imagePickerDescription: "#4A90E2",
  imagePickerAdditionalDesc: "#727272",
  linkColor: "#4867a5",
  tableLink: "#4070cf",
  sidebar: {
    hoveredIcon: "#f6f6fa",
    titleColor: "#C0C0C0",
    hoveredItem: "#424242",
    openedSubnavigationBackground: "#3A2622",
    activeItemBackground: "#EFEFEF",
    activeItemTitle: "#161616",
    iconActiveColor: "#3A2622",
    iconNotActiveColor: "#C0C0C0",
    background: "#161616",
  },
  findingStatus: {
    closed: {
      backgroundColor: "#FDF9F0",
      selectedBackgroundColor: "#BC7100",
      outsideTabkeBackgroundColor: "#F7F7F7",

      circleColor: "#D38715",
      selectedCircleColor: "#F1AD46",
      outsideTableCircleColor: "#F1AD46",

      textColor: "#D38715",
      outsideTableTextColor: "#161616",
      selectedTextColor: "#FFFFFF",

      borderColor: "transparent",
      outsideTableBorderColor: "#000000",
      selectedBorderColor: "#F1AD46",
    },
    inProgress: {
      backgroundColor: "#F2FAFE",
      selectedBackgroundColor: "#1C64B9",
      outsideTabkeBackgroundColor: "#F6F6F6",

      circleColor: "#1C64B9",
      selectedCircleColor: "#91C0F6",
      outsideTableCircleColor: "#91BFF6",

      textColor: "#1C64B9",
      selectedTextColor: "#FFFFFF",
      outsideTableTextColor: "#161616",

      borderColor: "transparent",
      outsideTableBorderColor: "#000000",
      selectedBorderColor: "#79AEEC",
    },
    completed: {
      backgroundColor: "#F0FDF4",
      selectedBackgroundColor: "#71B41C",
      outsideTabkeBackgroundColor: "#F6F6F6",

      circleColor: "#15803D",
      selectedCircleColor: "#C5F588",
      outsideTableCircleColor: "#7FE54F",

      textColor: "#15803D",
      selectedTextColor: "#FFFFFF",
      outsideTableTextColor: "#161616",

      borderColor: "transparent",
      outsideTableBorderColor: "#000000",
      selectedBorderColor: "#C5F588",
    },
    cancelled: {
      backgroundColor: "#FDF1F0",
      selectedBackgroundColor: "#D30D00",
      outsideTabkeBackgroundColor: "#F6F6F6",

      circleColor: "#D31515",
      selectedCircleColor: "#F15146",
      outsideTableCircleColor: "#F15146",

      textColor: "#D31515",
      selectedTextColor: "#FFFFFF",
      outsideTableTextColor: "#161616",

      borderColor: "transparent",
      outsideTableBorderColor: "#000000",
      selectedBorderColor: "#F15146",
    },
    disabled: {
      background: "#F6F6F6",
      textColor: "#969696",
      circleColor: "#969696",
    },
    separator: "#DFDFDF",
  },
  profilePictureBackground: "#D1D5DB",
  profilePictureText: "#475467",
  tabsSeparator: "#EBEDF0",
  questionSeparator: "#EAECF0",
  errorColor: "#FA1100",
  disabledFieldBackground: "#EFEFEF",
  disabledFieldText: "#252525",
  loader: "#64748B",
  deviceColor: "#4B515F",
  iconDisabledColor: "#C1C1C1",
  findingDateStateChanged: "#667085",
  findingSubjectChosenBg: "#FFE2DC",
  dropdownIcon: "#667085",
  dashboardTitleColor: "#ff7f66",
  dashboardHeaderColor: "#f6f6f9",
  dashboardRowColor: "#fcfcff",
  inputBorderLighterColor: "#F1F1F1",
  activeFormatButton: "#eeeeee",
  addMoreText: "#344054",
  findingTypeColor: "#F2FAFE",
  findingTypeTextColor: "#1C64B9",
  checkedRadioBackground: "#FEFBF2",
  checkedRadioColor: "#FF7F66",
  languageDropdownBorder: "#EAECF0",
  languageDropdownChosenBg: "#F6F6FA",
  languageDropdownHoverBg: "#F0F0F6",
};
