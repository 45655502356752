import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";
import { attemptFetchTranslations } from "../../request/translationsRequest";
import {
  addTranslationsResource,
  fetchTranslationsResourceError,
  fetchTranslationsResourceSuccess,
  setCurrentLanguage,
} from "../actions/translations/translationsActions";
import { GET_TRANSLATIONS_FETCH } from "../actions/translations/translationsActionConstants";
import i18next from "i18next";
import { selectTranslatedResources } from "../selectors/translationsSelectors";
import {
  LANGUAGES,
  findLanguageByCulture,
} from "../../constants/languageConstants";

function* fetchTranslationsResource({ payload }) {
  try {
    let culture = payload?.culture;
    if (!findLanguageByCulture(culture)) {
      culture = LANGUAGES?.[0]?.culture;
      yield put(setCurrentLanguage(culture));
    }
    const translatedResources = yield select(selectTranslatedResources);

    if (!translatedResources?.includes?.(culture)) {
      const { data } = yield call(attemptFetchTranslations, culture);
      yield call(
        i18next.addResourceBundle,
        culture,
        "translation",
        { ...data?.resources },
        true,
        true
      );
      yield put(addTranslationsResource(culture));
    }
    i18next.changeLanguage(culture);
    yield put(setCurrentLanguage(culture));

    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchTranslationsResourceSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchTranslationsResourceError(errorMessage));
  }
}
export default function* translationsSaga() {
  yield all([takeLatest(GET_TRANSLATIONS_FETCH, fetchTranslationsResource)]);
}
