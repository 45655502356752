import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router";
import { BASE_PAGE, LOGIN_PAGE } from "../../constants/pages";
import useAuth from "../../hooks/useAuth";

const AuthRoute = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isLoggedIn, roles, hasRole } = useAuth();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    if (props?.roles?.length === 0) {
      setAuthorized(true);
      setIsLoaded(true);
      return;
    }
    if (
      props?.roles?.reduce?.((prevValue, currentValue) => {
        if (prevValue === true) return prevValue;
        if (hasRole(currentValue)) return true;
        return false;
      }, false)
    )
      setAuthorized(true);
    else setAuthorized(false);
    setIsLoaded(true);
  }, [isLoggedIn, roles, props?.roles]);

  return isLoaded ? (
    isLoggedIn ? (
      authorized ? (
        <Route {...props} />
      ) : (
        <Redirect to={BASE_PAGE} />
      )
    ) : (
      <Redirect to={LOGIN_PAGE} />
    )
  ) : (
    <></>
  );
};

AuthRoute.propTypes = {
  roles: PropTypes.array,
};

export default AuthRoute;
