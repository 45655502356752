import {
  CLOSE_LOADING_MODAL,
  CLOSE_DELETE_MODAL,
  CLOSE_MODAL,
  TOGGLE_DISTRIBUTION_EMAILS_MODAL,
  TOGGLE_IMAGE_PREVIEW_MODAL,
  TOGGLE_TABLE_PREVIEW_MODAL,
  TOGGLE_SET_DELETE_MODAL,
  TOGGLE_SET_LOADING_MODAL,
  TOGGLE_SET_MEASURING_INSTRUMENT_MODAL,
  TOGGLE_SET_PASSWORD_MODAL,
  TOGGLE_SET_STANDARD_MODAL,
  TOGGLE_CONFIRM_MODAL,
  TOGGLE_SET_SCANNING_OBJECT_MODAL,
  CLOSE_USER_MODAL,
  TOGGLE_SET_USER_MODAL,
  TOGGLE_SEND_MAILS_MODAL,
  TOGGLE_TABLE_MODAL,
  TOGGLE_COPY_FINDING_MODAL,
  TOGGLE_RENAME_SCANNING_SUBJECT_MODAL,
  TOGGLE_COLOR_PICKER_MODAL,
  TOGGLE_RENAME_COLUMNS_MODAL,
  TOGGLE_ADD_FINDING_STAFF_MODAL,
  TOGGLE_SET_FINDING_TYPES_MODAL,
  CLOSE_FINDING_TYPES_MODAL,
  TOGGLE_SET_ATTENDED_PERSON_MODAL,
  TOGGLE_FINDING_PREVIEW_MODAL,
  TOGGLE_SET_FINDING_TYPE_MODAL,
  TOGGLE_LICENSE_MODAL,
  TOGGLE_DOCUMENT_TYPE_MODAL,
} from "./modalActionConstants";

export const toggleSetPasswordModal = (payload) => ({
  type: TOGGLE_SET_PASSWORD_MODAL,
  payload,
});
export const toggleSetStandardModal = (payload) => ({
  type: TOGGLE_SET_STANDARD_MODAL,
  payload,
});
export const toggleSetFindingTypeModal = (payload) => ({
  type: TOGGLE_SET_FINDING_TYPE_MODAL,
  payload,
});
export const toggleSetDeleteModal = (payload) => ({
  type: TOGGLE_SET_DELETE_MODAL,
  payload,
});
export const toggleDocumentTypeModal = (payload) => ({
  type: TOGGLE_DOCUMENT_TYPE_MODAL,
  payload,
});
export const toggleSetLoadingModal = (payload) => ({
  type: TOGGLE_SET_LOADING_MODAL,
  payload,
});
export const toggleDistributionEmailsModal = (payload) => ({
  type: TOGGLE_DISTRIBUTION_EMAILS_MODAL,
  payload,
});
export const toggleSetMeasuringInstrumentModal = (payload) => ({
  type: TOGGLE_SET_MEASURING_INSTRUMENT_MODAL,
  payload,
});
export const toggleImagePreviewModal = (payload) => ({
  type: TOGGLE_IMAGE_PREVIEW_MODAL,
  payload,
});
export const toggleTablePreviewModal = (payload) => ({
  type: TOGGLE_TABLE_PREVIEW_MODAL,
  payload,
});
export const toggleFindingPreviewModal = (payload) => ({
  type: TOGGLE_FINDING_PREVIEW_MODAL,
  payload,
});
export const toggleConfirmModal = (payload) => ({
  type: TOGGLE_CONFIRM_MODAL,
  payload,
});
export const toggleRenameColumnsModal = (payload) => ({
  type: TOGGLE_RENAME_COLUMNS_MODAL,
  payload,
});
export const toggleLicenseModal = (payload) => ({
  type: TOGGLE_LICENSE_MODAL,
  payload,
});
export const toggleColorPickerModal = (payload) => ({
  type: TOGGLE_COLOR_PICKER_MODAL,
  payload,
});
export const toggleScanningObjectModal = (payload) => ({
  type: TOGGLE_SET_SCANNING_OBJECT_MODAL,
  payload,
});
export const toggleSendMailsModal = (payload) => ({
  type: TOGGLE_SEND_MAILS_MODAL,
  payload,
});
export const toggleTableModal = (payload) => ({
  type: TOGGLE_TABLE_MODAL,
  payload,
});
export const toggleCopyFindingModal = (payload) => ({
  type: TOGGLE_COPY_FINDING_MODAL,
  payload,
});
export const toggleRenameScanningSubjectModal = (payload) => ({
  type: TOGGLE_RENAME_SCANNING_SUBJECT_MODAL,
  payload,
});
export const toggleAddFindingStaffModal = (payload) => ({
  type: TOGGLE_ADD_FINDING_STAFF_MODAL,
  payload,
});

export const closeDeleteModal = () => ({
  type: CLOSE_DELETE_MODAL,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});
export const closeLoadingModal = () => ({
  type: CLOSE_LOADING_MODAL,
});

export const toggleSetUserModal = (payload) => ({
  type: TOGGLE_SET_USER_MODAL,
  payload,
});

export const closeUserModal = () => ({
  type: CLOSE_USER_MODAL,
});

export const toggleSetFindingTypesModal = (payload) => ({
  type: TOGGLE_SET_FINDING_TYPES_MODAL,
  payload,
});

export const closeFindingTypesModal = () => ({
  type: CLOSE_FINDING_TYPES_MODAL,
});

export const toggleSetAttendedPersonModal = (payload) => ({
  type: TOGGLE_SET_ATTENDED_PERSON_MODAL,
  payload,
});
