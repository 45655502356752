import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ErrorMessage,
  InputContainer,
  InputField,
  InputRightElement,
} from "../../../Login/LoginForm/LoginForm.styled";
import { setPasswordFormikConstants } from "../../../../initialValues/setPasswordInitialValues";
import EyeStrikedIcon from "../../../Icon/Icons/EyeStriked";
import EyeIcon from "../../../Icon/Icons/Eye";
import { useTranslation } from "react-i18next";
import { InputLabel } from "../../../Field/TextInputField/TextInputField.styled";

const PasswordField = (props) => {
  const { t } = useTranslation();
  const formik = props?.formik;
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputContainer>
      <InputLabel
        $required={!formik?.values?.[setPasswordFormikConstants.password]}
      >
        {t("changePassword.newPasswordLabel")}
      </InputLabel>
      <InputField
        id={setPasswordFormikConstants.password}
        type={!showPassword && "password"}
        value={formik?.values?.[setPasswordFormikConstants.password]}
        onChange={formik?.handleChange}
        placeholder={t("changePassword.newPassword")}
      />
      {formik?.errors?.[setPasswordFormikConstants.password] &&
        formik?.touched?.[setPasswordFormikConstants.password] && (
          <ErrorMessage>
            {t(formik?.errors?.[setPasswordFormikConstants.password])}
          </ErrorMessage>
        )}
      <InputRightElement
        onClick={() => setShowPassword((prevValue) => !prevValue)}
        style={{ top: "37px" }}
      >
        {showPassword ? <EyeStrikedIcon /> : <EyeIcon />}
      </InputRightElement>
    </InputContainer>
  );
};

PasswordField.propTypes = {
  formik: PropTypes.node,
};

export default PasswordField;
