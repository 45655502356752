import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useAuth from "../../../hooks/useAuth";
import { MANAGER_ROLE } from "../../../constants/rolesConstants";
import documentTypesTableConstants from "../../../constants/tableConstants/documentTypesTableConstants";
import { formatDateTime } from "../../../util/helpers/dateHelpers";
import { makeToastMessage } from "../../../store/utils/makeToastMessage";
import { APPLICATION_TOAST_CONTAINER_ID } from "../../../constants/toastConstants";
import {
  closeModal,
  toggleDocumentTypeModal,
} from "../../../store/actions/modal/modalActions";
import Table from "../../Table/Table";
import {
  selectDocumentTypesData,
  selectTotalDocumentTypes,
} from "../../../store/selectors/documentTypesSelectors";
import {
  clearDocumentTypes,
  fetchCreateDocumentType,
  fetchDocumentTypes,
  fetchEditDocumentType,
} from "../../../store/actions/documentTypes/documentTypesActions";
import TextRenderer from "../../Table/Renderers/TextRenderer/TextRenderer";

const DocumentTypesTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const { hasRole } = useAuth();

  const hideAddAndUpdate = useMemo(() => {
    return !hasRole(MANAGER_ROLE);
  }, [hasRole]);

  const tableColumns = useMemo(() => {
    let columnsObject = Object.keys(documentTypesTableConstants).map(
      (property) => {
        return {
          ...documentTypesTableConstants[property],
          field: t(documentTypesTableConstants[property].i18key),
          propertyName: property,
          backendProperty:
            documentTypesTableConstants[property]?.backendProperty || property,
          style: documentTypesTableConstants[property]?.style,
          renderer:
            documentTypesTableConstants[property]?.renderer || TextRenderer,
          rendererProps: { hideChangePassword: true },
        };
      }
    );
    return columnsObject;
  }, [documentTypesTableConstants, t, hideAddAndUpdate]);

  const mapDataFunction = useCallback((newData) => {
    return newData.map((singleData) => {
      let mappedDataObject = {
        caption: singleData.caption,
        description: singleData.description,
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
        actions: {
          id: singleData?.id,
          caption: singleData?.caption,
          description: singleData?.description,
          hideUpdate: hideAddAndUpdate,
          handleApiResponseSuccess,
          refetchTable,
        },
      };
      return mappedDataObject;
    });
  }, []);

  const refetchTable = () => {
    tableRef?.current?.forceRefetch();
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleDocumentTypeModal({
        title: t("documentTypes.modal.newDocumentType"),
        handleSubmit: (values) =>
          dispatch(
            fetchCreateDocumentType({ ...values, handleApiResponseSuccess })
          ),
      })
    );
  };

  const handleApiResponseSuccess = (data, isEditing = false) => {
    refetchTable();
    const titleI18Key = data?.isDeleted
      ? "toast.success.deletedTitle"
      : isEditing
      ? "toast.success.editedTitle"
      : "toast.success.createdTitle";
    const descriptionI18Key = data?.isDeleted
      ? "toast.success.deletedDescription"
      : isEditing
      ? "toast.success.editedDescription"
      : "toast.success.createdDescription";
    dispatch(closeModal());
    makeToastMessage(
      {
        title: t(titleI18Key, {
          typeOfData: t("documentTypes.typeOfData"),
        }),
        description: t(descriptionI18Key, {
          name: data.caption,
          typeOfData: t("documentTypes.typeOfDataPlural").toLowerCase(),
        }),
      },
      {
        containerId: APPLICATION_TOAST_CONTAINER_ID,
      }
    );
  };

  const handleRedirect = (values) => {
    dispatch(
      toggleDocumentTypeModal({
        title: t("documentTypes.modal.editDocumentType"),
        prefilledData: {
          caption: values?.prefilledData?.caption,
          description: values?.prefilledData?.description,
        },
        isEditing: true,
        rank: 1,
        id: values?.prefilledData?.id,
        handleSubmit: (valuesToSend) =>
          dispatch(
            fetchEditDocumentType({
              ...valuesToSend,
              id: values?.prefilledData?.id,
              handleApiResponseSuccess,
            })
          ),
        tableRef,
      })
    );
  };

  return (
    <Table
      ref={tableRef}
      tableColumns={tableColumns}
      hideAddButton={hideAddAndUpdate}
      searchPlaceholder={t("documentTypes.table.searchPlaceholder")}
      dataSelector={selectDocumentTypesData}
      totalDataSelector={selectTotalDocumentTypes}
      dispatchFunction={fetchDocumentTypes}
      mapDataFunction={mapDataFunction}
      redirectFunction={handleRedirect}
      handleClickAddButton={handleClickAddButton}
      clearDispatchFunction={clearDocumentTypes}
    />
  );
};

DocumentTypesTable.propTypes = {
  children: PropTypes.node,
};

export default DocumentTypesTable;
