import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import companyUsersTableConstants from "../../../../constants/tableConstants/companyUsersTableConstants";
import TextRenderer from "../../../Table/Renderers/TextRenderer/TextRenderer";
import { formatDateTime } from "../../../../util/helpers/dateHelpers";
import { findRoleByRoleName } from "../../../../constants/rolesConstants";
import {
  EDIT_COMPANY_USER,
  NEW_COMPANY_USER,
} from "../../../../constants/pages";
import Table from "../../../Table/Table";
import {
  fetchUsers,
  fetchUsersClear,
} from "../../../../store/actions/user/userActions";
import {
  selectTotalUsers,
  selectUsersData,
} from "../../../../store/selectors/usersSelectors";
import { replaceInRoute } from "../../../../util/helpers/routeHelpers";
import history from "../../../../store/utils/history";

const CompanyUsersTable = (props) => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const location = useLocation();

  const tableColumns = useMemo(() => {
    history?.replace({
      state: { ...history?.location?.state, isInUsers: true },
    });
    return Object.keys(companyUsersTableConstants).map((property) => ({
      ...companyUsersTableConstants[property],
      field: t(companyUsersTableConstants[property].i18key),
      backendProperty:
        companyUsersTableConstants[property]?.backendProperty || property,
      propertyName: property,
      style: companyUsersTableConstants[property]?.style,
      renderer: companyUsersTableConstants[property]?.renderer || TextRenderer,
    }));
  }, [companyUsersTableConstants, t]);

  const mapDataFunction = useCallback(
    (newData) =>
      newData?.map?.((singleData) => ({
        users: `${singleData?.firstName} ${singleData?.lastName}`,
        email: singleData?.email || "Not provided",
        status: singleData?.blocked
          ? t("users.table.status.inactive")
          : t("users.table.status.active"),
        lastLogin:
          new Date(singleData?.lastLoginDate)?.getFullYear() < 2000
            ? ""
            : formatDateTime(singleData?.lastLoginDate),
        role: singleData?.roles?.map((item) => findRoleByRoleName(item)),
        lastChanges: {
          date: formatDateTime(
            singleData?.updatedAtUtc || new Date().toString()
          ),
          author: `${singleData?.updatedByUser?.firstName || ""} ${
            singleData?.updatedByUser?.lastName || ""
          }`,
        },
        id: singleData?.id,
        actions: {
          firstName: singleData?.firstName,
          lastName: singleData?.lastName,
          blocked: singleData?.blocked,
          companyId: props?.companyId,
          email: singleData?.email || "Not provided",
          role: singleData?.roles?.map((item) => findRoleByRoleName(item)),
          lastLogin: formatDateTime(singleData?.lastLoginDate),
          userId: singleData?.id,
          routeState: {
            page: tableRef?.current?.page,
            searchValue: tableRef?.current?.searchValue,
          },
          totalData: newData?.length,
          tableRef,
          t,
          isActiveTab: true,
        },
      })),
    []
  );
  const redirectHrefFunction = (userId) => {
    return {
      pathname: replaceInRoute(EDIT_COMPANY_USER, {
        companyId: props?.companyId,
        userId: userId,
      }),
      state: {
        page: tableRef?.current?.page,
        searchValue: tableRef?.current?.searchValue,
      },
    };
  };
  return (
    <Table
      custom={!props?.isEditing}
      tableColumns={tableColumns}
      smallerTextForMobile
      redirectFunction={props?.handleClickAddButton}
      redirectHrefFunction={redirectHrefFunction}
      searchPlaceholder={t("users.searchPlaceholder")}
      handleClickAddButton={props?.handleClickAddButton}
      dispatchFunction={(dispatchProps) =>
        fetchUsers({ ...dispatchProps, companyId: props?.companyId })
      }
      initialPage={location?.state?.page}
      addButtonHref={{
        pathname: replaceInRoute(NEW_COMPANY_USER, {
          companyId: props?.companyId || "new",
        }),
        state: {
          wasCompanyCreated: props?.isEditing,
        },
      }}
      initialSearchValue={location?.state?.searchValue}
      dataSelector={selectUsersData}
      totalDataSelector={selectTotalUsers}
      mapDataFunction={mapDataFunction}
      clearDispatchFunction={fetchUsersClear}
      ref={tableRef}
    />
  );
};

CompanyUsersTable.propTypes = {
  children: PropTypes.node,
  handleClickAddButton: PropTypes.func,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEditing: PropTypes.bool,
};

export default CompanyUsersTable;
