import { createClearType, createSetType } from "../actionHelpers";

export const TOGGLE_SET_PASSWORD_MODAL = createSetType(
  "TOGGLE_SET_PASSWORD_MODAL"
);
export const TOGGLE_SET_STANDARD_MODAL = createSetType(
  "TOGGLE_SET_STANDARD_MODAL"
);
export const TOGGLE_SET_FINDING_TYPE_MODAL = createSetType(
  "TOGGLE_SET_FINDING_TYPE_MODAL"
);
export const TOGGLE_SET_DELETE_MODAL = createSetType("TOGGLE_SET_DELETE_MODAL");
export const TOGGLE_COLOR_PICKER_MODAL = createSetType(
  "TOGGLE_COLOR_PICKER_MODAL"
);
export const TOGGLE_SET_MEASURING_INSTRUMENT_MODAL = createSetType(
  "TOGGLE_SET_MEASURING_INSTRUMENT_MODAL"
);
export const TOGGLE_DISTRIBUTION_EMAILS_MODAL = createSetType(
  "TOGGLE_DISTRIBUTION_EMAILS_MODAL"
);
export const TOGGLE_IMAGE_PREVIEW_MODAL = createSetType(
  "TOGGLE_IMAGE_PREVIEW_MODAL"
);
export const TOGGLE_DOCUMENT_TYPE_MODAL = createSetType(
  "TOGGLE_DOCUMENT_TYPE_MODAL"
);
export const TOGGLE_TABLE_PREVIEW_MODAL = createSetType(
  "TOGGLE_TABLE_PREVIEW_MODAL"
);
export const TOGGLE_FINDING_PREVIEW_MODAL = createSetType(
  "TOGGLE_FINDING_PREVIEW_MODAL"
);
export const TOGGLE_RENAME_COLUMNS_MODAL = createSetType(
  "TOGGLE_RENAME_COLUMNS_MODAL"
);
export const TOGGLE_LICENSE_MODAL = createSetType(
  "TOGGLE_LICENSE_MODAL"
);
export const SET_MODAL_TYPE = createSetType("SET_MODAL_TYPE");
export const CLOSE_DELETE_MODAL = createSetType("CLOSE_DELETE_MODAL");
export const CLOSE_MODAL = createClearType("CLOSE_MODAL");

export const TOGGLE_SET_LOADING_MODAL = createSetType(
  "TOGGLE_SET_LOADING_MODAL"
);
export const TOGGLE_CONFIRM_MODAL = createSetType("TOGGLE_CONFIRM_MODAL");

export const TOGGLE_SET_SCANNING_OBJECT_MODAL = createSetType(
  "TOGGLE_SET_SCANNING_OBJECT_MODAL"
);
export const TOGGLE_SEND_MAILS_MODAL = createSetType("TOGGLE_SEND_MAILS_MODAL");
export const TOGGLE_TABLE_MODAL = createSetType("TOGGLE_TABLE_MODAL");
export const TOGGLE_COPY_FINDING_MODAL = createSetType(
  "TOGGLE_COPY_FINDING_MODAL"
);
export const TOGGLE_RENAME_SCANNING_SUBJECT_MODAL = createSetType(
  "TOGGLE_RENAME_SCANNING_SUBJECT_MODAL"
);
export const TOGGLE_ADD_FINDING_STAFF_MODAL = createSetType(
  "TOGGLE_ADD_FINDING_STAFF_MODAL"
);

export const CLOSE_LOADING_MODAL = createClearType("CLOSE_LOADING_MODAL");

export const TOGGLE_SET_USER_MODAL = createSetType("TOGGLE_SET_USER_MODAL");
export const CLOSE_USER_MODAL = createSetType("CLOSE_USER_MODAL");

export const TOGGLE_SET_FINDING_TYPES_MODAL = createSetType(
  "TOGGLE_SET_FINDING_TYPES_MODAL"
);
export const CLOSE_FINDING_TYPES_MODAL = createSetType(
  "CLOSE_FINDING_TYPES_MODAL"
);

export const TOGGLE_SET_ATTENDED_PERSON_MODAL = createSetType(
  "TOGGLE_SET_ATTENDED_PERSON_MODAL"
);
