import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { findingFormikConstants } from "../../../../../../initialValues/findingInitialValues";
import { selectClientsData } from "../../../../../../store/selectors/clientsSelector";
import { useTranslation } from "react-i18next";
import {
  selectSingleFindingSettings,
  selectSingleFindingSettingsOld,
} from "../../../../../../store/selectors/findingsSelectors";
import AutocompleteField from "../../../../../Field/AutocompleteField/AutocompleteField";
import { useState } from "react";

const ClientField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const clients = useSelector(selectClientsData);
  const [isDeleted, setIsDeleted] = useState(false);
  const findingSettingsOld = useSelector(selectSingleFindingSettingsOld);
  const findingSettings = useSelector(selectSingleFindingSettings);

  // const clientName = useMemo(() => {
  //   return findingSettings?.client?.name;
  // }, [findingSettings]);

  const value = useMemo(() => {
    let clientToReturn = clients.find(
      (client) =>
        client?.id == formik?.values?.[findingFormikConstants.clientId]?.value
    );
    if (!clientToReturn && findingSettings?.client && !isDeleted)
      clientToReturn = findingSettings?.client;
    return clientToReturn;
  }, [formik?.values, clients, isDeleted]);

  const handleChange = (value) => {
    setIsDeleted(true);
    formik?.setFieldValue?.(findingFormikConstants.clientId, {
      edited: findingSettingsOld?.clientId?.value !== value,
      value: value?.id,
    });
  };

  return (
    <AutocompleteField
      options={clients}
      label={t("findings.form.client.placeholder")}
      disabled={props?.disabled}
      title={t("findings.form.client.title")}
      required={formik?.values?.[findingFormikConstants.clientId].value == null}
      onChange={handleChange}
      dropdownLabel={["name"]}
      value={value}
      error={
        formik?.touched?.[findingFormikConstants.clientId]?.value &&
        formik?.errors?.[findingFormikConstants.clientId]?.value
      }
    />
  );
};

ClientField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
  stateId: PropTypes.number,
};

export default ClientField;
