import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../themes";

export const TableContentContainer = styled(Box)`
  border: 1px solid ${selectedTheme.colors.tableBorder};
  border-radius: 6px;
  position: relative;
  ${(props) =>
    props?.isSticky &&
    css`
      display: flex;
      flex-direction: row;
    `}
`;

export const MainTableContainer = styled(Box)`
  ${(props) =>
    props?.isSticky &&
    css`
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;
      max-width: calc(100vw - ${props?.isSidebarOpened ? "448px" : "263px"});
    `}
`;

export const StickyTableContainer = styled(Box)`
  border-left: 1px solid ${selectedTheme.colors.tableBorder};
  background-color: ${selectedTheme.colors.dashboardRowColor};
`;
