// import { addCRUDInfo } from "../../store/utils/deleteCRUDInfo";
// import { convertDateToISO } from "../helpers/dateHelpers";
import _ from "lodash";
import { addCRUDInfo } from "../../store/utils/deleteCRUDInfo";
import { formatDate } from "../helpers/dateHelpers";
import { createHtmlString } from "../helpers/htmlHelpers";
import questionTypeConstants from "../../constants/questionTypeConstants";
// import questionTypeConstants from "../../constants/questionTypeConstants";
// import { addCRUDInfo } from "../../store/utils/deleteCRUDInfo";

const findingEditableProperties = [
  "location",
  "facility",
  "description",
  "staffId",
  "clientId",
  "closingDate",
  "date",
  "conclusion",
  "answer",
  "images",
  "isInRange",
  "note",
];

// export const mapForEditing = (obj) => {
//   if (Array.isArray(obj)) {
//     const sortedArray = _.sortBy(obj, "id");
//     return sortedArray.map((item) => {
//       return { value: mapForEditing(item), edited: false };
//     });
//   } else if (typeof obj !== "object" || obj === null) {
//     return obj;
//   }
//   return Object.keys(obj).reduce((acc, key) => {
//     acc[key] = { value: mapForEditing(obj[key]), edited: false };
//     return acc;
//   }, {});
// };

// export const reverseEditing = (editedObj) => {
//   if (Array.isArray(editedObj)) {
//     return editedObj.map((item) => reverseEditing(item.value));
//   } else if (typeof editedObj !== "object" || editedObj === null) {
//     return editedObj;
//   }
//   return Object.keys(editedObj).reduce((acc, key) => {
//     acc[key] = reverseEditing(editedObj[key]?.value);
//     return acc;
//   }, {});
// };

// export function compareObjects(obj1, obj2, path = "", result = []) {
//   if (obj1 == null || obj2 == null) return result;
//   for (let key in obj2) {
//     const currentPath = path ? `${path}/${key}` : `/${key}`;
//     if (obj2[key]?.edited) {
//       if (
//         typeof obj2[key]?.value === "object" &&
//         !Array.isArray(obj2[key]?.value) &&
//         obj2[key]?.value !== null
//       ) {
//         //pretvorimo obj2[key].value u normalan objekat
//         result.push({
//           path: currentPath,
//           op: "replace",
//           value: reverseEditing(obj2[key].value),
//         });
//       } else if (Array.isArray(obj2[key]?.value)) {
//         if (obj1[key] && Array.isArray(obj1[key]?.value)) {
//           if (obj2[key]?.value?.length > obj1[key]?.value?.length) {
//             for (
//               let i = obj1[key]?.value?.length;
//               i < obj2[key]?.value?.length;
//               i++
//             ) {
//               if (obj2[key]?.value[i].value !== null) {
//                 result.push({
//                   path: `${currentPath}/-`,
//                   op: "add",
//                   value: reverseEditing(obj2[key].value[i].value),
//                 });
//               }
//             }
//           }
//           for (let i = 0; i < obj1[key]?.value?.length; i++) {
//             if (obj2[key]?.value[i]?.edited) {
//               if (obj2[key]?.value[i]?.value !== null)
//                 result.push({
//                   path: `${currentPath}/${i}`,
//                   op: "replace",
//                   value: reverseEditing(obj2[key].value[i].value),
//                 });
//               else
//                 result.push({
//                   path: `${currentPath}/${i}`,
//                   op: "delete",
//                 });
//             }
//             compareObjects(
//               obj1[key]?.value[i]?.value,
//               obj2[key]?.value[i]?.value,
//               `${currentPath}/${i}`,
//               result
//             );
//           }
//         } else {
//           result.push({
//             path: currentPath,
//             op: "replace",
//             value: reverseEditing(obj2[key].value),
//           });
//         }
//       } else {
//         if (obj2[key]?.value === null && obj1[key]?.value !== null)
//           result.push({
//             path: currentPath,
//             op: "delete",
//           });
//         else
//           result.push({
//             path: currentPath,
//             op: "replace",
//             value: reverseEditing(obj2[key]?.value),
//           });
//       }
//     } else {
//       if (typeof obj2[key]?.value === "object" && obj2[key]?.value !== null)
//         compareObjects(obj1[key]?.value, obj2[key]?.value, currentPath, result);
//       else if (Array.isArray(obj2[key]?.value)) {
//         obj2[key]?.value.forEach((item, index) => {
//           compareObjects(
//             obj1[key]?.value[index]?.value,
//             item?.value,
//             `${currentPath}/${index}`,
//             result
//           );
//         });
//       }
//     }
//   }
//   return result;
// }

// export const settingsMapper = (formValues, staffs, clients) => {
//   let formObj = {
//     ...formValues,
//     date: {
//       edited: formvalues?.date.edited,
//       value: formvalues?.date.value
//         ? convertDateToISO(formvalues?.date.value)
//         : formvalues?.date.value,
//     },
//     closingDate: {
//       edited: formvalues?.closingDate.edited,
//       value: formvalues?.closingDate.value
//         ? convertDateToISO(formvalues?.closingDate.value)
//         : formvalues?.closingDate.value,
//     },
//   };

//   if (formvalues?.staffId.edited) {
//     const staffObj = {
//       edited: true,
//       value: mapForEditing(
//         staffs.find((entity) => entity.id === formvalues?.staffId.value)
//       ),
//     };
//     formObj = { ...formObj, staff: staffObj };
//   }
//   if (formvalues?.clientId.edited) {
//     const clientObj = {
//       edited: true,
//       value: mapForEditing(
//         clients.find((entity) => entity.id === formvalues?.clientId.value)
//       ),
//     };
//     formObj = { ...formObj, client: clientObj };
//   }
//   return formObj;
// };
export const addEditFlag = (value, isHtml = false) => ({
  value: isHtml ? createHtmlString(value) : value,
  edited: false,
});
const removeEditFlag = (object) => {
  // debugger; //eslint-disable-line
  if (
    object?.value != null &&
    Array.isArray(object?.value) &&
    typeof object?.value?.getMonth !== "function"
  )
    return object?.value;
  if (
    typeof object?.value === "object" &&
    object?.value != null &&
    typeof object?.value?.getMonth !== "function" // Checking if object is date
  ) {
    return Object.keys(object?.value)?.reduce((acc, val) => {
      return {
        ...acc,
        [val]: removeEditFlag(object?.value[val]),
      };
    }, {});
  }
  let objectProperties =
    typeof object === "object" && object != null ? Object.keys(object) : object;
  return objectProperties?.includes?.("edited") ||
    objectProperties?.includes?.("added") ||
    objectProperties?.includes?.("removed")
    ? object?.value
    : object;
};

// For example there are 5 elements in array in which 2 elements have flag "removed"
// This function finds index of element in array of existing elements, without removed flag
export const findExistingIndex = (array, realIndex) => {
  let existingIndex = -1;
  for (let i = 0; i < array?.length; i++) {
    if (!array?.[i]?.removed) existingIndex++;
    if (i === realIndex) break;
  }
  return existingIndex;
};

export const mapFindingSubjectForFE = (findingSubject) => {
  let newFindingSubject = {
    ...findingSubject,
    conclusion: addEditFlag(findingSubject?.conclusion),
    orderNumber: addEditFlag(findingSubject?.orderNumber),
    specificName: addEditFlag(findingSubject?.specificName),
    positiveConclusionTemplate: findingSubject?.positiveConclusionTemplate,
    negativeConclusionTemplate: findingSubject?.negativeConclusionTemplate,
    sections: findingSubject?.sections?.map?.((singleSection) => ({
      ...singleSection,
      note: addEditFlag(singleSection?.note),
      items: singleSection?.items?.map?.((singleItem) => ({
        ...singleItem,
        answer: addEditFlag(singleItem?.answer),
        columns: {
          edited: false,
          value: singleItem?.columns?.map?.((singleColumn) => ({
            ...singleColumn,
            id:
              singleColumn?.id === 0 || singleColumn?.id == null
                ? Math.random() * Date.now()
                : singleColumn?.id,
          })),
        },
        descriptionOne: addEditFlag(singleItem?.descriptionOne),
        descriptionTwo: addEditFlag(singleItem?.descriptionTwo),
        isInRange: addEditFlag(singleItem?.isInRange),
        images: singleItem?.images?.map?.((singleImage) =>
          addEditFlag(singleImage)
        ),
        // images: addEditFlag(singleItem?.images),
      })),
    })),
  };
  newFindingSubject = {
    ...newFindingSubject,
    sections: newFindingSubject?.sections?.map?.((singleSection) => ({
      ...singleSection,
      items: singleSection?.items?.map?.((singleItem) => ({
        ...singleItem,
        columns: {
          edited: false,
          value: singleItem?.columns?.value?.map?.((singleColumn) => ({
            ...singleColumn,
            columnId: singleColumn?.id,
          })),
        },
        columnAnswers: (() => {
          if (singleItem?.itemTypeId !== questionTypeConstants.TABLE) return [];
          let numOfRows = Math.max(
            ...singleItem?.columns?.value?.reduce?.(
              (acc, singleColumn) => [
                ...acc,
                ...singleColumn?.answers?.map?.(
                  (singleAnswer) => singleAnswer?.rowIndex + 1
                ),
              ],
              [0]
            )
          );
          let arrayToReturn = [];
          for (let index = 0; index < numOfRows; index++) {
            arrayToReturn.push(
              singleItem?.columns?.value?.reduce?.((acc, singleColumn) => {
                return [
                  ...acc,
                  {
                    columnId: singleColumn?.id,
                    // added: numOfRows === 0,
                    answer:
                      singleColumn?.answers?.find?.(
                        (singleAnswer) => singleAnswer?.rowIndex === index
                      )?.answer || "",
                  },
                ];
              }, [])
            );
          }
          return arrayToReturn;
        })(),
      })),
    })),
  };
  return newFindingSubject;
};

export const mapFindingObjectForFE = (findingObject) => {
  return Object.keys(findingObject)?.reduce((acc, key) => {
    if (findingEditableProperties?.includes(key))
      return { ...acc, [key]: addEditFlag(findingObject[key]) };
    if (key === "staffs")
      return {
        ...acc,
        [key]: findingObject[key]?.map?.((singleStaff) =>
          addEditFlag(singleStaff)
        ),
      };
    if (key === "findingAttendees")
      return {
        ...acc,
        [key]: findingObject[key]?.map?.((singleData) =>
          addEditFlag({
            ...singleData,
            firstName: addEditFlag(singleData?.firstName),
            lastName: addEditFlag(singleData?.lastName),
            title: addEditFlag(singleData?.title),
          })
        ),
      };
    if (key === "workspaceSchemas")
      return {
        ...acc,
        [key]: findingObject[key]?.map?.((singleSchema) =>
          addEditFlag({
            ...singleSchema,
            imageUrl: addEditFlag(singleSchema?.imageUrl),
          })
        ),
      };
    if (key === "findingSubjects") {
      return {
        ...acc,
        [key]: findingObject[key]?.map(
          (singleSubject) => mapFindingSubjectForFE(singleSubject)
          // {
          //   return {
          //     ...singleSubject,
          //     conclusion: addEditFlag(singleSubject?.conclusion),
          //     orderNumber: addEditFlag(singleSubject?.orderNumber),
          //     positiveConclusionTemplate:
          //       singleSubject?.positiveConclusionTemplate,
          //     negativeConclusionTemplate:
          //       singleSubject?.negativeConclusionTemplate,
          //     sections: singleSubject?.sections?.map?.((singleSection) => ({
          //       ...singleSection,
          //       note: addEditFlag(singleSection?.note),
          //       items: singleSection?.items?.map?.((singleItem) => ({
          //         ...singleItem,
          //         answer: addEditFlag(singleItem?.answer),
          //         isInRange: addEditFlag(singleItem?.isInRange),
          //         images: singleItem?.images?.map?.((singleImage) =>
          //           addEditFlag(singleImage)
          //         ),
          //         // images: addEditFlag(singleItem?.images),
          //       })),
          //     })),
          //   };
          // }
        ),
      };
    }
    return {
      ...acc,
      [key]: findingObject[key],
    };
  }, {});
};

const getFindingSubjectIndexFromPath = (findingSubjectPath) => {
  let pathArray = findingSubjectPath?.split("/");
  let subjectIndex = pathArray?.findIndex(
    (singlePath) => singlePath === "findingSubjects"
  );
  if (subjectIndex === -1 || pathArray?.[subjectIndex + 1] === "-") return -1;
  return Number(pathArray?.[subjectIndex + 1]);
};

const getStaffIndexFromPath = (staffPath) => {
  let pathArray = staffPath?.split("/");
  let staffIndex = pathArray?.findIndex(
    (singlePath) => singlePath === "staffs"
  );
  if (staffIndex === -1 || pathArray?.[staffIndex + 1] === "-") return -1;
  return Number(pathArray?.[staffIndex + 1]);
};

const getAttendedIndexFromPath = (attendedPath) => {
  let pathArray = attendedPath?.split("/");
  let staffIndex = pathArray?.findIndex(
    (singlePath) => singlePath === "findingAttendees"
  );
  if (staffIndex === -1 || pathArray?.[staffIndex + 1] === "-") return -1;
  return Number(pathArray?.[staffIndex + 1]);
};

const isChangingOrderNumber = (changes) => {
  return changes?.some((singleChange) => {
    let pathArray = singleChange?.path?.split("/");
    if (
      pathArray?.[pathArray?.length - 1] === "orderNumber" ||
      isAddingSubject(singleChange) ||
      isDeletingSubject(singleChange)
    )
      return true;
    return false;
  });
};

const isSingleChangeChangingOrderNumber = (singleChange) => {
  let isChangingOrderNumber = singleChange?.path?.includes?.("orderNumber");
  if (!isChangingOrderNumber) return null;
  let pathArray = singleChange?.path?.split("/");
  let index = Number(pathArray?.[1]);
  let orderNumber = singleChange?.value;
  return { index, orderNumber };
};

const isDeletingSubject = (singleChange) => {
  let pathArray = singleChange?.path?.split("/");
  return (
    singleChange?.op === "remove" &&
    pathArray.length === 2 &&
    pathArray?.[0] === "findingSubjects"
  );
};

/*eslint-disable*/
const isAddingSubject = (singleChange) => {
  let pathArray = singleChange?.path?.split("/");
  return (
    singleChange?.op === "add" &&
    pathArray.length === 2 &&
    pathArray?.[0] === "findingSubjects"
  );
};

const isChangingSchema = (singleChange) => {
  let pathArray = singleChange?.path?.split("/");
  return pathArray?.[0] === "workspaceSchemas";
};
const isChangingStaff = (singleChange) => {
  let pathArray = singleChange?.path?.split("/");
  return pathArray?.[0] === "staffs";
};

const isChangingAttendees = (singleChange) => {
  let pathArray = singleChange?.path?.split("/");
  return pathArray?.[0] === "findingAttendees";
};

const isChangingImage = (singleChange) => {
  let pathArray = singleChange?.path?.split("/");
  return pathArray?.[pathArray?.length - 2] === "images";
};

const getSchemaObject = (findingObject, singleChange) => {
  let pathArray = singleChange?.path?.split("/");
  let schemaIndex = Number(
    pathArray?.[
      pathArray?.findIndex((singlePath) => singlePath === "workspaceSchemas") +
        1
    ]
  );
  return {
    schemaIndex,
    schemaObject: findingObject?.workspaceSchemas?.[schemaIndex]?.value,
  };
};

const getImageObject = (findingObject, singleChange) => {
  let pathArray = singleChange?.path?.split("/");
  let findingSubjectIndex = Number(
    pathArray?.[
      pathArray?.findIndex((singlePath) => singlePath === "findingSubjects") + 1
    ]
  );
  let subjectSectionIndex = Number(
    pathArray?.[
      pathArray?.findIndex((singlePath) => singlePath === "sections") + 1
    ]
  );
  let itemIndex = Number(
    pathArray?.[
      pathArray?.findIndex((singlePath) => singlePath === "items") + 1
    ]
  );
  let imageIndex = Number(
    pathArray?.[
      pathArray?.findIndex((singlePath) => singlePath === "images") + 1
    ]
  );
  return {
    findingSubjectIndex,
    subjectSectionIndex,
    itemIndex,
    imageIndex,
    imageObject:
      findingObject?.findingSubjects?.[findingSubjectIndex]?.sections?.[
        subjectSectionIndex
      ]?.items?.[itemIndex]?.images?.[imageIndex]?.value,
  };
};

// const getFindingSubjectId = (findingSubjectPath, findingObject) => {
//   let pathArray = findingSubjectPath?.split("/");
//   console.log(pathArray);
//   let subjectIndex = pathArray?.findIndex(
//     (singlePath) => singlePath === "findingSubjects"
//   );
//   if (subjectIndex === -1)
//     return {
//       index: -1,
//       id: -1,
//     };
//   let findingSubjectIndex = Number(pathArray?.[subjectIndex + 1]);
//   return {
//     index: findingSubjectIndex,
//     id: findingObject?.findingSubjects?.[findingSubjectIndex]?.id,
//   };
//   // return findingSubjectPath?.find()
// };

// const getFindingSubjects = (changes, findingObject) => {
//   changes?.forEach((singleChange) =>
//     console.log(getFindingSubjectId(singleChange?.path, findingObject))
//   );
// };

// const getFindingSubjectIndexOfId = (subjectId, findingObject) => {
//   return findingObject?.findingSubjects?.findIndex?.(
//     (singleSubject) => singleSubject === subjectId
//   );
// };

// const getOrderNumberAfterChanges = (findingId, changes) => {};

// const getFindingSubjectsArray = (findingObject, freshFinding, changes) => {
//   // if (freshFinding?.findingSubjects?.some(singleFreshSubject => findingObject?.findingSubjects?.findIndex(singleSubject => singleSubject?.id === singleFreshSubject)))
// };
const orderSubjects = (findingSubjects) => {
  // let allSubjects = [...findingSubjects];
  let sortedSubjects = [...findingSubjects]?.sort(
    (a, b) => a?.orderNumber - b?.orderNumber
  );
  let newChanges = [];
  for (let i = 0; i < sortedSubjects?.length; i++) {
    if (sortedSubjects?.[i]?.orderNumber !== i) {
      sortedSubjects?.splice(i, 1, {
        ...sortedSubjects?.[i],
        orderNumber: i,
      });
      newChanges?.push({
        path: `findingSubjects/${sortedSubjects?.[i]?.index}/orderNumber`,
        op: "replace",
        value: i,
      });
    }
  }
  return newChanges;
};

// findingStaff/0

const matchStaffIds = (findingObject, freshFinding, changes) => {
  return changes?.reduce((acc, singleChange) => {
    if (!isChangingStaff(singleChange)) return [...acc, singleChange];
    if (singleChange?.op === "add") {
      let existingIndex = freshFinding?.staffs?.findIndex?.(
        (singleStaff) => singleStaff?.id === singleChange?.value?.id
      );
      if (existingIndex !== -1)
        return [
          ...acc,
          {
            op: "replace",
            value: singleChange?.value?.staffRole,
            path: `findingStaff/${existingIndex}/staffRole`,
          },
        ];
      return [
        ...acc,
        {
          ...singleChange,
          path:
            "findingStaff/" +
            singleChange?.path?.[singleChange?.path?.length - 1],
          value: {
            staffId: singleChange?.value?.id,
            staffRole: singleChange?.value?.staffRole,
            findingId: freshFinding?.id,
          },
        },
      ];
    }
    if (singleChange?.op === "remove") {
      let staffIndex = getStaffIndexFromPath(singleChange?.path);
      // console.log([...findingObject?.staffs]);
      let staffId = findingObject?.staffs?.[staffIndex]?.value?.id;
      let newStaffIndex = freshFinding?.staffs?.findIndex?.(
        (singleFreshStaff) => singleFreshStaff?.id === staffId
      );
      // debugger;
      if (newStaffIndex === -1) return [...acc];
      return [
        ...acc,
        {
          ...singleChange,
          path: `findingStaff/${newStaffIndex}`,
        },
      ];
    }
    if (singleChange?.op === "replace") {
      let staffIndex = getStaffIndexFromPath(singleChange?.path);
      let staffId = findingObject?.staffs?.[staffIndex]?.value?.id;
      let newStaffIndex = freshFinding?.staffs?.findIndex?.(
        (singleFreshStaff) => singleFreshStaff?.id === staffId
      );
      if (newStaffIndex !== -1)
        return [
          ...acc,
          {
            op: "replace",
            path: "findingStaff/" + newStaffIndex + "/staffRole",
            value: singleChange?.value?.staffRole,
          },
        ];
      else
        return [
          ...acc,
          {
            op: "add",
            path: "findingStaff/-",
            value: {
              staffId: singleChange?.value?.id,
              staffRole: singleChange?.value?.staffRole,
              findingId: freshFinding?.id,
            },
          },
        ];
    }
    return [...acc, singleChange];
  }, []);
};

const matchAttendeesIds = (findingObject, freshFinding, changes) => {
  return changes?.reduce((acc, singleChange) => {
    if (!isChangingAttendees(singleChange)) return [...acc, singleChange];
    if (singleChange?.op === "remove") {
      let attendedIndex = getAttendedIndexFromPath(singleChange?.path);
      let attendedId =
        findingObject?.findingAttendees?.[attendedIndex]?.value?.value?.id;
      let newAttendedIndex = freshFinding?.findingAttendees?.findIndex?.(
        (singleFreshAttended) => singleFreshAttended?.id === attendedId
      );
      // debugger;
      if (newAttendedIndex === -1) return [...acc];
      return [
        ...acc,
        {
          ...singleChange,
          path: `findingAttendees/${newAttendedIndex}`,
        },
      ];
    }
    if (singleChange?.op === "replace") {
      let attendedIndex = getAttendedIndexFromPath(singleChange?.path);
      let attendedId =
        findingObject?.findingAttendees?.[attendedIndex]?.value?.id;
      let newAttendedIndex = freshFinding?.findingAttendees?.findIndex?.(
        (singleFreshAttended) => singleFreshAttended?.id === attendedId
      );
      if (newAttendedIndex !== -1)
        return [
          ...acc,
          {
            op: "replace",
            path:
              "findingAttendees/" +
              newAttendedIndex +
              "/" +
              singleChange?.path?.split("/")?.[2],
            value: singleChange?.value,
          },
        ];
      else {
        if (singleChange?.path?.split("/")?.[2] === "lastName") return [...acc];
        const data = findingObject?.findingAttendees?.[attendedIndex]?.value;
        return [
          ...acc,
          {
            op: "add",
            path: "findingAttendees/-",
            value: {
              firstName: data?.firstName?.value,
              lastName: data?.lastName?.value,
              title: data?.title?.value,
            },
          },
        ];
      }
    }
    return [...acc, singleChange];
  }, []);
};

const matchImageIds = (findingObject, freshFinding, changes, userId) => {
  return changes?.reduce((acc, val) => {
    if (isChangingSchema(val)) {
      if (val?.op === "add") return [...acc, val];
      let schemaProps = getSchemaObject(findingObject, val);
      if (schemaProps?.schemaIndex === -1) return [...acc];
      let schemaObject = schemaProps?.schemaObject;
      if (
        schemaObject?.id ===
        freshFinding?.workspaceSchemas?.[schemaProps?.schemaIndex]?.id
      ) {
        return [...acc, val];
      } else {
        let freshSchemaIndex = freshFinding?.workspaceSchemas?.findIndex(
          (singleSchema) => singleSchema?.id === schemaObject?.id
        );
        if (freshSchemaIndex === -1) {
          if (val?.op === "replace") {
            // transformisanje u add
            return [
              ...acc,
              {
                op: "add",
                path: "workspaceSchemas/-",
                value: addCRUDInfo(
                  {
                    imageUrl: val?.value,
                    orderNumber:
                      Math.max(
                        ...freshFinding?.workspaceSchemas?.map(
                          (singleSchema) => singleSchema?.orderNumber
                        ),
                        ...findingObject?.workspaceSchemas?.map(
                          (singleSchema) => singleSchema?.value?.orderNumber
                        )
                      ) + 1,
                  },
                  userId
                ),
              },
            ];
          }
          return [...acc];
        } else {
          let pathArray = val?.path?.split?.("/");
          pathArray?.splice(1, 1, `${freshSchemaIndex}`);
          return [
            ...acc,
            {
              ...val,
              path: pathArray?.join("/"),
            },
          ];
        }
      }
    }
    if (isChangingImage(val)) {
      if (val?.op === "add") return [...acc, val];
      let {
        imageObject,
        imageIndex,
        findingSubjectIndex,
        subjectSectionIndex,
        itemIndex,
      } = getImageObject(findingObject, val);
      if (
        imageObject?.id ===
        freshFinding?.findingSubjects?.[findingSubjectIndex]?.sections?.[
          subjectSectionIndex
        ]?.items?.[itemIndex]?.images?.[imageIndex]?.id
      ) {
        return [...acc, val];
      } else {
        let freshImageIndex = freshFinding?.findingSubjects?.[
          findingSubjectIndex
        ]?.sections?.[subjectSectionIndex]?.items?.[
          itemIndex
        ]?.images?.findIndex(
          (singleImage) => singleImage?.id === imageObject?.id
        );
        // debugger;
        // console.log(freshImageIndex);
        // console.log(
        //   freshFinding?.findingSubjects?.[findingSubjectIndex]?.sections?.[
        //     subjectSectionIndex
        //   ]?.items?.[itemIndex]
        // );
        if (freshImageIndex === -1) {
          if (val?.op === "replace") {
            // transformisanje u add
            return [
              ...acc,
              {
                op: "add",
                path: `findingSubjects/${findingSubjectIndex}/sections/${subjectSectionIndex}/items/${itemIndex}/images/-`,
                value: addCRUDInfo(
                  {
                    imageUrl: val?.value?.imageUrl,
                  },
                  userId
                ),
              },
            ];
          }
          return [...acc];
        } else {
          let pathArray = val?.path?.split?.("/");
          pathArray?.splice(8, 1, `${freshImageIndex}`);
          return [
            ...acc,
            {
              ...val,
              path: pathArray?.join("/"),
            },
          ];
        }
      }
    }

    return [...acc, val];
  }, []);
};

let getSubjectIndexes = (findingObject, freshFinding, changes) => {
  let allSubjects = [...freshFinding?.findingSubjects]?.map?.(
    (singleSubject, i) => ({
      orderNumber: singleSubject?.orderNumber,
      index: i,
    })
  );
  changes?.forEach((singleChange) => {
    if (isAddingSubject(singleChange)) {
      allSubjects.push({
        orderNumber: singleChange?.value?.orderNumber,
        index: allSubjects?.length,
      });
    }
  });
  changes?.forEach((singleChange) => {
    let changeOfOrder = isSingleChangeChangingOrderNumber(singleChange);
    if (changeOfOrder != null) {
      allSubjects.push(changeOfOrder);
    }
  });
  // console.log();
  let sortedSubjects = _.uniqBy(allSubjects?.reverse?.(), "index")?.sort?.(
    (a, b) => a?.orderNumber - b?.orderNumber
  );
  // console.log(sortedSubjects);
  let isOrdered = true;
  let newChanges = [];
  for (let i = 0; i < sortedSubjects?.length; i++) {
    if (sortedSubjects?.[i]?.orderNumber !== i) {
      newChanges?.push({ index: sortedSubjects?.[i]?.index, orderNumber: i });
    }
  }
  // console.log(newChanges);
  // console.log(isOrdered);
  return newChanges?.map((singleChange) => ({
    op: "replace",
    path: `findingSubjects/${singleChange?.index}/orderNumber`,
    value: singleChange?.orderNumber,
  }));

  // findingObject?.findingSubjects
  //   ?.filter?.((singleSubject) => !singleSubject?.removed)
  //   ?.forEach?.((singleSubject) => {
  //     if (singleSubject?.id === 0) {
  //       allSubjects?.push({
  //         ...singleSubject,
  //         index: allSubjects?.length,
  //         orderNumber: singleSubject?.orderNumber?.value,
  //       });
  //       return;
  //     }
  //     let subjectIndex = allSubjects?.findIndex(
  //       (singleFreshSubject) => singleFreshSubject?.id === singleSubject?.id
  //     );
  //     if (
  //       allSubjects?.findIndex(
  //         (singleFreshSubject) => singleFreshSubject?.id === singleSubject?.id
  //       ) === -1
  //     )
  //       allSubjects?.push({
  //         ...singleSubject,
  //         index: allSubjects?.length,
  //         orderNumber: singleSubject?.orderNumber?.value,
  //       });
  //     else
  //       allSubjects?.splice(subjectIndex, 1, {
  //         ...allSubjects?.[subjectIndex],
  //         index: allSubjects?.[subjectIndex]?.index,
  //         orderNumber: singleSubject?.orderNumber?.value,
  //       });
  //   });
  return allSubjects;
};

const isChangingSingleSubject = (subjectIndex, changes, singleSubject) => {
  return changes?.some((singleChange) => {
    let pathArray = singleChange?.path?.split?.("/");
    // console.log(
    //   singleChange?.op !== "remove" &&
    //     pathArray?.[0] === "findingSubjects" &&
    //     Number(pathArray?.[1]) === subjectIndex &&
    //     !(
    //       singleChange?.path?.includes("orderNumber") &&
    //       !singleSubject?.orderNumber?.manuallyEdited
    //     )
    // );
    return (
      singleChange?.op !== "remove" &&
      pathArray?.[0] === "findingSubjects" &&
      Number(pathArray?.[1]) === subjectIndex &&
      !(
        singleChange?.path?.includes("orderNumber") &&
        !singleSubject?.orderNumber?.manuallyEdited
      )
    );
  });
};

const orderFinding = (findingObject, freshFinding, changes) => {
  if (!isChangingOrderNumber(changes)) return changes;
  let currentSubjects = [...findingObject?.findingSubjects];
  let freshSubjects = [...freshFinding?.findingSubjects];
  for (let index = 0; index < currentSubjects?.length; index++) {
    let freshSubjectIndex = freshSubjects?.findIndex(
      (singleSubject) => singleSubject?.id === currentSubjects?.[index]?.id
    );
    // debugger; //eslint-disable-line
    if (freshSubjectIndex !== -1 || currentSubjects?.[index]?.removed) {
      freshSubjects?.splice(freshSubjectIndex, 1);
    }
  }
  let newChanges = [...changes];
  // console.log(freshSubjects);
  let orderNumberChanges = getSubjectIndexes(
    findingObject,
    freshFinding,
    changes
  );
  // console.log(
  //   "pre promene",
  //   getSubjectIndexes(findingObject, freshFinding, changes)
  // );
  // Orderovanje vestacko
  // orderNumberChanges = orderSubjects(
  //   getSubjectIndexes(
  //     {
  //       ...findingObject,
  //       findingSubjects: [
  //         ...findingObject.findingSubjects?.filter(
  //           (singleSubject) => !singleSubject?.removed
  //         ),
  //       ],
  //       // findingSubjects: [
  //       //   ...findingObject.findingSubjects,
  //       //   freshSubjects?.map((singleFreshSubject, i) => ({
  //       //     ...singleFreshSubject,
  //       //     orderNumber: currentSubjects?.length + i,
  //       //   })),
  //       // ],
  //     },
  //     freshFinding
  //   )
  // );
  // console.log("orderNumberChanges", orderNumberChanges);
  if (freshSubjects?.length !== 0) {
    // let allSubjects = removeDuplicates(
    //   [...currentSubjects, ...freshSubjects],
    //   "id"
    // );
    // let isOrdered = true;
    // let arrayOfNotOrderedNumbers = [];
    // let subjectsToOrder = [];
    // for (let i = 0; i < allSubjects?.length; i++) {
    //   if (
    //     allSubjects?.findIndex?.(
    //       (singleSubject) => singleSubject?.orderNumber === i
    //     ) === -1
    //   ) {
    //     subjectsToOrder.push(i);
    //     continue;
    //   }
    //   // orderArray?.[allSubjects?.[i]?.orderNumber] = allSubjects?.[i]?.id;
    // }
    // if (orderArray?.some(singleElement => !singleElement)) {
    //   if (subjectsToOrder?.includes())
    // }
  }
  return (
    [
      ...newChanges,
      ...freshSubjects?.map((singleSubject, index) => ({
        op: "replace",
        path: `findingSubjects/${
          freshFinding?.findingSubjects?.findIndex?.(
            (singleFreshSubject) => singleFreshSubject?.id === singleSubject?.id
          ) -
          findingObject?.findingSubjects?.filter(
            (singleSubject) => singleSubject?.removed
          )?.length
        }/orderNumber`,
        value:
          currentSubjects?.filter((singleSubject) => !singleSubject?.removed)
            ?.length + index,
      })),
      ...orderNumberChanges,
    ]
      //   .reverse(),
      //   "path"
      // )
      //   .reverse()
      ?.sort((a, b) => a?.value?.orderNumber - b?.value?.orderNumber)
  );
};

const overlapMapFinding = (
  findingObject,
  freshFinding,
  changes = [],
  userId
) => {
  let currentSubjects = findingObject?.findingSubjects;
  let currentSubjectIds = findingObject?.findingSubjects
    ?.filter((singleSubject) => !singleSubject?.removed)
    ?.map?.((singleSubject) => singleSubject?.id);
  let freshSubjects = [...freshFinding?.findingSubjects];
  // console.log([...currentSubjects]);
  // console.log([...freshSubjects]);
  let newSubjects = [];
  // Dodavanje currentsubjects koji vec postoje na serveru
  currentSubjects?.forEach?.((singleCurrentSubject) => {
    if (
      !singleCurrentSubject?.removed &&
      freshSubjects?.some(
        (singleFreshSubject) =>
          singleFreshSubject?.id === singleCurrentSubject?.id
      )
    ) {
      currentSubjectIds?.splice(
        currentSubjectIds?.findIndex(
          (singleCurrentSubjectId) =>
            singleCurrentSubjectId === singleCurrentSubject?.id
        ),
        1
      );
      newSubjects?.push({
        ...singleCurrentSubject,
      });
    }
  });
  // console.log([...newSubjects]);

  // Serverski subjekti koji ne postoje na klijentu
  let leftoverSubjects = freshSubjects?.filter(
    (singleFreshSubject) =>
      !currentSubjects?.some?.(
        (singleCurrentSubject) =>
          singleCurrentSubject?.id === singleFreshSubject?.id &&
          !singleFreshSubject?.removed
      )
  );
  // console.log([...leftoverSubjects]);

  // Dodavanje na klijentu serverskih subjekta koji na klijentu ne postoje
  leftoverSubjects?.forEach?.((singleLeftoverSubject) => {
    newSubjects?.push(mapFindingSubjectForFE(singleLeftoverSubject));
  });

  // currentSubjects
  //   ?.filter((singleCurrentSubject) => singleCurrentSubject?.removed)
  //   ?.forEach((singleCurrentSubject) => {
  //     let subjectToDeleteIndex = newSubjects?.findIndex(
  //       (singleSortedSubject) =>
  //         singleSortedSubject?.id === singleCurrentSubject?.id
  //     );
  //     newSubjects?.splice(subjectToDeleteIndex, 1);
  //   });

  //Leftoverima dodati ON length + i;
  // console.log([...newSubjects]);
  // Sortirati po ID kako bi dobili odgovarajuci index i
  // dodavanje starih i novih indexa
  let newSortedSubjects = newSubjects
    ?.sort((a, b) => a?.id - b?.id)
    ?.map?.((singleSubject, index) => ({
      ...singleSubject,
      newIndex: index,
      oldIndex:
        currentSubjects
          ?.filter((singleCurrentSubject) => !singleCurrentSubject?.removed)
          ?.findIndex?.(
            (singleCurrentSubject) =>
              singleCurrentSubject?.id === singleSubject?.id
          ) === -1
          ? null
          : currentSubjects
              ?.filter((singleCurrentSubject) => !singleCurrentSubject?.removed)
              ?.findIndex?.(
                (singleCurrentSubject) =>
                  singleCurrentSubject?.id === singleSubject?.id
              ),
    }));
  let newAddedSortedSubjects = [...newSubjects];
  // console.log([...newSortedSubjects]);

  // Kada se menjaju predmeti na klijentu koji bi trebalo da postoje na serveru ali su izbrisani
  // treba rucno postaviti flag da trebaju da se dodaju nove promene
  let addNewChanges = false;
  let deletedSubjectsFromServer = 0;
  // Dodavanje novih predmeta koji postoje na klijentu a ne postoje na serveru i dobijanje novih indexa
  // OVDE DODATI LOGIKU DAL SE BRISE ILI NE
  currentSubjects?.forEach?.((singleCurrentSubject) => {
    if (
      !singleCurrentSubject?.removed &&
      !freshSubjects?.some(
        (singleFreshSubject) =>
          singleFreshSubject?.id === singleCurrentSubject?.id
      )
    ) {
      addNewChanges = true;
      let subjectOldIndex = currentSubjects
        ?.filter?.((singleCurrentSubject) => !singleCurrentSubject?.removed)
        ?.findIndex?.(
          (singleSubject) => singleSubject?.id === singleCurrentSubject?.id
        );
      // debugger;
      if (
        !singleCurrentSubject?.added &&
        subjectOldIndex !== -1 &&
        !isChangingSingleSubject(subjectOldIndex, changes, singleCurrentSubject)
        // !singleCurrentSubject?.orderNumber?.manuallyEdited
      ) {
        deletedSubjectsFromServer++;
        return;
      }
      let subjectToAdd = {
        ...singleCurrentSubject,
        alreadyAdded: singleCurrentSubject?.added,
        added: true,
        oldIndex:
          currentSubjects
            ?.filter?.((singleCurrentSubject) => !singleCurrentSubject?.removed)
            ?.findIndex?.(
              (singleSubject) => singleSubject?.id === singleCurrentSubject?.id
            ) === -1
            ? null
            : currentSubjects
                ?.filter?.(
                  (singleCurrentSubject) => !singleCurrentSubject?.removed
                )
                ?.findIndex?.(
                  (singleSubject) =>
                    singleSubject?.id === singleCurrentSubject?.id
                ),
        newIndex: newSortedSubjects?.length,
        // newIndex: freshSubjects?.findIndex(
        // (singleFreshSubject) =>
        // singleFreshSubject?.id === singleCurrentSubject?.id
        // ),
      };
      newSortedSubjects?.push(subjectToAdd);
    }
  });

  // console.log([...newSortedSubjects]);
  // Dodeljivanje novog ON na predmete koji su dovuceni sa servera, a nisu postojali na klijentu
  leftoverSubjects?.forEach?.((singleLeftoverSubject, index) => {
    let leftoverSubjectIndex = newSortedSubjects?.findIndex(
      (singleSortedSubject) =>
        singleSortedSubject?.id === singleLeftoverSubject?.id
    );
    // console.log(leftoverSubjectIndex);
    newSortedSubjects?.splice(leftoverSubjectIndex, 1, {
      ...newSortedSubjects?.[leftoverSubjectIndex],
      orderNumber: {
        edited: true,
        value:
          newSortedSubjects?.length -
          leftoverSubjects?.length -
          deletedSubjectsFromServer +
          index,
      },
    });
  });
  // console.log([...newSortedSubjects]);
  // U slucaju da se samo dodaje predmet i ne menja se ON, dodaje se ovaj newOrderNumber + index
  let newOrderNumber = newSortedSubjects?.filter?.(
    (singleSortedSubject) => singleSortedSubject?.id > 1
  )?.length;

  // Vestacko uredjivanje order numbera
  newSortedSubjects = newSortedSubjects
    ?.sort?.((a, b) => {
      let first =
        a?.orderNumber?.value != null ? a?.orderNumber?.value : a?.orderNumber;
      let second =
        b?.orderNumber?.value != null ? b?.orderNumber?.value : b?.orderNumber;
      return first - second;
    })
    ?.map?.((singleSortedSubject, i) => {
      if (singleSortedSubject?.orderNumber?.value !== i) {
        return {
          ...singleSortedSubject,
          orderNumber: {
            edited: true,
            value: i,
          },
        };
      }
      return { ...singleSortedSubject };
    });

  // Nove promene
  let newChangesNotSorted = newSortedSubjects?.map?.((singleSortedSubject) => {
    if (singleSortedSubject?.added && !singleSortedSubject?.alreadyAdded) {
      // console.log(singleSortedSubject);
      let subject = {
        ...singleSortedSubject,
        subjectId: singleSortedSubject?.id,
        createdByUser: null,
        updatedByUser: null,
        findingId: 0,
        name:
          singleSortedSubject?.name ||
          singleSortedSubject?.scanningSubject?.name,
        specificName: removeEditFlag(singleSortedSubject?.specificName),
        measuringInstruments: singleSortedSubject?.measuringInstruments?.map?.(
          (singleInstrument) => ({
            ...singleInstrument,
            id: 0,
            createdByUser: null,
            updatedByUser: null,
          })
        ),
        standards: singleSortedSubject?.standards?.map?.((singleStandard) => ({
          ...singleStandard,
          id: 0,
          createdByUser: null,
          updatedByUser: null,
        })),
        id: 0,
        positiveConclusionTemplate:
          singleSortedSubject?.positiveConclusionTemplate,
        negativeConclusionTemplate:
          singleSortedSubject?.negativeConclusionTemplate,
        scanningSubject: null,
        orderNumber: removeEditFlag(singleSortedSubject?.orderNumber),
        conclusion: removeEditFlag(singleSortedSubject?.conclusion),
        sections: singleSortedSubject?.sections?.map?.((singleSection) => {
          let sectionToReturn = {
            ...singleSection,
            createdByUser: null,
            updatedByUser: null,
            id: 0,
            note: removeEditFlag(singleSection?.note),
            items: singleSection?.items?.map?.((singleItem) => {
              let itemToReturn = {
                ...singleItem,
                createdByUser: null,
                updatedByUser: null,
                id: 0,
                answer: removeEditFlag(singleItem?.answer),
                descriptionOne: removeEditFlag(singleItem?.descriptionOne),
                descriptionTwo: removeEditFlag(singleItem?.descriptionTwo),
                isInRange: removeEditFlag(singleItem?.isInRange),
                findingSubjectSectionId: 0,
                columns: singleItem.columns?.value?.map((singleColumn) => {
                  let columnToReturn = {
                    ...singleColumn,
                    id: 0,
                    columnTitle: singleColumn?.columnTitle,
                    columnSubtitle: singleColumn?.columnSubtitle,
                    answers: singleColumn?.answers?.map?.(
                      (singleAnswer, index) => {
                        let answerToReturn = {
                          ...singleAnswer,
                          rowIndex: index,
                          id: 0,
                        };
                        delete answerToReturn.columnId;
                        delete answerToReturn?.createdByUser;
                        delete answerToReturn?.updatedByUser;
                        return answerToReturn;
                      }
                    ),
                  };
                  delete columnToReturn?.createdByUser;
                  delete columnToReturn?.updatedByUser;
                  delete columnToReturn?.columnId;
                  return columnToReturn;
                }),
                options: singleItem?.options?.map((singleOption) => ({
                  ...singleOption,
                  id: 0,
                  checklistTemplateSectionItemId: 0,
                  createdByUser: null,
                  updatedByUser: null,
                })),
                images:
                  singleItem?.images?.map?.((singleImage) => ({
                    ...removeEditFlag(singleImage),
                    findingSubjectSectionItemId: 0,
                    createdByUser: null,
                    updatedByUser: null,
                  })) || [],
              };
              delete itemToReturn?.edited;
              delete itemToReturn?.columnAnswers;
              return itemToReturn;
            }),
          };
          // delete sectionToReturn?.id;
          return sectionToReturn;
        }),
      };

      delete subject?.added;
      return {
        op: "add",
        path: "findingSubjects/-",
        value: subject,
      };
    } else {
      return {
        op: "replace",
        path: `findingSubjects/${singleSortedSubject?.newIndex}/orderNumber`,
        value: singleSortedSubject?.orderNumber?.value,
      };
    }
  });
  // console.log(newChangesNotSorted);
  let newChangesSorted = [
    ...newChangesNotSorted?.filter(
      (singleChange) => singleChange?.op === "add"
    ),
    ...newChangesNotSorted?.filter(
      (singleChange) => singleChange?.op !== "add"
    ),
  ];
  // console.log(newChangesSorted);
  let newChanges =
    isChangingOrderNumber(changes) || addNewChanges ? newChangesSorted : [];
  // console.log(
  //   newChanges?.map?.((singleNewChange) => {
  //     let dataToReturn = { ...singleNewChange };
  //     delete dataToReturn?.value?.subjectId;
  //     return singleNewChange;
  //   })
  // );
  return removeDuplicates([
    ...newChanges?.map?.((singleNewChange) => {
      let dataToReturn = { ...singleNewChange };
      delete dataToReturn?.value?.subjectId;
      return singleNewChange;
    }),
    ...changes?.reduce?.((acc, singleChange) => {
      if (!singleChange?.path?.includes("findingSubjects"))
        return [...acc, singleChange];
      let findingIndex = getFindingSubjectIndexFromPath(singleChange?.path);
      if (singleChange?.op === "remove") {
        let findingId = findingObject?.findingSubjects?.[findingIndex]?.id;
        let freshFindingIndex = freshFinding?.findingSubjects?.findIndex?.(
          (singleFreshSubject) => singleFreshSubject?.id === findingId
        );
        if (freshFindingIndex === -1) {
          return [...acc];
        }
        let pathArray = singleChange?.path?.split?.("/");
        pathArray?.splice(1, 1, freshFindingIndex);
        return [
          ...acc,
          {
            op: "remove",
            path: pathArray?.join("/"),
            // value: singleChange?.value,
          },
        ];
      }
      if (
        singleChange?.op === "replace" ||
        (singleChange?.op === "add" &&
          singleChange?.path?.split?.("/")?.length !== 2)
      ) {
        let findingId = findingObject?.findingSubjects?.filter?.(
          (singleSubject) => !singleSubject?.removed
        )?.[findingIndex]?.id;
        let findingFreshIndex = newSortedSubjects?.find(
          (singleSortedSubject) => singleSortedSubject?.id === findingId
        )?.newIndex;
        // debugger;
        if (
          // singleChange?.op === "add" &&
          singleChange?.path?.includes("columns")
        )
          return [...acc, singleChange];
        if (findingFreshIndex === -1 || findingFreshIndex == null) {
          return [...acc];
        }
        // console.log(
        //   newChangesSorted?.find?.(
        //     (singleNewChange) => singleNewChange?.value?.subjectId === findingId
        //   )
        // );
        // if (
        //   newSortedSubjects?.find(
        //     (singleSortedSubject) => singleSortedSubject?.id === findingId
        //   )?.added &&
        //   acc?.findIndex?.(
        //     (singleNewChange) => singleNewChange?.value?.subjectId === findingId
        //   ) === -1
        // ) {
        //   return [
        //     ...acc,
        //     newChangesSorted?.find?.(
        //       (singleNewChange) =>
        //         singleNewChange?.value?.subjectId === findingId
        //     ),
        //   ];
        // }
        let pathArray = singleChange?.path?.split?.("/");
        pathArray?.splice(1, 1, findingFreshIndex);
        return [
          ...acc,
          {
            ...singleChange,
            path: pathArray?.join("/"),
          },
        ];
      }
      return [...acc, singleChange];
    }, []),
    //   ?.reduce((acc, val) => {
    //     let findingSubjectIndex = getFindingSubjectIndexFromPath(val?.path);
    //     // debugger; //eslint-disable-line
    //     if (findingSubjectIndex === -1) {
    //       return [...acc, val];
    //     }
    //     let freshFindingSubjectIndex = freshFinding?.findingSubjects?.findIndex(
    //       (singleSubject) =>
    //         singleSubject?.id ===
    //         findingObject?.findingSubjects?.[findingSubjectIndex]?.id
    //     );
    //     // if (freshFindingSubjectIndex !== -1 && isDeletingSubject(val))
    //     //   return [
    //     //     ...acc,
    //     //     {
    //     //       ...val,
    //     //       findingId:
    //     //         findingObject?.findingSubjects?.[findingSubjectIndex]?.id,
    //     //     },
    //     //   ];
    //     if (
    //       freshFinding?.findingSubjects?.findIndex(
    //         (singleSubject) =>
    //           singleSubject?.id ===
    //           findingObject?.findingSubjects?.[findingSubjectIndex]?.id
    //       ) === -1
    //     ) {
    //       if (isDeletingSubject(val))
    //         return [
    //           ...acc,
    //           {
    //             ...val,
    //             shouldDelete: true,
    //             findingId:
    //               findingObject?.findingSubjects?.[findingSubjectIndex]?.id,
    //           },
    //         ];
    //       let newFindingSubject =
    //         findingObject?.findingSubjects?.[findingSubjectIndex];
    //       let subject = {
    //         ...newFindingSubject,
    //         createdByUser: null,
    //         updatedByUser: null,
    //         findingId: 0,
    //         measuringInstruments:
    //           newFindingSubject?.measuringInstruments?.map?.(
    //             (singleInstrument) => ({
    //               ...singleInstrument,
    //               id: 0,
    //               createdByUser: null,
    //               updatedByUser: null,
    //             })
    //           ),
    //         standards: newFindingSubject?.standards?.map?.(
    //           (singleStandard) => ({
    //             ...singleStandard,
    //             id: 0,
    //             createdByUser: null,
    //             updatedByUser: null,
    //           })
    //         ),
    //         id: 0,
    //         positiveConclusionTemplate:
    //           newFindingSubject?.positiveConclusionTemplate,
    //         negativeConclusionTemplate:
    //           newFindingSubject?.negativeConclusionTemplate,
    //         scanningSubject: null,
    //         orderNumber: removeEditFlag(newFindingSubject?.orderNumber),
    //         conclusion: removeEditFlag(newFindingSubject?.conclusion),
    //         sections: newFindingSubject?.sections?.map?.((singleSection) => {
    //           let sectionToReturn = {
    //             ...singleSection,
    //             createdByUser: null,
    //             updatedByUser: null,
    //             id: 0,
    //             note: removeEditFlag(singleSection?.note),
    //             items: singleSection?.items?.map?.((singleItem) => {
    //               let itemToReturn = {
    //                 ...singleItem,
    //                 createdByUser: null,
    //                 updatedByUser: null,
    //                 id: 0,
    //                 answer: removeEditFlag(singleItem?.answer),
    //                 descriptionOne: removeEditFlag(singleItem?.descriptionOne),
    //                 descriptionTwo: removeEditFlag(singleItem?.descriptionTwo),
    //                 isInRange: removeEditFlag(singleItem?.isInRange),
    //                 findingSubjectSectionId: 0,
    //                 options: singleItem?.options?.map((singleOption) => ({
    //                   ...singleOption,
    //                   id: 0,
    //                   checklistTemplateSectionItemId: 0,
    //                   createdByUser: null,
    //                   updatedByUser: null,
    //                 })),
    //                 images:
    //                   singleItem?.images?.map?.((singleImage) => ({
    //                     ...removeEditFlag(singleImage),
    //                     findingSubjectSectionItemId: 0,
    //                     createdByUser: null,
    //                     updatedByUser: null,
    //                   })) || [],
    //               };
    //               delete itemToReturn?.edited;
    //               return itemToReturn;
    //             }),
    //           };
    //           // delete sectionToReturn?.id;
    //           return sectionToReturn;
    //         }),
    //       };

    //       delete subject?.added;
    //       if (
    //         acc?.some(
    //           (singleChange) =>
    //             singleChange?.findingId ===
    //             findingObject?.findingSubjects?.[findingSubjectIndex]?.id
    //         )
    //       )
    //         return [...acc];
    //       return [
    //         ...acc,
    //         {
    //           op: "add",
    //           path: "findingSubjects/-",
    //           value: subject,
    //           findingId:
    //             findingObject?.findingSubjects?.[findingSubjectIndex]?.id,
    //         },
    //       ];
    //       // algoritam za dodavanje finding subjecta
    //     }
    //     return [
    //       ...acc,
    //       {
    //         ...val,
    //         path: val?.path?.replace?.(
    //           `${findingSubjectIndex}`,
    //           `${
    //             findingSubjectIndex -
    //             acc?.filter(
    //               (singleAccChange) =>
    //                 singleAccChange?.findingId && !singleAccChange?.shouldDelete
    //             )?.length
    //           }`
    //         ),
    //       },
    //     ];
    //   }, [])
    //   ?.filter((singleChange) => !singleChange?.shouldDelete)
    //   ?.map((singleChange) => ({
    //     op: singleChange?.op,
    //     path: singleChange?.path,
    //     value: singleChange?.value,
    //   })),
    // ...newSortedSubjects?.map?.((singleSortedSubject) => ({
    //   op: "replace",
    //   path: `findingSubjects/${singleSortedSubject?.newIndex}/orderNumber`,
    //   value: singleSortedSubject?.orderNumber?.value,
    // })),
  ]);
};

const removeNestedAddChanges = (changes) => {
  let deletedPaths = [];
  return changes?.reduce((acc, val) => {
    if (val?.op === "add") {
      deletedPaths?.push(val?.path);
      return [...acc, val];
    }
    if (
      deletedPaths?.findIndex((singlePath) =>
        val?.path?.includes(singlePath)
      ) !== -1
    )
      return [...acc];
    return [...acc, val];
  }, []);
};

const removeNestedDeletedChanges = (changes) => {
  let deletedPaths = [];
  return changes?.reduce((acc, val) => {
    if (val?.op === "remove") {
      deletedPaths?.push(val?.path);
      return [...acc, val];
    }
    if (
      deletedPaths?.findIndex((singlePath) =>
        val?.path?.includes(singlePath)
      ) !== -1
    )
      return [...acc];
    return [...acc, val];
  }, []);
};

const formatIndexes = (changes, freshFinding, findingObject) => {
  let maxSubjects = freshFinding?.findingSubjects?.length;
  changes?.forEach((singleChange) => {
    if (isDeletingSubject(singleChange)) maxSubjects--;
    if (isAddingSubject(singleChange)) maxSubjects++;
  });
  return changes?.reduce((acc, val) => {
    let subjectIndex = getFindingSubjectIndexFromPath(val?.path);
    if (
      subjectIndex + 1 > maxSubjects &&
      val?.op === "replace" &&
      !val?.path?.includes("columns")
    ) {
      return [...acc];
    }
    return [...acc, val];
  }, []);
};

const formatColumnChanges = (changes, wholeFindingObject) => {
  return changes?.reduce((acc, val) => {
    let pathArray = val?.path?.split("/");
    delete val?.value?.columnId;
    if (pathArray?.includes("columns")) {
      let findingSubjectIndex = Number(pathArray?.[1]);
      let sectionIndex = Number(pathArray?.[3]);
      let itemIndex = Number(pathArray?.[5]);
      let columnIndex = Number(pathArray?.[7]);

      let findingSubjectId =
        wholeFindingObject?.currentObject?.findingSubjects?.[
          findingSubjectIndex
        ]?.id;
      let newFindingSubjectIndex =
        wholeFindingObject?.freshFinding?.findingSubjects?.findIndex(
          (singleSubject) => singleSubject?.id === findingSubjectId
        );
      if (newFindingSubjectIndex === -1) return [...acc];
      let newFindingSubjectIndexToUse = Math.max(
        newFindingSubjectIndex -
          wholeFindingObject.currentObject?.findingSubjects
            ?.slice(0, newFindingSubjectIndex)
            ?.filter((singleSubject) => singleSubject?.removed)?.length,
        0
      );

      if (pathArray?.includes("answers") && pathArray?.length >= 10) {
        let oldAnswerIndex = Number(pathArray?.[9]);
        let answerIndex = wholeFindingObject?.currentObject?.findingSubjects?.[
          findingSubjectIndex
        ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.[
          columnIndex
        ]?.answers
          ?.slice(0, oldAnswerIndex)
          ?.filter((singleAnswer) => !singleAnswer?.removed)?.length;
        let freshAnswersLength =
          wholeFindingObject?.freshFinding?.findingSubjects?.[
            newFindingSubjectIndex
          ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.[
            columnIndex
          ]?.answers?.length;
        if (val?.value) {
          delete val?.value?.id;
          delete val?.value?.createdByUser;
          delete val?.value?.updatedByUser;
        }
        let removedAnswersLength = changes?.filter?.(
          (singleChange) =>
            singleChange?.op === "remove" &&
            singleChange?.path?.includes(
              `findingSubjects/${newFindingSubjectIndexToUse}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/`
            )
        )?.length;
        freshAnswersLength -= removedAnswersLength;
        if (val?.op === "replace") {
          if (
            freshAnswersLength <= oldAnswerIndex ||
            newFindingSubjectIndex === -1
          ) {
            return [
              ...acc,
              {
                op: "add",
                path: `findingSubjects/${newFindingSubjectIndexToUse}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/-`,
                value: val?.value,
              },
            ];
          } else {
            let freshAnswer =
              wholeFindingObject?.freshFinding?.findingSubjects?.[
                newFindingSubjectIndex
              ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.[
                columnIndex
              ]?.answers?.[answerIndex];
            let currentAnswer = val?.value;
            // if (
            //   freshAnswer?.answer === currentAnswer?.answer &&
            //   freshAnswer?.rowIndex === currentAnswer?.rowIndex
            // )
            //   return [...acc];
            return [
              ...acc,
              {
                op: "replace",
                path: `findingSubjects/${newFindingSubjectIndexToUse}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/${oldAnswerIndex}`,
                value: val?.value,
              },
            ];
          }
        }
        if (val?.op === "remove") {
          if (oldAnswerIndex >= freshAnswersLength + removedAnswersLength) {
            return [...acc];
          } else
            return [
              ...acc,
              {
                op: "remove",
                path: `findingSubjects/${newFindingSubjectIndexToUse}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/${oldAnswerIndex}`,
              },
            ];
        } else {
          return [
            ...acc,
            {
              op: "add",
              path: `findingSubjects/${newFindingSubjectIndexToUse}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/-`,
              value: val?.value,
            },
          ];
        }
      } else if (pathArray?.length >= 9) {
        let isTitle = pathArray?.[8] === "columnTitle";
        let freshColumn =
          wholeFindingObject?.freshFinding?.findingSubjects?.[
            newFindingSubjectIndex
          ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.[
            columnIndex
          ];
        if (isTitle && freshColumn?.columnTitle === val?.value) return [...acc];
        if (!isTitle && freshColumn?.columnSubtitle === val?.value)
          return [...acc];
      }
      return [
        ...acc,
        {
          ...val,
          path: `findingSubjects/${newFindingSubjectIndexToUse}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/${
            pathArray?.[8] === "columnTitle" ? "columnTitle" : "columnSubtitle"
          }`,
        },
      ];
    } else return [...acc, val];
  }, []);
};

const removeFreshColumnChanges = (changes, wholeFindingObject) => {
  let columnsToCheck = changes?.reduce((acc, val) => {
    let pathArray = val?.path?.split("/");
    if (pathArray?.includes("columns")) {
      let findingSubjectIndex = Number(pathArray?.[1]);
      let sectionIndex = Number(pathArray?.[3]);
      let itemIndex = Number(pathArray?.[5]);
      let columnIndex = Number(pathArray?.[7]);

      let findingSubjectId =
        wholeFindingObject?.currentObject?.findingSubjects?.filter?.(
          (singleSubject) => !singleSubject?.removed
        )?.[findingSubjectIndex]?.id;
      let freshFindingSubjectIndex =
        wholeFindingObject?.freshFinding?.findingSubjects?.findIndex(
          (singleSubject) => singleSubject?.id === findingSubjectId
        );
      let newFindingSubjectIndexCalculated =
        freshFindingSubjectIndex -
        wholeFindingObject?.currentObject?.findingSubjects
          ?.slice(0, freshFindingSubjectIndex)
          ?.filter?.((singleSubject) => singleSubject?.removed)?.length;
      newFindingSubjectIndexCalculated = _.isNumber(
        newFindingSubjectIndexCalculated
      )
        ? newFindingSubjectIndexCalculated
        : 0;
      let newFindingSubjectIndex = Math.max(
        newFindingSubjectIndexCalculated,

        0
      );
      if (
        !acc?.find(
          (singleColumnToCheck) =>
            singleColumnToCheck?.findingSubjectIndex === findingSubjectIndex &&
            singleColumnToCheck?.sectionIndex === sectionIndex &&
            singleColumnToCheck?.itemIndex === itemIndex
        )
      ) {
        return [
          ...acc,
          {
            findingSubjectIndex,
            sectionIndex,
            itemIndex,
            columnIndex,
            newFindingSubjectIndex,
            freshFindingSubjectIndex,
          },
        ];
      }
    }
    return [...acc];
  }, []);
  let newChanges = [];
  if (columnsToCheck?.length !== 0) {
    columnsToCheck.forEach((singleColumnToCheck) => {
      let freshColumnAnswers =
        wholeFindingObject?.freshFinding?.findingSubjects?.[
          singleColumnToCheck?.freshFindingSubjectIndex
        ]?.sections?.[singleColumnToCheck?.sectionIndex]?.items?.[
          singleColumnToCheck?.itemIndex
        ]?.columns;
      let currentColumnAnswers =
        wholeFindingObject?.currentObject?.findingSubjects?.filter?.(
          (singleSubject) => !singleSubject?.removed
        )?.[singleColumnToCheck?.findingSubjectIndex]?.sections?.[
          singleColumnToCheck?.sectionIndex
        ]?.items?.[singleColumnToCheck?.itemIndex]?.columns;
      let freshColumnAnswersLength = freshColumnAnswers?.[0]?.answers?.length;
      let currentColumnAnswersLength =
        currentColumnAnswers?.[0]?.answers?.filter?.(
          (singleAnswer) => !singleAnswer?.added
        )?.length;
      if (freshColumnAnswersLength > currentColumnAnswersLength) {
        for (
          let i = currentColumnAnswersLength;
          i < freshColumnAnswersLength;
          i++
        ) {
          for (let j = 0; j < freshColumnAnswers?.length; j++) {
            newChanges?.push({
              op: "remove",
              path: `findingSubjects/${singleColumnToCheck?.newFindingSubjectIndex}/sections/${singleColumnToCheck?.sectionIndex}/items/${singleColumnToCheck?.itemIndex}/columns/${j}/answers/${i}`,
            });
          }
        }
      }
    });
  }
  newChanges = removeDuplicates([
    ...changes,
    ...newChanges?.sort(
      (a, b) =>
        b?.path?.split?.("/")?.[a?.path?.split?.("/")?.length - 1] -
        a?.path?.split?.("/")?.[b?.path?.split?.("/")?.length - 1]
    ),
  ]);
  let newSortedChanges = [
    ...newChanges?.filter((singleChange) =>
      singleChange?.path?.includes?.("answers")
    ),
  ];
  newSortedChanges = [
    ...newSortedChanges
      ?.filter?.((singleChange) => singleChange?.op === "remove")
      ?.sort(
        (a, b) =>
          b?.path?.split?.("/")?.[b?.path?.split?.("/")?.length - 1] -
          a?.path?.split?.("/")?.[a?.path?.split?.("/")?.length - 1]
      ),
    ...newSortedChanges?.filter(
      (singleChange) => singleChange?.op !== "remove"
    ),
  ];
  newSortedChanges = [
    ...newSortedChanges?.filter(
      (singleChange) => singleChange?.op === "remove"
    ),
    ...newSortedChanges?.filter((singleChange) => singleChange?.op === "add"),
    ...newSortedChanges?.filter(
      (singleChange) => singleChange?.op === "replace"
    ),
  ];
  return [
    ...newChanges?.filter(
      (singleChange) => !singleChange?.path?.includes?.("answers")
    ),
    ...newSortedChanges,
  ];
};

const removeExtraChanges = (changes, options) => {
  let currentNumberOfSubjects = options?.freshFinding?.findingSubjects?.length;
  changes?.forEach((singleChange) => {
    if (isAddingSubject(singleChange)) currentNumberOfSubjects++;
    if (isDeletingSubject(singleChange)) currentNumberOfSubjects--;
  });
  let newChanges = changes?.reduce((acc, val) => {
    if (isAddingSubject(val) || isDeletingSubject(val)) return [...acc, val];
    let pathArray = val?.path?.split("/");
    let findingSubjectIndex = pathArray?.includes("findingSubjects")
      ? Number(pathArray?.[1])
      : -2;
    if (
      findingSubjectIndex >= currentNumberOfSubjects &&
      findingSubjectIndex !== -2
    )
      return [...acc];
    return [...acc, val];
  }, []);
  newChanges = [
    ...newChanges.filter((singleChange) => isDeletingSubject(singleChange)),
    ...newChanges.filter((singleChange) => isAddingSubject(singleChange)),
    ...newChanges.filter(
      (singleChange) =>
        !isDeletingSubject(singleChange) && !isAddingSubject(singleChange)
    ),
  ];
  return newChanges;
};

export const mapFindingObjectForBE = (findingObject, options) => {
  let changes = findEdited(findingObject, "", "", {
    currentObject: findingObject,
    freshFinding: options?.newerFinding,
  });
  // console.log("changes", changes);
  let matchedImageChanges = matchImageIds(
    findingObject,
    options?.newerFinding,
    removeDuplicates(changes),
    options?.userId
  );
  // console.log("matchedImageChanges", matchedImageChanges);
  let matchedStaffChanges = matchStaffIds(
    findingObject,
    options?.newerFinding,
    removeDuplicates(matchedImageChanges),
    options?.userId
  );
  // console.log("matchedStaffChanges", matchedStaffChanges);

  let matchedAttendeesChanges = matchAttendeesIds(
    findingObject,
    options?.newerFinding,
    removeDuplicates(matchedStaffChanges),
    options?.userId
  );
  // console.log("matchedAttendeesChanges", matchedAttendeesChanges);

  let uniqueChangesWithUtc = removeDuplicates(matchedAttendeesChanges, "path");
  // console.log("uniqueChangesWithUtc", uniqueChangesWithUtc);
  let uniqueChangesWithAdding = formatAdding(uniqueChangesWithUtc);
  // console.log("uniqueChangesWithAdding", uniqueChangesWithAdding);
  let overlappingChanges = overlapMapFinding(
    findingObject,
    options.newerFinding,
    uniqueChangesWithAdding,
    options?.userId
  );
  // console.log("overlappingChanges", overlappingChanges);
  let changesWithUtc = addUpdatedOnChanges(overlappingChanges, options?.userId);
  // console.log("changesWithUtc", changesWithUtc);
  // let orderedChanges = orderFinding(
  //   findingObject,
  //   options.newerFinding,
  //   overlappingChanges
  // );
  // // console.log("orderedChanges", orderedChanges);
  let sortedChanges = [
    ...changesWithUtc
      ?.filter((singleChange) => singleChange?.op === "remove")
      ?.reverse?.(),
    ...overlappingChanges?.filter((singleChange) => singleChange?.op === "add"),
    ...overlappingChanges?.filter(
      (singleChange) => singleChange?.op === "replace"
    ),
  ];
  // console.log("sortedChanges", sortedChanges);
  let formattedIndexes = formatIndexes(sortedChanges, options.newerFinding);
  // console.log(formattedIndexes);
  // let formattedColumnChanges = formatColumnChanges(formattedIndexes, {
  //   currentObject: findingObject,
  //   freshFinding: options?.newerFinding,
  // });
  // console.log(formattedColumnChanges);
  // let removedFreshColumnChanges = removeFreshColumnChanges(
  //   formattedColumnChanges,
  //   {
  //     currentObject: findingObject,
  //     freshFinding: options?.newerFinding,
  //   }
  // );
  // console.log(removedFreshColumnChanges);
  let removedExtraChanges = removeExtraChanges(formattedIndexes, {
    currentObject: findingObject,
    freshFinding: options?.newerFinding,
  });
  // console.log(removedExtraChanges);
  // console.log(formattedIndexes);
  // console.log(getFindingSubjects(sortedChanges, findingObject));
  return removedExtraChanges;
  // ?.map?.((singleChange) => ({
  // ...singleChange,
  // value: removeEditFlag(singleChange?.value),
  // }));
};

const removeDuplicates = (changes) => {
  return changes?.reduce((acc, val) => {
    if (
      [...acc]?.filter(
        (singleValue) =>
          singleValue?.op === val?.op &&
          singleValue?.path === val?.path &&
          singleValue?.path?.[singleValue?.path?.length - 1] !== "-"
      )?.length === 0
    )
      return [...acc, val];
    return acc;
  }, []);
};

const addToPath = (oldPath = "", newPath, ...newPaths) => {
  if (oldPath?.length === 0) {
    if (newPaths?.length === 0) return String(newPath);
    else
      return String(newPath) + newPaths?.map((singlePath) => `/${singlePath}`);
  }
  if (newPaths?.length === 0) return oldPath + `/${String(newPath)}`;
  return (
    oldPath +
    `/${String(newPath)}` +
    newPaths?.map((singlePath) => `/${singlePath}`)
  );
};

export const mapColumnsToBE = (columns) => {
  return columns?.map?.((singleColumn) => {
    let columnToReturn = {
      ...singleColumn,
      answers: singleColumn?.answers?.map?.((singleAnswer, index) => {
        let answerToReturn = { ...singleAnswer, rowIndex: index };
        delete answerToReturn?.id;
        delete answerToReturn?.createdByUser;
        delete answerToReturn?.updatedByUser;
        delete answerToReturn?.columnId;
        return answerToReturn;
      }),
    };
    delete columnToReturn?.columnId;
    delete columnToReturn?.id;
    delete columnToReturn?.createdByUser;
    delete columnToReturn?.updatedByUser;
    return columnToReturn;
  });
};

export const findEdited = (
  findingObject,
  path = "",
  existingPath = path,
  wholeFindingObject
) => {
  try {
    // debugger; //eslint-disable-line
    // If argument is object
    if (typeof findingObject === "object" && findingObject != null)
      return Object.keys(findingObject).reduce((acc, val) => {
        // If property is array
        if (Array.isArray(findingObject[val])) {
          return [
            ...acc,
            ...findingObject[val].reduce((acc2, val2, index) => {
              if (val === "findingAttendees" && val2?.added) {
                let attendedPerson = {
                  firstName: val2?.value?.firstName?.value,
                  lastName: val2?.value?.lastName?.value,
                  title: val2?.value?.title?.value,
                };
                return [
                  ...acc2,
                  {
                    op: "add",
                    path: addToPath(val, "-"),
                    value: attendedPerson,
                  },
                ];
              }
              // if (val === "columns") {
              //   let pathArray = path?.split("/");
              //   let findingSubjectIndex = Number(pathArray?.[1]);
              //   let sectionIndex = Number(pathArray?.[3]);
              //   let itemIndex = Number(pathArray?.[5]);
              //   let currentFindingSubject =
              //     wholeFindingObject?.currentObject?.findingSubjects?.[
              //       findingSubjectIndex
              //     ];
              //   let freshFindingSubjectIndex =
              //     wholeFindingObject?.freshFinding?.findingSubjects?.findIndex?.(
              //       (singleSubject) =>
              //         singleSubject?.id === currentFindingSubject?.id
              //     );
              //   // Ukoliko nema subjekat na serveru, znaci ne uporedjuje se ni sa cim i ide add
              //   // Ukoliko ima subjekat na serveru, uporedjuje se i menjaju se vrednosti
              //   if (freshFindingSubjectIndex !== -1) {
              //     let freshFindingSubject =
              //       wholeFindingObject?.freshFinding?.findingSubjects?.[
              //         freshFindingSubjectIndex
              //       ];
              //     let freshColumns =
              //       freshFindingSubject?.sections?.[sectionIndex]?.items?.[
              //         itemIndex
              //       ]?.columns;
              //     let currentColumns = findingObject?.columns;
              //     let tableChanges = [];
              //     currentColumns?.forEach(
              //       (singleCurrentColumn, columnIndex) => {
              //         let currentColumnAnswers = singleCurrentColumn?.answers;
              //         let freshColumnAnswers =
              //           freshColumns?.[columnIndex]?.answers;
              //         currentColumnAnswers?.forEach(
              //           (singleCurrentColumnAnswer, answerIndex) => {
              //             let singleFreshColumnAnswer =
              //               freshColumnAnswers?.find(
              //                 (singleTempFreshColumnAnswer) =>
              //                   singleTempFreshColumnAnswer?.id ===
              //                   singleCurrentColumnAnswer?.id
              //               );
              //             if (singleFreshColumnAnswer) {
              //               if (
              //                 singleFreshColumnAnswer?.answer !==
              //                 singleCurrentColumnAnswer?.answer
              //               ) {
              //                 tableChanges?.push({
              //                   op: "replace",
              //                   path: `findingSubjects/${findingSubjectIndex}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/${answerIndex}/answer`,
              //                   value: singleCurrentColumnAnswer?.answer,
              //                 });
              //               }
              //               if (
              //                 singleFreshColumnAnswer?.rowIndex !==
              //                 singleCurrentColumnAnswer?.rowIndex
              //               ) {
              //                 tableChanges?.push({
              //                   op: "replace",
              //                   path: `findingSubjects/${findingSubjectIndex}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/${answerIndex}/rowIndex`,
              //                   value: singleCurrentColumnAnswer?.rowIndex,
              //                 });
              //               }
              //             } else {
              //               let newPath = `findingSubjects/${findingSubjectIndex}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/-`;
              //               if (
              //                 !acc2?.find(
              //                   (singleChange) =>
              //                     singleChange?.path === newPath &&
              //                     singleChange?.value?.rowIndex ===
              //                       singleCurrentColumnAnswer?.rowIndex
              //                 )
              //               )
              //                 tableChanges?.push({
              //                   op: "add",
              //                   path: `findingSubjects/${findingSubjectIndex}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/-`,
              //                   value: {
              //                     ...singleCurrentColumnAnswer,
              //                   },
              //                 });
              //             }
              //           }
              //         );
              //       }
              //     );
              //     return [...acc2, ...tableChanges];
              //   }
              //   return [...acc2];
              // }
              if (val === "findingSubjects" && val2?.removed) {
                return [
                  ...acc2,
                  {
                    op: "remove",
                    path: addToPath(
                      val,
                      index
                      // findExistingIndex(findingObject[val], index)
                    ),
                  },
                ];
              }
              if (val === "findingSubjects" && val2?.added) {
                let subject = {
                  ...val2,
                  id: 0,
                  positiveConclusionTemplate: val2?.positiveConclusionTemplate,
                  negativeConclusionTemplate: val2?.negativeConclusionTemplate,
                  scanningSubject: null,
                  name: val2?.scanningSubject?.name,
                  specificName: removeEditFlag(val2?.specificName),
                  orderNumber: removeEditFlag(val2?.orderNumber),
                  conclusion: removeEditFlag(val2?.conclusion),
                  sections: val2?.sections?.map?.((singleSection) => {
                    let sectionToReturn = {
                      ...singleSection,
                      id: 0,
                      note: removeEditFlag(singleSection?.note),
                      items: singleSection?.items?.map?.((singleItem) => {
                        let itemToReturn = {
                          ...singleItem,
                          id: 0,
                          answer: removeEditFlag(singleItem?.answer),
                          descriptionOne: removeEditFlag(
                            singleItem?.descriptionOne
                          ),
                          descriptionTwo: removeEditFlag(
                            singleItem?.descriptionTwo
                          ),
                          isInRange: removeEditFlag(singleItem?.isInRange),
                          images:
                            singleItem?.images?.map?.((singleImage) =>
                              removeEditFlag(singleImage)
                            ) || [],
                          columns: singleItem?.columns?.value?.map?.(
                            (singleColumn) => {
                              let columnToReturn = {
                                ...singleColumn,
                                id: 0,
                                columnTitle: singleColumn?.columnTitle,
                                columnSubtitle: singleColumn?.columnSubtitle,
                                answers: singleColumn?.answers?.map?.(
                                  (singleAnswer, index) => ({
                                    ...singleAnswer,
                                    rowIndex: index,
                                    id: 0,
                                  })
                                ),
                              };
                              delete columnToReturn.columnId;
                              return columnToReturn;
                            }
                          ),
                        };
                        delete itemToReturn?.edited;
                        delete itemToReturn?.columnAnswers;
                        return itemToReturn;
                      }),
                    };
                    delete sectionToReturn?.id;
                    return sectionToReturn;
                  }),
                };

                delete subject?.added;
                return [
                  ...acc2,
                  {
                    op: "add",
                    path: addToPath(val, index),
                    value: subject,
                  },
                ];
              }
              return [
                ...acc2,
                ...findEdited(
                  val2,
                  addToPath(path, val, index),
                  addToPath(
                    existingPath,
                    val,
                    findExistingIndex(findingObject[val], index)
                  ),
                  wholeFindingObject
                ),
              ];
            }, []),
          ];
        }
        if (
          Object.keys(findingObject).includes("edited") ||
          Object.keys(findingObject).includes("removed") ||
          Object.keys(findingObject).includes("added")
        ) {
          // console.log(findingObject);
          //   let pathArray = path?.split("/");
          //   if (
          //     pathArray?.includes("columns") &&
          //     pathArray?.includes("answers") &&
          //     pathArray?.length >= 10
          //   ) {
          //     let findingSubjectIndex = Number(pathArray?.[1]);
          //     let sectionIndex = Number(pathArray?.[3]);
          //     let itemIndex = Number(pathArray?.[5]);
          //     let columnIndex = Number(pathArray?.[7]);
          //     let findingSubjectId =
          //       wholeFindingObject?.currentObject?.findingSubjects?.filter?.(
          //         (singleSubject) => !singleSubject?.removed
          //       )?.[findingSubjectIndex]?.id;
          //     let newFindingSubjectIndex =
          //       wholeFindingObject?.freshFinding?.findingSubjects?.findIndex(
          //         (singleSubject) => singleSubject?.id === findingSubjectId
          //       );

          //     let newAnswerIndex =
          //       wholeFindingObject?.freshFinding?.findingSubjects?.[
          //         newFindingSubjectIndex
          //       ]?.sections?.[sectionIndex]?.items?.[itemIndex]?.columns?.[
          //         columnIndex
          //       ]?.answers?.findIndex(
          //         (singleAnswer) => singleAnswer?.id === findingObject?.value?.id
          //       );
          //     delete findingObject.value.id;
          //     delete findingObject.value.createdByUser;
          //     delete findingObject.value.updatedByUser;
          //     if (
          //       !findingObject?.added &&
          //       findingObject?.edited &&
          //       !findingObject?.removed
          //     ) {
          //       if (newAnswerIndex === -1) {
          //         return [
          //           ...acc,
          //           {
          //             op: "add",
          //             path: `findingSubjects/${findingSubjectIndex}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/-`,
          //             value: removeEditFlag(findingObject),
          //           },
          //         ];
          //       } else
          //         return [
          //           ...acc,
          //           {
          //             op: "replace",
          //             path: `findingSubjects/${findingSubjectIndex}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/${newAnswerIndex}`,
          //             value: removeEditFlag(findingObject),
          //           },
          //         ];
          //     }
          //     if (findingObject?.removed) {
          //       if (newAnswerIndex === -1) {
          //         return [...acc];
          //       } else
          //         return [
          //           ...acc,
          //           {
          //             op: "remove",
          //             path: `findingSubjects/${findingSubjectIndex}/sections/${sectionIndex}/items/${itemIndex}/columns/${columnIndex}/answers/${newAnswerIndex}`,
          //             value: removeEditFlag(findingObject),
          //           },
          //         ];
          //     }
          //   }
          if (
            findingObject?.removed &&
            findingObject?.value &&
            !findingObject?.added
          )
            return [
              ...acc,
              {
                op: "remove",
                path,
              },
            ];
          else if (
            findingObject?.added &&
            findingObject?.value &&
            !findingObject?.removed
          )
            return [
              ...acc,
              {
                op: "add",
                path,
                value: removeEditFlag(findingObject),
              },
            ];
          else if (findingObject?.edited && findingObject?.value) {
            if (existingPath?.includes("columns")) {
              if (existingPath?.includes("answers")) {
                let pathArray = path?.split("/");
                let existingPathArray = existingPath?.split("/");
                let newPath = [
                  ...pathArray?.slice(0, -1),
                  existingPathArray?.slice(-1),
                ].join("/");
                return [
                  ...acc,
                  {
                    op: "replace",
                    path: newPath,
                    value: removeEditFlag(findingObject),
                  },
                ];
              }
              return [
                ...acc,
                {
                  op: "replace",
                  path: path,
                  value: removeEditFlag(findingObject),
                },
              ];
            }
            return [
              ...acc,
              {
                op: "replace",
                path: existingPath,
                value: removeEditFlag(findingObject),
              },
            ];
          } else
            return [
              ...acc,
              ...findEdited(
                findingObject?.value,
                path,
                existingPath,
                wholeFindingObject
              ),
            ];
        }

        // If property is object
        if (
          typeof findingObject[val] === "object" &&
          findingObject[val] != null
        ) {
          if (
            Object.keys(findingObject[val]).includes("edited") ||
            Object.keys(findingObject[val]).includes("removed") ||
            Object.keys(findingObject[val]).includes("added")
          ) {
            // if (Object.keys(findingObject[val]).includes("columnId")) {
            //   let pathArray = path.split("/");
            //   let oldFindingSubjectIndex = Number(pathArray?.[1]);
            //   let oldSectionIndex = Number(pathArray?.[3]);
            //   let oldItemIndex = Number(pathArray?.[5]);
            //   let rowIndex = Number(pathArray?.[pathArray?.length - 1]);
            //   let answerObject = findingObject?.[rowIndex];

            //   let oldFindingSubject =
            //     wholeFindingObject?.currentObject?.findingSubjects?.[
            //       oldFindingSubjectIndex
            //     ];
            //   let newFindingSubjectIndex =
            //     wholeFindingObject?.freshFinding?.findingSubjects?.findIndex(
            //       (singleFindingSubject) =>
            //         singleFindingSubject?.id === oldFindingSubject?.id
            //     );
            //   if (newFindingSubjectIndex === -1)
            //     newFindingSubjectIndex =
            //       wholeFindingObject?.freshFinding?.findingSubjects?.length;

            //   let oldSection =
            //     wholeFindingObject?.currentObject?.findingSubjects?.[
            //       oldFindingSubjectIndex
            //     ]?.sections?.[oldSectionIndex];
            //   let newSectionIndex = wholeFindingObject?.freshFinding?.[
            //     newFindingSubjectIndex
            //   ]?.sections?.findIndex(
            //     (singleSection) => singleSection?.id === oldSection?.id
            //   );
            //   if (newSectionIndex === -1)
            //     newSectionIndex =
            //       wholeFindingObject?.freshFinding?.findingSubjects?.[
            //         newFindingSubjectIndex
            //       ]?.sections?.length;

            //   let oldItem =
            //     wholeFindingObject?.currentObject?.findingSubjects?.[
            //       oldFindingSubjectIndex
            //     ]?.sections?.[oldSectionIndex]?.items?.[oldItemIndex];
            //   let newItemIndex = wholeFindingObject?.freshFinding?.[
            //     newFindingSubjectIndex
            //   ]?.sections?.[oldSectionIndex]?.findIndex(
            //     (singleItem) => singleItem?.id === oldItem?.id
            //   );
            //   if (newItemIndex === -1)
            //     newItemIndex =
            //       wholeFindingObject?.freshFinding?.findingSubjects?.[
            //         newFindingSubjectIndex
            //       ]?.sections?.length;

            //   let newPath = [...pathArray];
            //   newPath.splice(newPath?.length - 2, 1, "columns");
            //   newPath.push("answers");
            //   if (answerObject?.added) {
            //     newPath;
            //   }
            //   debugger;
            // }
            if (findingObject[val]?.removed && !findingObject[val]?.added)
              return [
                ...acc,
                {
                  op: "remove",
                  path: addToPath(path, val),
                  // value: removeEditFlag(findingObject?.value),
                },
              ];
            else if (findingObject[val]?.added && !findingObject[val]?.removed)
              return [
                ...acc,
                {
                  op: "add",
                  path: addToPath(path, val),
                  value: removeEditFlag(findingObject[val]),
                },
              ];
            else if (findingObject[val]?.edited) {
              if (val === "closingDate" || val === "date") {
                let formatedDate = formatDate(
                  findingObject[val]?.value,
                  "yyyy-MM-dd"
                );
                return [
                  ...acc,
                  {
                    op: "replace",
                    path: addToPath(existingPath, val),
                    value: formatedDate,
                  },
                ];
              }
              if (val === "columns") {
                return [
                  ...acc,
                  {
                    op: "replace",
                    path: addToPath(path, val),
                    value: mapColumnsToBE(removeEditFlag(findingObject[val])),
                  },
                ];
              }
              return [
                ...acc,
                {
                  op: "replace",
                  path: addToPath(existingPath, val),
                  value: removeEditFlag(findingObject[val]),
                },
              ];
            } else return acc;
          }
          return [
            ...acc,
            ...findEdited(
              findingObject[val],
              addToPath(path, val),
              addToPath(existingPath, val),
              wholeFindingObject
            ),
          ];
        }
        return acc;
      }, []);

    // If argument is array
    if (Array.isArray(findingObject)) {
      return findingObject?.reduce((acc, val, index) => {
        return [
          ...acc,
          ...findEdited(
            val,
            addToPath(path, index),
            addToPath(path, index),
            wholeFindingObject
          ),
        ];
      }, []);
    }
    return [];
  } catch (e) {
    console.dir(e);
  }
};

export const addUpdatedOnChanges = (changes, userId) => {
  return changes?.reduce((acc, val) => {
    let pathArray = val?.path?.split?.("/");
    if (
      findingEditableProperties?.includes(pathArray?.[pathArray?.length - 1]) ||
      _.isNumber(Number(pathArray?.[pathArray?.length - 1]))
    ) {
      let genericPath = val?.path?.split?.("/");
      genericPath = [...genericPath?.slice(0, -1)]?.join("/");
      // if (val?.op === "remove") return [...acc, val]
      let newChanges = pathArray?.reduce(
        (acc2, val2, index) => {
          let newPath = addToPath(acc2.path, val2);
          if (
            isNaN(+pathArray?.[index]) ||
            (val?.op !== "replace" && newPath?.includes?.(genericPath))
          )
            return { path: newPath, updatedChanges: [...acc2.updatedChanges] };

          return {
            path: newPath,
            updatedChanges: [
              ...acc2.updatedChanges,
              {
                path: addToPath(newPath, "updatedAtUtc"),
                op: "replace",
                value: new Date(
                  new Date()?.getTime() -
                    new Date()?.getTimezoneOffset() * 60000
                ).toISOString(),
              },
              {
                path: addToPath(newPath, "updatedByUserId"),
                op: "replace",
                value: userId,
              },
            ],
          };
        },
        { path: "", updatedChanges: [] }
      );
      return [...acc, val, ...newChanges?.updatedChanges];
    }
    return [...acc];
  }, []);
};
const formatAdding = (changes) => {
  return changes?.reduce?.((acc, val) => {
    if (val?.op === "add") {
      let pathArray = val?.path?.split?.("/");
      const newPath = [...pathArray?.slice(0, -1), "-"]?.join("/");
      return [
        ...acc,
        {
          ...val,
          path: newPath,
        },
      ];
    }
    return [...acc, val];
  }, []);
};
