import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchFindingTypes } from "../../../store/actions/findingTypes/findingTypesActions";
import { selectFindingTypesData } from "../../../store/selectors/findingTypesSelectors";
import FindingTypeLabel from "./FindingTypeLabel/FindingTypeLabel";
import {
  FindingTypes,
  FindingTypesContainer,
  FindingTypesLabel,
} from "./FindingTypesSelect.styled";

const FindingTypesSelect = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isPreloadedValue, setIsPreloadedValue] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const findingTypes = useSelector(selectFindingTypesData);

  useEffect(() => {
      dispatch(fetchFindingTypes());
  }, []);

  useEffect(() => {
    if (!isPreloadedValue && selectedValue !== props?.value) {
      setSelectedValue(JSON.stringify(props?.value));
      setIsPreloadedValue(true);
    }
  }, [props?.value]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    props?.onChange(JSON.parse(event.target.value));
  };
  return (
    <FindingTypesContainer>
      <FindingTypesLabel>{t("findingTypes.findingType")}</FindingTypesLabel>
      <FindingTypes value={selectedValue} onChange={handleChange}>
        {findingTypes?.map((item, index) => (
          <FindingTypeLabel
            isChecked={JSON.stringify(item) === selectedValue}
            key={index}
            label={item?.name}
            value={JSON.stringify(item)}
            uncheckedIcon={props?.uncheckedIcon ?? props?.uncheckedIcon}
            checkedIcon={props?.checkedIcon ?? props?.checkedIcon}
          />
        ))}
      </FindingTypes>
    </FindingTypesContainer>
  );
};

FindingTypesSelect.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.any,
  uncheckedIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  checkedIcon: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

FindingTypesSelect.defaultProps = {
  onChange: () => {},
};

export default FindingTypesSelect;
