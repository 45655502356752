import React from "react";
import { Router } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import history from "./store/utils/history";
import AppRoutes from "./AppRoutes";
import { StyledEngineProvider } from "@mui/material";
import Modal from "./components/Modals/Modal";
import Header from "./components/Header/Header";
import { ReactComponent as UsersIcon } from "./assets/images/svg/users.svg";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { APPLICATION_TOAST_CONTAINER_ID } from "./constants/toastConstants";
import AppLayout from "./layouts/AppLayout/AppLayout";
import SidebarNavigation from "./components/SidebarNavigation/SidebarNavigation";
import { TouchBackend } from "react-dnd-touch-backend";

// Styles
import GlobalStyle from "./components/Styles/globalStyles";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-circular-progressbar/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/fonts/Inter/Inter.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useIsMobile from "./hooks/useIsMobile";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import Footer from "./components/Footer/Footer";

const App = () => {
  const { isMobile } = useIsMobile();
  return (
    <>
      <Router history={history} className="app">
        <I18nextProvider i18n={i18n}>
          <Helmet>
            <title>Equipment Checker</title>
          </Helmet>

          <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
            <StyledEngineProvider injectFirst>
              <AppLayout
                sidebar={<SidebarNavigation />}
                content={
                  <>
                    <Modal />
                    <ToastContainer
                      enableMultiContainer
                      limit={2}
                      containerId={APPLICATION_TOAST_CONTAINER_ID}
                    />

                    <Header icon={<UsersIcon />} route="Users" />
                    <GlobalStyle />
                    <AppRoutes />
                    <Footer />
                  </>
                }
              />
            </StyledEngineProvider>
          </DndProvider>
        </I18nextProvider>
      </Router>
    </>
  );
};

export default App;
