import { all, call, put, takeLatest, select } from "@redux-saga/core/effects";
import {
  attemptFetchCreateChecklistTemplate,
  attemptFetchEditChecklistTemplate,
} from "../../request/checklistTemplatesRequest";
import {
  attemptCopyScanningObject,
  attemptDeleteScanningObject,
  attemptFetchCreateScanningObject,
  attemptFetchEditScanningObject,
  attemptFetchScanningObjects,
  attemptFetchSingleScanningObject,
} from "../../request/scanningObjectsRequest";
import { mapChecklistTemplateForBE } from "../../util/mappers/scanningObjectsMappers";
import {
  SCANNING_OBJECTS_COPY_FETCH,
  SCANNING_OBJECTS_CREATE_FETCH,
  SCANNING_OBJECTS_DELETE_FETCH,
  SCANNING_OBJECTS_EDIT_FETCH,
  SCANNING_OBJECTS_FETCH,
  SINGLE_SCANNING_OBJECT_FETCH,
} from "../actions/scanningObjects/scanningObjectsActionConstants";
import {
  fetchCopyScanningObjectError,
  fetchCopyScanningObjectSuccess,
  fetchCreateScanningObjectError,
  fetchCreateScanningObjectSuccess,
  fetchDeleteScanningObjectError,
  fetchDeleteScanningObjectSuccess,
  fetchEditScanningObjectError,
  fetchEditScanningObjectSuccess,
  fetchScanningObjectsError,
  fetchScanningObjectsSuccess,
  fetchSingleScanningObjectError,
  fetchSingleScanningObjectSuccess,
  setScanningObjects,
  setScanningObjectsChanged,
  setScanningObjectsTotal,
  setSingleScanningObject,
} from "../actions/scanningObjects/scanningObjectsActions";
import {
  selectChecklistTemplates,
  selectScanningObjectType,
  selectSelectedChecklistTemplate,
} from "../selectors/scanningObjectsSelectors";
import {
  mapFetchAllFunction,
  mapFetchScanningSubjectFunction,
} from "../utils/mapFetchAllFunctions";

function* fetchScanningObjects({ payload }) {
  try {
    const queryObject = mapFetchAllFunction(payload);
    const data = yield call(attemptFetchScanningObjects, queryObject);
    yield put(
      setScanningObjects({
        data: data?.data?.scanningSubjects,
      })
    );
    yield put(setScanningObjectsTotal(data?.data?.count));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchScanningObjectsSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchScanningObjectsError(errorMessage));
  }
}

function* fetchSingleScanningObject({ payload }) {
  try {
    const { data } = yield call(
      attemptFetchSingleScanningObject,
      payload?.scanningObjectId
    );
    yield put(
      setSingleScanningObject(
        mapFetchScanningSubjectFunction(data)
      )
    );
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchSingleScanningObjectSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchSingleScanningObjectError(errorMessage));
  }
}
function* fetchCreateScanningObject({ payload }) {
  try {
    let findingType = yield select(selectScanningObjectType);
    let createScanningObjectRequestObject = {
      name: payload?.name,
      findingTypeId: findingType.id,
    };
    const { data } = yield call(
      attemptFetchCreateScanningObject,
      createScanningObjectRequestObject
    );
    const templateData = yield select(selectSelectedChecklistTemplate);
    if (
      templateData?.measuringInstruments !== undefined &&
      templateData?.sections !== undefined
    ) {
      let createChecklistTemplateRequestObject = mapChecklistTemplateForBE(
        {
          isDefault: true,
          scanningSubjectId: data?.id,
        },
        templateData
      );
      yield call(
        attemptFetchCreateChecklistTemplate,
        createChecklistTemplateRequestObject
      );
    }
    yield put(setScanningObjectsChanged(false));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.id);
    }
    yield put(fetchCreateScanningObjectSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCreateScanningObjectError(errorMessage));
  }
}
function* fetchEditScanningObject({ payload }) {
  try {
    let editScanningObjectRequestObject = {
      name: payload?.name,
    };

    const { data } = yield call(attemptFetchEditScanningObject, {
      scanningObjectId: payload?.scanningObjectId,
      data: editScanningObjectRequestObject,
    });

    const allTemplatesData = yield select(selectChecklistTemplates);
    for (let i = 0; i < allTemplatesData?.length; i++) {
      const singleTemplate = allTemplatesData[i];
      if (singleTemplate?.changed) {
        let checklistTemplateRequestObject = mapChecklistTemplateForBE(
          {
            isDefault: singleTemplate.isDefault,
            scanningSubjectId: data?.id,
          },
          singleTemplate
        );
        if (singleTemplate?.added) {
          yield call(attemptFetchCreateChecklistTemplate, {
            ...checklistTemplateRequestObject,
            scanningSubjectId: payload?.scanningObjectId,
          });
        } else {
          yield call(attemptFetchEditChecklistTemplate, {
            checklistTemplateId: singleTemplate?.id,
            data: {
              ...checklistTemplateRequestObject,
              scanningSubjectId: payload?.scanningObjectId,
            },
          });
        }
      }
    }
    yield call(fetchSingleScanningObject, {
      payload: {
        scanningObjectId: payload?.scanningObjectId,
      },
    });

    yield put(setScanningObjectsChanged(false));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess);
    }
    yield put(fetchEditScanningObjectSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchEditScanningObjectError(errorMessage));
  }
}

function* fetchDeleteScanningObject({ payload }) {
  try {
    yield call(attemptDeleteScanningObject, {
      scanningObjectId: payload?.scanningObjectId,
    });
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, { isDeleted: true });
    }
    yield put(fetchDeleteScanningObjectSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchDeleteScanningObjectError(errorMessage));
  }
}

function* fetchCopyScanningObject({ payload }) {
  try {
    const { data } = yield call(attemptCopyScanningObject, {
      scanningObjectId: payload?.scanningObjectId,
    });
    yield put(setSingleScanningObject(mapFetchScanningSubjectFunction(data)));
    if (payload?.handleApiResponseSuccess) {
      yield call(payload?.handleApiResponseSuccess, data?.id);
    }
    yield put(fetchCopyScanningObjectSuccess());
  } catch (e) {
    console.log("greska", e);
    let errorMessage = e?.response?.data?.message;

    if (payload?.handleApiResponseError) {
      yield call(payload?.handleApiResponseError, errorMessage);
    }
    yield put(fetchCopyScanningObjectError(errorMessage));
  }
}

export default function* scanningObjectsSaga() {
  yield all([
    takeLatest(SCANNING_OBJECTS_FETCH, fetchScanningObjects),
    takeLatest(SINGLE_SCANNING_OBJECT_FETCH, fetchSingleScanningObject),
    takeLatest(SCANNING_OBJECTS_CREATE_FETCH, fetchCreateScanningObject),
    takeLatest(SCANNING_OBJECTS_EDIT_FETCH, fetchEditScanningObject),
    takeLatest(SCANNING_OBJECTS_DELETE_FETCH, fetchDeleteScanningObject),
    takeLatest(SCANNING_OBJECTS_COPY_FETCH, fetchCopyScanningObject),
  ]);
}
