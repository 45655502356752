import {
  createClearType,
  createErrorType,
  createFetchType,
  createSetType,
  createSuccessType,
} from "../actionHelpers";

export const SET_USER = createSetType("SET_USER");
export const SET_USER_ERROR = "SET_USER_ERROR";

export const GET_USERS_SCOPE = "GET_USERS_SCOPE";
export const GET_USERS_FETCH = createFetchType(GET_USERS_SCOPE);
export const GET_USERS_SUCCESS = createSuccessType(GET_USERS_SCOPE);
export const GET_USERS_ERROR = createErrorType(GET_USERS_SCOPE);
export const GET_USERS_CLEAR = createClearType(GET_USERS_SCOPE);

export const SINGLE_USER_SCOPE = "SINGLE_USER_SCOPE";
export const SINGLE_USER_FETCH = createFetchType(SINGLE_USER_SCOPE);
export const SINGLE_USER_SUCCESS = createSuccessType(SINGLE_USER_SCOPE);
export const SINGLE_USER_ERROR = createErrorType(SINGLE_USER_SCOPE);
export const SINGLE_USER_SET = createSetType(SINGLE_USER_SCOPE);
export const SINGLE_USER_CLEAR = createClearType(SINGLE_USER_SCOPE);

const MY_USER_SCOPE = "MY_USER_SCOPE";
export const MY_USER_FETCH = createFetchType(MY_USER_SCOPE);
export const MY_USER_SUCCESS = createSuccessType(MY_USER_SCOPE);
export const MY_USER_ERROR = createErrorType(MY_USER_SCOPE);
export const MY_USER_SET = createSetType(MY_USER_SCOPE);

const CREATE_USER_SCOPE = "CREATE_USER_SCOPE";
export const CREATE_USER_FETCH = createFetchType(CREATE_USER_SCOPE);
export const CREATE_USER_SUCCESS = createSuccessType(CREATE_USER_SCOPE);
export const CREATE_USER_ERROR = createErrorType(CREATE_USER_SCOPE);

const USER_CHANGE_PASSWORD_SCOPE = "USER_CHANGE_PASSWORD_SCOPE";
export const USER_CHANGE_PASSWORD_FETCH = createFetchType(
  USER_CHANGE_PASSWORD_SCOPE
);
export const USER_CHANGE_PASSWORD_SUCCESS = createSuccessType(
  USER_CHANGE_PASSWORD_SCOPE
);
export const USER_CHANGE_PASSWORD_ERROR = createErrorType(
  USER_CHANGE_PASSWORD_SCOPE
);

const EDIT_USER_SCOPE = "EDIT_USER_SCOPE";
export const EDIT_USER_FETCH = createFetchType(EDIT_USER_SCOPE);
export const EDIT_USER_SUCCESS = createSuccessType(EDIT_USER_SCOPE);
export const EDIT_USER_ERROR = createErrorType(EDIT_USER_SCOPE);

const BLOCK_USER_SCOPE = "BLOCK_USER_SCOPE";
export const BLOCK_USER_FETCH = createFetchType(BLOCK_USER_SCOPE);
export const BLOCK_USER_SUCCESS = createSuccessType(BLOCK_USER_SCOPE);
export const BLOCK_USER_ERROR = createErrorType(BLOCK_USER_SCOPE);

const UNBLOCK_USER_SCOPE = "UNBLOCK_USER_SCOPE";
export const UNBLOCK_USER_FETCH = createFetchType(UNBLOCK_USER_SCOPE);
export const UNBLOCK_USER_SUCCESS = createSuccessType(UNBLOCK_USER_SCOPE);
export const UNBLOCK_USER_ERROR = createErrorType(UNBLOCK_USER_SCOPE);

export const SET_USERS = createSetType("SET_USERS");
export const ADD_USER = createSetType("ADD_USER");
export const SET_TOTAL_USERS = createSetType("SET_TOTAL_USERS");
export const SET_USERS_ERROR_MESSAGE = createSetType("CLEAR_USERS");
export const CLEAR_USERS = createClearType("CLEAR_USERS");
