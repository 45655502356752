import React from "react";
import ProfileBreadIcon from "../components/Icon/Icons/ProfileBreadIcon";
import { getRouteName } from "../util/helpers/routeHelpers";
import { ReactComponent as UsersIcon } from "../assets/images/svg/users.svg";
import { ReactComponent as TaskIcon } from "../assets/images/svg/task-square.svg";
import PersonalIcon from "../components/Icon/Icons/PersonalIcon";
import StaffIcon from "../components/Icon/Icons/StaffIcon";
import ClientsBreadIcon from "../components/Icon/Icons/ClientsBreadIcon";
import FindingBreadIcon from "../components/Icon/Icons/FindingBreadIcon";
import ResearchBreadIcon from "../components/Icon/Icons/ResearchBreadIcon";
import MeasuringInstrumentsSidebarIcon from "../components/Icon/Icons/MeasuringInstrumentsSiderbarIcon";
import ScanningSubjectsBreadIcon from "../components/Icon/Icons/ScanningSubjectsBreadIcon";
import SettingsBreadIcon from "../components/Icon/Icons/SettingsBreadIcon";
import BaseBreadIcon from "../components/Icon/Icons/BaseBreadIcon";
import FindingTypesIcon from "../components/Icon/Icons/FindingTypesIcon";
import CompanyIcon from "../components/Icon/Icons/CompanyIcon";
import DocumentTypeIcon from "../components/Icon/Icons/DocumentTypeIcon";
import DocumentIcon from "../components/Icon/Icons/DocumentIcon";
export const BASE_PAGE = "/";
export const LOGIN_PAGE = "/login";
export const LOGOUT_PAGE = "/logout";
export const FORGOT_PASSWORD_PAGE = "/forgot-password";
export const HOME_PAGE = "/home";
export const ERROR_PAGE = "/error-page";
export const NOT_FOUND_PAGE = "/not-found";
export const USERS_PAGE = "/personal/users";
export const NEW_USER = "/personal/users/new";
export const NEW_COMPANY_USER = "/companies/:companyId/new-user";
export const EDIT_COMPANY_USER = "/companies/:companyId/:userId";
export const SETTINGS_PAGE = "/settings";
export const PROFILE_PAGE = "/profile";
export const EDIT_USER = "/personal/users/:userId";
export const RESEARCH_PAGE = "/research";
export const STANDARDS_PAGE = "/research/standards";
export const CLIENTS_PAGE = "/clients";
export const NEW_CLIENT_PAGE = "/clients/new";
export const EDIT_CLIENT_PAGE = "/clients/:clientId";
export const MEASURING_INSTRUMENTS_PAGE = "/research/measuring-instruments";
export const DOCUMENT_TYPES_PAGE = "/research/document-types";
export const SCANNING_OBJECTS_PAGE = "/research/scanning-objects";
export const NEW_SCANNING_OBJECTS_PAGE = "/research/scanning-objects/new";
export const EDIT_SCANNING_OBJECTS_PAGE =
  "/research/scanning-objects/:scanningObjectId";
export const STUFFS_PAGE = "/personal/staff";
export const NEW_STUFF_PAGE = "/personal/staff/new";
export const EDIT_STUFF_PAGE = "/personal/staff/:stuffId";
export const FINDINGS_PAGE = "/findings";
export const FINDINGS_NEW_PAGE = "/findings/new";
export const FINDINGS_EDIT_PAGE = "/findings/:findingId";
export const DOCUMENTS_PAGE = "/documents";
export const DOCUMENTS_NEW_PAGE = "/documents/new";
export const DOCUMENTS_EDIT_PAGE = "/documents/:documentId";
export const COMPANIES_PAGE = "/companies";
export const COMPANIES_NEW_PAGE = "/companies/new";
export const COMPANIES_EDIT_PAGE = "/companies/:companyId";
export const PERSONAL_PAGE = "/personal";
export const FINDING_TYPES_PAGE = "/research/finding-types";

export const pagesAsArray = [
  { id: BASE_PAGE, route: "/", i18key: `pages.${getRouteName(BASE_PAGE)}` },
  {
    id: HOME_PAGE,
    route: "/home",
    i18key: `pages.${getRouteName(HOME_PAGE)}`,
    icon: <BaseBreadIcon />,
  },
  {
    id: LOGIN_PAGE,
    route: "/login",
    i18key: `pages.${getRouteName(LOGIN_PAGE)}`,
  },
  {
    id: LOGOUT_PAGE,
    route: "/logout",
    i18key: `pages.logout`,
  },
  {
    id: FORGOT_PASSWORD_PAGE,
    route: "/forgot-password",
    i18key: `pages.${getRouteName(FORGOT_PASSWORD_PAGE)}`,
  },
  { id: HOME_PAGE, route: "/home", i18key: `pages.${getRouteName(HOME_PAGE)}` },
  {
    id: ERROR_PAGE,
    route: "/error-page",
    i18key: `pages.${getRouteName(ERROR_PAGE)}`,
  },
  {
    id: NOT_FOUND_PAGE,
    route: "/not-found",
    i18key: `pages.${getRouteName(NOT_FOUND_PAGE)}`,
  },
  {
    id: USERS_PAGE,
    route: "/personal/users",
    i18key: `pages.${getRouteName(USERS_PAGE)}`,
    icon: <UsersIcon />,
  },
  {
    id: NEW_USER,
    route: "/personal/users/new",
    i18key: `pages.newUser`,
  },
  {
    id: NEW_USER,
    route: "/personal/users/:userId",
    i18key: `pages.editUser`,
  },
  {
    id: SETTINGS_PAGE,
    route: "/settings",
    i18key: `pages.${getRouteName(SETTINGS_PAGE)}`,
    icon: <SettingsBreadIcon />,
  },
  {
    id: PROFILE_PAGE,
    route: "/profile",
    i18key: `pages.${getRouteName(PROFILE_PAGE)}`,
    icon: <ProfileBreadIcon />,
  },
  {
    id: STANDARDS_PAGE,
    route: "/research/standards",
    i18key: `pages.${getRouteName(STANDARDS_PAGE)}`,
    icon: <TaskIcon />,
  },
  {
    id: RESEARCH_PAGE,
    route: "/research/",
    i18key: `pages.${getRouteName(RESEARCH_PAGE)}`,
    icon: <ResearchBreadIcon />,
  },
  {
    id: PERSONAL_PAGE,
    route: "/personal/",
    i18key: `pages.${getRouteName(PERSONAL_PAGE)}`,
    icon: <PersonalIcon />,
  },
  {
    id: CLIENTS_PAGE,
    route: "/clients",
    i18key: `pages.${getRouteName(CLIENTS_PAGE)}`,
    icon: <ClientsBreadIcon />,
  },
  {
    id: NEW_CLIENT_PAGE,
    route: "/clients/new",
    i18key: `pages.newClient`,
  },
  {
    id: EDIT_CLIENT_PAGE,
    route: "/clients/:clientId",
    i18key: `pages.editClient`,
  },
  {
    id: MEASURING_INSTRUMENTS_PAGE,
    route: "/research/measuring-instruments",
    i18key: `pages.${getRouteName(MEASURING_INSTRUMENTS_PAGE)}`,
    icon: <MeasuringInstrumentsSidebarIcon />,
  },
  {
    id: FINDING_TYPES_PAGE,
    route: "/research/finding-types",
    i18key: `pages.${getRouteName(FINDING_TYPES_PAGE)}`,
    icon: <FindingTypesIcon />,
  },
  {
    id: DOCUMENT_TYPES_PAGE,
    route: "/research/document-types",
    i18key: `pages.${getRouteName(DOCUMENT_TYPES_PAGE)}`,
    icon: <DocumentTypeIcon />
  },
  {
    id: SCANNING_OBJECTS_PAGE,
    route: "/research/scanning-objects",
    i18key: `pages.${getRouteName(SCANNING_OBJECTS_PAGE)}`,
    icon: <ScanningSubjectsBreadIcon />,
  },
  {
    id: NEW_SCANNING_OBJECTS_PAGE,
    route: "/research/scanning-objects/new",
    i18key: `pages.newScanningObject`,
  },
  {
    id: EDIT_SCANNING_OBJECTS_PAGE,
    route: "/research/scanning-objects/:scanningObjectId",
    i18key: `pages.editScanningObject`,
  },
  {
    id: STUFFS_PAGE,
    route: "/personal/staff",
    i18key: `pages.${getRouteName(STUFFS_PAGE)}`,
    icon: <StaffIcon />,
  },
  {
    id: NEW_STUFF_PAGE,
    route: "/personal/staff/new",
    i18key: `pages.newStuff`,
  },
  {
    id: EDIT_STUFF_PAGE,
    route: "/personal/staff/:stuffId",
    i18key: `pages.editStuff`,
  },
  {
    id: FINDINGS_PAGE,
    route: "/findings",
    i18key: `pages.findings`,
    icon: <FindingBreadIcon />,
  },
  {
    id: FINDINGS_NEW_PAGE,
    route: "/findings/new",
    i18key: `pages.newFinding`,
  },
  {
    id: FINDINGS_EDIT_PAGE,
    route: "/findings/:findingId",
    i18key: `pages.editFinding`,
  },
  {
    id: DOCUMENTS_PAGE,
    route: "/documents",
    i18key: `pages.documents`,
    icon: <DocumentIcon />,
    fill: true,
  },
  {
    id: DOCUMENTS_EDIT_PAGE,
    route: "/documents/:documentId",
    i18key: `pages.editDocument`,
  },
  {
    id: DOCUMENTS_NEW_PAGE,
    route: "/documents/new",
    i18key: `pages.newDocument`,
  },
  {
    id: COMPANIES_PAGE,
    route: "/companies",
    i18key: `pages.companies`,
    icon: <CompanyIcon />,
  },
  {
    id: COMPANIES_NEW_PAGE,
    route: "/companies/new",
    i18key: `pages.newCompany`,
  },
  {
    id: COMPANIES_EDIT_PAGE,
    route: "/companies/:companyId",
    i18key: `pages.editCompany`,
  },
  {
    id: NEW_COMPANY_USER,
    route: "/companies/:companyId/new-user",
    i18key: `pages.newUser`,
  },
  {
    id: EDIT_COMPANY_USER,
    route: "/companies/:companyId/new-user",
    i18key: `pages.editUser`,
  },
];
